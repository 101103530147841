import styled from "@emotion/styled";
import { keyframes, css } from "@emotion/react";

export const primaryFont = "'Source Sans Pro', sans-serif";
export const secondaryFont = "'OfficeCodeProWeb', monospace";

export const backgroundDark = "#2e353f"; // dark blue gray
export const lightBorderColor = "#dcdcdc"; // really light gray color for subtle borders between elements

export const backgroundMedium = "#e6e7e8"; // dark blue gray
export const baseColor = "#333333"; // dark gray/black color for text

export const tooltipHeaderBackgroundColor = "#d7dbdd";

export const chartMargin = { top: 10, left: 90, right: 50, bottom: 25 };

export const noOutlineOnFocus = css`
  &:focus:not(:focus-visible) {
    outline: none;
  }
`;

const fadeIn = keyframes`
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
`;

export const fadeInAnimation = css`
  opacity: 0;
  animation: ${fadeIn} 0.2s linear 1 forwards;
`;

interface RadioButtonProps {
  $checked: boolean;
}

export const radioButtonCss = ({ $checked }: RadioButtonProps) => css`
  display: flex;
  align-items: center;
  position: relative;

  &:before {
    content: "";
    width: 12px;
    height: 12px;
    border-radius: 200px;
    border: solid 1px #000;
    margin-right: 4px;
    flex-shrink: 0;
  }

  &:after {
    ${$checked ? "content: '';" : "content: none;"}
    width: 6px;
    height: 6px;
    border-radius: 200px;
    background-color: #000;
    transform: translate(4px, 0);
    position: absolute;
    flex-shrink: 0;
  }

  &:hover {
    background-color: #fff;
    color: ${backgroundDark};

    &:before {
      border-color: ${backgroundDark};
    }

    &:after {
      background-color: ${backgroundDark};
    }
  }
`;

export const AxisGElement = styled.g`
  pointer-events: none;

  & text {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 0.9rem;
    fill: #232323;
  }
`;
