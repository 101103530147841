import { memo } from "react";
import Tippy from "@tippyjs/react";
import { followCursor } from "tippy.js";
import styled from "@emotion/styled";
import { Global, css } from "@emotion/react";
import { lightBorderColor } from "../../components/styles";
import "tippy.js/dist/tippy.css";
import { googleAnalyticsCustomEvent } from "../../../sharedUtilities/googleAnalyticsEvent";

export const TooltipBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 7.5px;
  box-sizing: border-box;
  font-size: 13px;
`;

export const TooltipContent = styled.div`
  font-size: 0.7rem;
  line-height: 1.4;
  color: #333;
  max-width: 250px;
`;

const tippyStyles = css`
  .tippy-box {
    background-color: rgba(255, 255, 255, 0.9) !important;
    border: 1px solid ${lightBorderColor} !important;
    border-radius: 4px !important;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15) !important;
  }

  .tippy-content {
    padding: 0 !important;
  }
`;

const ProductSpacePlot = memo(
  ({
    processedData,
    metaDataLookup,
    highlightedItem,
    select,
    setRingItem,
    setHighlightedItem,
  }: any) => {
    return (
      <>
        <Global styles={tippyStyles} />
        {processedData.map((node, i) => {
          const product = metaDataLookup.get(node.productId)?.data;
          return (
            <Tippy
              key={`tip-${node.productId}-${i}`}
              content={
                <TooltipContent>
                  <TooltipBody>{product?.nameShortEn}</TooltipBody>
                </TooltipContent>
              }
              plugins={[followCursor]}
              followCursor={true}
              trigger="mouseenter"
              disabled={highlightedItem === node.productId}
              arrow={true}
              animation={false}
              duration={150}
            >
              <circle
                cx={node.x}
                cy={node.y}
                r={node.r}
                fill={node.fill}
                strokeWidth="1"
                stroke="grey"
                style={{ cursor: "pointer" }}
                onMouseEnter={() => select(node.productId)}
                onMouseLeave={() => select(null)}
                onClick={() => {
                  setHighlightedItem(null);
                  setRingItem(node.productId);
                  googleAnalyticsCustomEvent(
                    "node",
                    "click",
                    "productspace",
                    "explore",
                  );
                }}
              />
            </Tippy>
          );
        })}
      </>
    );
  },
);

ProductSpacePlot.displayName = "ProductSpacePlot";
export default ProductSpacePlot;
