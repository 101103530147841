import { format } from "d3-format";
// import { GetString } from 'fluent-react';
import sortBy from "lodash-es/sortBy";
import React, { useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import GlossaryTerm, { TooltipAlign, TooltipType } from "./GlossaryTerm";
import { ArrowIconContainer } from "./Utils";
import usePromotableZIndex from "./usePromotableZIndex";
import Diamond, { Fill } from "./Diamond";
import StarRating from "./StarRating";
import TitleCell, { SortCriterion, SortDirection } from "./TitleCell";

// imports

// Taken from https://stackoverflow.com/a/22885197:
const log10 = Math.log(10);
const getSignificantDigitCount = (n: number) => {
  n = Math.abs(+String(n).replace(".", "")); //remove decimal and make positive
  if (n === 0) {
    return 0;
  }
  while (n !== 0 && n % 10 === 0) {
    n /= 10; //kill the 0s at the end of n
  }

  return Math.floor(Math.log(n) / log10) + 1; //get number of digits
};
// Format monetary sums into nice number with as many significant digits as in
// the input not exceeding 3. Also replace the `G` prefix with `B` because `B`
// makes more sense for money sums. The other prefixes are fine:
export const formatTradeValue = (input: number) => {
  const maxNumSignificantDigits = 3;
  const numSignificantDigitsInInput = getSignificantDigitCount(input);
  const numSignificantDigitsInOutput =
    numSignificantDigitsInInput <= maxNumSignificantDigits
      ? numSignificantDigitsInInput
      : maxNumSignificantDigits;
  return format(`$.${numSignificantDigitsInOutput}s`)(input).replace("G", "B");
};

export const formatTradeValueUSD = (input: number, getFluentString: any) => {
  return (
    getFluentString("trade-format-value-usd") + " " + formatTradeValue(input)
  );
};

export enum DecileClassification {
  Top = "Top",
  Second = "Second",
  Third = "Third",
  Fourth = "Fourth",
  Fifth = "Fifth",
  Sixth = "Sixth",
  Seventh = "Seventh",
  Eighth = "Eighth",
  Ninth = "Ninth",
  Last = "Last",
  Empty = "Empty",
}
export enum TradeFlow {
  Gross = "Gross",
  Net = "Net",
}

export enum TradeDirection {
  export = "export",
  import = "import",
}

export enum ProductClass {
  HS = "HS",
  SITC = "SITC",
}

export enum ProductLevel {
  section = "section",
  twoDigit = "twoDigit",
  fourDigit = "fourDigit",
  sixDigit = "sixDigit",
}

export enum QueryLevel {
  Location = "location",
  Group = "group",
}

export interface QueryStore {
  country: number | undefined;
  queryLevel: QueryLevel;
  id: number | undefined;
  product: number | undefined;
  year: number;
  startYear: number | undefined;
  tradeDirection: TradeDirection;
  productClass: ProductClass;
  nodeSizing: NodeSizing;
  tradeFlow: TradeFlow;
  target: Target;
  partner: number | undefined;
}

// "product" vs "partner" in graph control panel:
export enum Target {
  Product = "Product",
  Partner = "Partner",
}

export enum NodeSizing {
  None = "None",
  WorldTrade = "WorldTrade",
  CountryTrade = "CountryTrade",
}

// TODO: Move all `ProductClass` and `TradeFlow` references to `src/Utils` instead of this file:
export enum VizType {
  Tree = "tree",
  Geo = "geo",
  Stack = "stack",
  MarketShare = "marketshare",
  Network = "productspace",
  Feasibility = "feasibility",
  Rings = "rings",
}

// Errors out at compile time if a discriminating `switch` doesn't catch all cases
// of an enum and at run time if for some reason an invalid enum value is passed.
// See https://basarat.gitbooks.io/typescript/content/docs/types/discriminated-unions.html
export function failIfValidOrNonExhaustive(
  _variable: never,
  message: string,
): never {
  throw new Error(message);
}

export const nonBreakingWhiteSpaceCharacter = "\u00a0";
// ??? WHAT IS THIS FOR??
export const newGetDisplayedProductCode = (
  code: string,
  productClass: ProductClass,
  level: ProductLevel,
) => {
  // const productClassPhrase = (productClass === ProductClass.HS) ?
  //                             __lexiconText('applicationWide.productClass.HS') :
  //                             __lexiconText('applicationWide.productClass.SITC');
  // const productClassPhrase =
  //   productClass === ProductClass.HS
  //     ? __lexiconText("applicationWide.productClassWithVersion.HS")
  //     : __lexiconText("applicationWide.productClassWithVersion.SITC");

  // Make the space between product class and product code non-breaking:
  // return `${code} ${productClassPhrase}${newProductLevelToDigit(level)}`.replace(/ /g, nonBreakingWhiteSpaceCharacter);
  return `${code} ${productClass}`.replace(
    / /g,
    nonBreakingWhiteSpaceCharacter,
  );
};

// ____
const titleCellBaseZIndex = 20;
const promotedTitleCellZIndex = 3000;
const tooltipZIndex = 4000; // Higher than all other z-indices to ensure tooltips are always on top

const zIndices = {
  tableCellProductName: titleCellBaseZIndex + 5,
  tableCellComplexity: titleCellBaseZIndex,
  tableCellOpportunityGain: titleCellBaseZIndex,
  tableCellFeasibility: titleCellBaseZIndex,
  tableCellExportValue: titleCellBaseZIndex,
  tableCellExportValueChange: titleCellBaseZIndex,
  dataCell: 10,
  tooltip: tooltipZIndex,
};

//#region Styling
const strokeColor = "#808080";
const strokeLightColor = "#b3b3b3";
const productTextColor = "#666";
const Root = styled.div<any>`
  display: grid;
  grid-template-columns: ${(props) =>
    props.condensed
      ? "3fr repeat(2, 1.5fr)"
      : " 3fr repeat(2, 1.5fr) repeat(2, 1fr) 1.5fr"};
  overflow: auto;
  grid-auto-rows: max-content;

  /* stylelint-disable-next-line property-no-vendor-prefix */
  ::-webkit-scrollbar {
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-appearance: none;
    height: 8px;
    width: 8px;
  }
  /* stylelint-disable-next-line property-no-vendor-prefix */
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.3);
  }
  /* stylelint-disable-next-line property-no-vendor-prefix */
  ::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 1050px) {
    font-size: 0.8rem;
  }
`;
const cellBottomBorderStyle = css`
  border-bottom: 1px solid ${strokeLightColor};
`;

const DataCell = styled.div`
  ${cellBottomBorderStyle}
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: ${zIndices.dataCell};
`;
const titleColoredBarSeparationFromEdge = "0.5rem";
const ProductNameOuterContainer = styled(DataCell)`
  position: sticky;
  left: 0;
  background-color: #fff;
  z-index: 15;
  justify-content: flex-start;
  border-right: 1px solid ${strokeColor};

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: ${titleColoredBarSeparationFromEdge};
    bottom: ${titleColoredBarSeparationFromEdge};
    left: 0;
    width: 0.5rem;
    background-color: var(--colored-bar-background-color);
  }

  svg {
    display: none;
  }

  &:hover {
    background-color: #efefef;

    svg {
      display: block;
    }
  }
`;
const ExportValueChangeContainer = styled.div`
  margin-left: 0.5rem;
`;
const ProductName = styled.span`
  color: ${productTextColor};

  &::after {
    content: "";
    display: inline-block;
    margin-right: 0.5rem;
  }
`;
const ProductCode = styled.span`
  color: ${productTextColor};
  font-weight: 300;
`;
const ArrowDown = ArrowIconContainer;
const ArrowUp = styled(ArrowIconContainer)`
  transform: rotate(180deg);
`;

const ProductLink = styled.div`
  display: block;
  padding: 1rem;
  width: 100%;

  @media (max-width: 975px) {
    padding: 0.6rem 0.6rem 0.6rem 1rem;
  }
`;
const DistanceLegendContainer = styled.div`
  margin-top: 0.5rem;
`;
const DistanceLegend = styled.div`
  display: flex;
  position: relative;
  align-items: center;

  &:first-child {
    margin-bottom: 0.3rem;
  }
`;
const LegendLabel = styled.em`
  margin-left: 1rem;
  font-size: 0.9rem;

  @media (max-width: 1050px) {
    margin-left: 0.5rem;
    font-size: 0.8rem;
  }
`;
//#endregion

// Allow CSS custom properties
declare module "csstype" {
  interface Properties {
    "--colored-bar-background-color"?: string;
  }
}

const formatChange = format(".1~%");
const TableRow = (props: {
  product: TableDatum;
  year: number;
  condensed: boolean;
  highlight: Function;
  setRingItem: Function;
}) => {
  const { product, year, condensed, highlight, setRingItem } = props;
  const {
    id,
    color,
    name,
    code,
    complexityDecile,
    opportunityGainDecile,
    rcaDecile,
    feasibilityDecile,
    globalExportValue,
    globalExportValueChange,
  } = product;
  const productNameStyle: React.CSSProperties = {
    "--colored-bar-background-color": color,
  };
  const productCode = `(${newGetDisplayedProductCode(code, ProductClass.HS, ProductLevel.fourDigit)})`;
  const globalExportValueChangeMagnitude = Math.abs(globalExportValueChange);
  const arrowColor = globalExportValueChange >= 0 ? "#f1c086" : "#8fd8e5";
  const Arrow = globalExportValueChange >= 0 ? ArrowUp : ArrowDown;

  const productIDString = id.split("product-HS-").pop();
  let productIDNumber: number | undefined;
  if (productIDString !== undefined) {
    productIDNumber = parseInt(productIDString, 10);
  }
  const mouseEnter = () => highlight(id);
  const mouseOut = () => highlight(null);
  const click = () => setRingItem(id);
  return (
    <React.Fragment>
      <ProductNameOuterContainer
        onMouseEnter={mouseEnter}
        onMouseLeave={mouseOut}
        onClick={click}
        style={productNameStyle}
      >
        <ProductLink>
          <ProductName>{name}</ProductName>
          <ProductCode>{productCode}</ProductCode>
        </ProductLink>
      </ProductNameOuterContainer>
      <DataCell
        onMouseEnter={mouseEnter}
        onMouseLeave={mouseOut}
        onClick={click}
      >
        <StarRating decile={feasibilityDecile} />
      </DataCell>
      {!condensed && (
        <DataCell>
          <StarRating decile={opportunityGainDecile} />
        </DataCell>
      )}
      <DataCell
        onMouseEnter={mouseEnter}
        onMouseLeave={mouseOut}
        onClick={click}
      >
        <StarRating decile={complexityDecile} />
      </DataCell>
      {!condensed && <DataCell>{formatTradeValue(globalExportValue)}</DataCell>}
      {!condensed && (
        <DataCell>
          <Arrow
            color={arrowColor}
            height={20}
            // dangerouslySetInnerHTML={{
            //   __html: require("./arrow.svg"),
            // }}
          />
          {!condensed && (
            <ExportValueChangeContainer>
              {formatChange(globalExportValueChangeMagnitude)}
            </ExportValueChangeContainer>
          )}
        </DataCell>
      )}
    </React.Fragment>
  );
};

export interface TableDatum {
  normalizedDistance: unknown;
  exportRca: unknown;
  normalizedCog: unknown;
  id: string;
  color: string;
  name: string;
  code: string;
  complexityDecile: DecileClassification;
  rcaDecile: DecileClassification;
  opportunityGainDecile: DecileClassification;
  feasibilityDecile: DecileClassification;
  globalExportValue: number;
  globalExportValueChange: number;
  normalizedPci: number;
  normalizedFeasibility: number;
  normalizedOpportunityGain: number;
  score: number;
}

interface Props {
  products: TableDatum[];
  getFluentString: any;
  width: number;
  height: number;
  zIndexFromParent: number;
  year: number;
  condensed?: boolean;
  highlight?: Function;
  setRingItem?: Function;
}

const Table = (props: Props) => {
  const {
    products,
    getFluentString = () => "",
    width = 1000,
    height = 600,
    zIndexFromParent = 10,
    year = 2021,
    condensed = false,
    highlight = () => {},
    setRingItem = () => {},
  } = props;
  const [sortCriterion, setSortCriterion] = useState<SortCriterion>(
    SortCriterion.Complexity,
  );
  const [sortDirection, setSortDirection] = useState<SortDirection>(
    SortDirection.Descending,
  );

  const { promoteZIndex, restoreZIndex, getZIndex } = usePromotableZIndex({
    normalZIndices: zIndices,
    promotedZIndex: promotedTitleCellZIndex,
  });

  const getScore = (product: TableDatum) => product.score;
  let productsSortedAscendingly: typeof products;
  if (sortCriterion === SortCriterion.Complexity) {
    productsSortedAscendingly = sortBy(products, [
      (product) => product.normalizedPci,
    ]);
  } else if (sortCriterion === SortCriterion.OpportunityGain) {
    productsSortedAscendingly = sortBy(products, [
      (product) => product.normalizedCog,
    ]);
  } else if (sortCriterion === SortCriterion.Feasibility) {
    productsSortedAscendingly = sortBy(products, [
      (product) => product.normalizedDistance,
    ]);
  } else if (sortCriterion === SortCriterion.ExportValue) {
    productsSortedAscendingly = sortBy(products, [
      (product) => product.globalExportValue,
    ]);
  } else if (sortCriterion === SortCriterion.ExportValueChange) {
    productsSortedAscendingly = sortBy(products, [
      (product) => product.globalExportValueChange,
    ]);
  } else if (sortCriterion === SortCriterion.RCA) {
    productsSortedAscendingly = sortBy(products, [
      (product) => product.exportRca,
    ]);
  } else {
    failIfValidOrNonExhaustive(
      sortCriterion,
      "Invalid sort criterion " + sortCriterion,
    );
    productsSortedAscendingly = products;
  }

  let sortedProducts: typeof products;
  if (sortDirection === SortDirection.Ascending) {
    sortedProducts = productsSortedAscendingly;
  } else if (sortDirection === SortDirection.Descending) {
    sortedProducts = productsSortedAscendingly.reverse();
  } else {
    failIfValidOrNonExhaustive(
      sortDirection,
      "Invalid sort direction" + sortDirection,
    );
    sortedProducts = productsSortedAscendingly;
  }

  const onColumnTitleClick = (nextCriterion: SortCriterion) => {
    const prevCriterion = sortCriterion;
    if (prevCriterion !== nextCriterion) {
      setSortCriterion(nextCriterion);
      setSortDirection(SortDirection.Descending);
    } else {
      // If same criterion is requested, flip sort direction:
      const prevDirection = sortDirection;
      let newDirection: SortDirection;
      if (prevDirection === SortDirection.Ascending) {
        newDirection = SortDirection.Descending;
      } else if (prevDirection === SortDirection.Descending) {
        newDirection = SortDirection.Ascending;
      } else {
        failIfValidOrNonExhaustive(
          prevDirection,
          "Invalid sort direction " + prevDirection,
        );
        newDirection = SortDirection.Ascending;
      }
      setSortDirection(newDirection);
    }
  };

  const distanceTooltip = (
    <DistanceLegendContainer>
      <DistanceLegend>
        <Diamond fill={Fill.InvertedFull} />
        <Diamond fill={Fill.InvertedFull} />
        <Diamond fill={Fill.InvertedFull} />
        <Diamond fill={Fill.InvertedFull} />
        <Diamond fill={Fill.InvertedFull} />
        <LegendLabel>Opportunity Gain</LegendLabel>
      </DistanceLegend>
      <DistanceLegend>
        <Diamond fill={Fill.InvertedNone} />
        <Diamond fill={Fill.InvertedNone} />
        <Diamond fill={Fill.InvertedNone} />
        <Diamond fill={Fill.InvertedNone} />
        <Diamond fill={Fill.InvertedNone} />
        <LegendLabel>most distant</LegendLabel>
      </DistanceLegend>
    </DistanceLegendContainer>
  );

  const titleRow = (
    <>
      <TitleCell
        hasAssignedSortCriterion={false}
        zIndex={getZIndex("tableCellProductName")}
        stickColumnToLeft={true}
      >
        Product Name
        {/* {getFluentString("high-potential-products-table-name-column-title")} */}
      </TitleCell>
      <TitleCell
        hasAssignedSortCriterion={true}
        assignedSortCriterion={SortCriterion.Feasibility}
        onClick={onColumnTitleClick}
        currentSortCriterion={sortCriterion}
        currentSortDirection={sortDirection}
        zIndex={getZIndex("tableCellFeasibility")}
        promoteZIndex={() => promoteZIndex("tableCellFeasibility")}
        restoreZIndex={restoreZIndex}
      >
        <div>
          <GlossaryTerm
            type={TooltipType.Standard}
            term={`"Nearby" Distance`}
            explanation={
              "A country's ability to enter in to a new product, measured from 0 to 1. A 'nearby' product (closer to 0) requires related capabilities to existing products, offering a greater likelihood of success."
            }
            citation={undefined}
            align={TooltipAlign.Standard}
            attachment={distanceTooltip}
          />
        </div>
      </TitleCell>
      {!condensed && (
        <TitleCell
          hasAssignedSortCriterion={true}
          assignedSortCriterion={SortCriterion.OpportunityGain}
          onClick={onColumnTitleClick}
          currentSortCriterion={sortCriterion}
          currentSortDirection={sortDirection}
          zIndex={getZIndex("tableCellOpportunityGain")}
          promoteZIndex={() => promoteZIndex("tableCellOpportunityGain")}
          restoreZIndex={restoreZIndex}
        >
          <div>
            <GlossaryTerm
              type={TooltipType.Standard}
              term={"Opportunity Gain"}
              explanation={
                "Measures opportunities for future diversification in entering a product, by opening new links to complex products"
              }
              citation={undefined}
              align={TooltipAlign.Standard}
              attachment={null}
            />
          </div>
        </TitleCell>
      )}
      <TitleCell
        hasAssignedSortCriterion={true}
        assignedSortCriterion={SortCriterion.Complexity}
        currentSortCriterion={sortCriterion}
        currentSortDirection={sortDirection}
        onClick={onColumnTitleClick}
        zIndex={getZIndex("tableCellComplexity")}
        promoteZIndex={() => promoteZIndex("tableCellComplexity")}
        restoreZIndex={restoreZIndex}
      >
        <div>
          <GlossaryTerm
            type={TooltipType.Standard}
            term={"Product Complexity"}
            explanation={
              "Measures the amount of diversity of knowhow required to make a product."
            }
            citation={undefined}
            align={TooltipAlign.Standard}
            attachment={null}
          />
        </div>
      </TitleCell>
      {!condensed && (
        <TitleCell
          hasAssignedSortCriterion={true}
          assignedSortCriterion={SortCriterion.ExportValue}
          onClick={onColumnTitleClick}
          currentSortCriterion={sortCriterion}
          currentSortDirection={sortDirection}
          zIndex={getZIndex("tableCellExportValue")}
          promoteZIndex={() => promoteZIndex("tableCellExportValue")}
          restoreZIndex={restoreZIndex}
        >
          Global size (USD)
          {/* {getFluentString("high-potential-products-table-trade-column-title")} */}
        </TitleCell>
      )}
      {!condensed && (
        <TitleCell
          hasAssignedSortCriterion={true}
          assignedSortCriterion={SortCriterion.ExportValueChange}
          onClick={onColumnTitleClick}
          currentSortCriterion={sortCriterion}
          currentSortDirection={sortDirection}
          zIndex={getZIndex("tableCellExportValueChange")}
          promoteZIndex={() => promoteZIndex("tableCellExportValueChange")}
          restoreZIndex={restoreZIndex}
        >
          Global growth 5 YR
          {
            // getFluentString(
            //   "high-potential-products-table-trade-change-column-title",
            // )
          }
        </TitleCell>
      )}
    </>
  );

  const contentRows = sortedProducts.map((product) => (
    <TableRow
      product={product}
      year={year}
      key={product.id}
      condensed={condensed}
      highlight={highlight}
      setRingItem={setRingItem}
    />
  ));
  return (
    <>
      <div id="overlay-portal-container" />
      <Root
        condensed={condensed}
        style={{
          width,
          height,
          zIndex: zIndexFromParent,
        }}
      >
        {titleRow}
        {contentRows}
      </Root>
    </>
  );
};

export default Table;
