import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
} from "@mui/material";

export interface MetadataField {
  fieldName: string;
  description: string;
  dataType: string;
  notes: string;
}

interface MetadataTableProps {
  metadata: MetadataField[];
}

// Styled components using the MUI styled API
const StyledTableContainer = styled(TableContainer)({
  height: "100%",
  boxShadow: "none",
  border: "none",
  display: "flex",
  flexDirection: "column",
});

const StyledTable = styled(Table)({
  borderCollapse: "collapse",
  "& th, & td": {
    border: "1px solid lightgray",
  },
});

const HeaderCell = styled(TableCell)({
  backgroundColor: "lightgray",
  fontWeight: 600,
  padding: "2px 2px",
  border: "1px solid gray",
});

const BodyCell = styled(TableCell)({
  // padding: "8px 16px",
});

const MonospaceCell = styled(BodyCell)({
  fontFamily: "monospace",
});

const NotesCell = styled(BodyCell)({
  fontSize: "0.875rem",
});

const StyledTableRow = styled(TableRow)({
  "&:nth-of-type(odd)": {
    backgroundColor: "#f5f5f5",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#ffffff",
  },
});

const MetadataTable = ({ metadata }: MetadataTableProps) => {
  return (
    <StyledTableContainer>
      <Paper
        variant="outlined"
        sx={{
          boxShadow: "none",
          display: "flex",
          flexDirection: "column",

          overflow: "hidden",
          height: "275px",
        }}
      >
        <TableContainer sx={{ flex: 1, overflow: "auto" }}>
          <StyledTable size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <HeaderCell>Field Name</HeaderCell>
                <HeaderCell>Description</HeaderCell>
                <HeaderCell>Data Type</HeaderCell>
                <HeaderCell>Notes</HeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {metadata.map((row, index) => (
                <StyledTableRow key={row.fieldName}>
                  <MonospaceCell>{row.fieldName}</MonospaceCell>
                  <BodyCell>{row.description}</BodyCell>
                  <BodyCell>{row.dataType}</BodyCell>
                  <NotesCell>{row.notes}</NotesCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </StyledTable>
        </TableContainer>
      </Paper>
    </StyledTableContainer>
  );
};

export default MetadataTable;
