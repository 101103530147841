import { useState } from "react";
import ModalBase, { ModalType } from "../modals/ModalBase";
import SubregionMap from "../../assets/images/Subregion-map.png";
import SubregionMapIcon from "../../assets/images/ui/subregion-map.svg";
import styled from "@emotion/styled";
import {
  OptionIcon,
  OptionLabel,
  OptionsListItem,
} from "../navigationBar/Components";
import { googleAnalyticsCustomEvent } from "../../sharedUtilities/googleAnalyticsEvent";

const SubregionMapImage = styled.img`
  width: 100%;
  height: 100%;
`;

const SubregionMapModalButton = ({
  menuOptionClassName,
  isNavigationBarOpen,
}: any) => {
  let [isSubregionMapOpen, setIsSubregionMapOpen] = useState<boolean>(false);

  const toggleSubregionMap = () => {
    if (!isSubregionMapOpen) {
      googleAnalyticsCustomEvent("leftnav", "click", "regionmap", "explore");
    }
    const newIsSubregionMapOpen = !isSubregionMapOpen;
    setIsSubregionMapOpen(newIsSubregionMapOpen);
  };

  let subregionMapModal = null;

  if (isSubregionMapOpen) {
    subregionMapModal = (
      <ModalBase
        modalType={ModalType.SubregionMap}
        toggleModal={toggleSubregionMap}
      >
        <p>
          The Atlas contains trade data for 250 countries which are aggregated
          into regional and continental groups. Atlas regions are a modified
          version of the{" "}
          <a href="https://unstats.un.org/unsd/methodology/m49" target="_blank">
            UN Statistical Division
          </a>{" "}
          geographic regions.
        </p>
        <SubregionMapImage src={SubregionMap} />
      </ModalBase>
    );
  }

  return (
    <>
      <OptionsListItem
        className={menuOptionClassName}
        onClick={toggleSubregionMap}
      >
        <OptionIcon>
          <img src={SubregionMapIcon} alt={"Subregion Map"} />
        </OptionIcon>
        {isNavigationBarOpen === true && (
          <OptionLabel>Subregion Map</OptionLabel>
        )}
      </OptionsListItem>
      {subregionMapModal}
    </>
  );
};

export default SubregionMapModalButton;
