import { scaleLinear } from "d3";
import { Text } from "@visx/text";
import { getFillColor } from ".";
import Tippy from "@tippyjs/react";
import { followCursor } from "tippy.js";
import { TooltipBody, TooltipContent } from "./ProductSpacePlot";
import "tippy.js/dist/tippy.css";
import { css, Global } from "@emotion/react";
import { lightBorderColor } from "../../components/styles";

const getTextAnchor = (angle) => {
  if (angle >= 0 && angle < Math.PI / 2) {
    return "start";
  } else if (angle >= Math.PI / 2 && angle < Math.PI) {
    return "end";
  } else if (angle >= Math.PI && angle < (3 * Math.PI) / 2) {
    return "end";
  } else {
    return "start";
  }
};

const getVerticalAnchor = (angle) => {
  if (angle >= 0 && angle <= Math.PI) {
    return "start";
  } else {
    return "end";
  }
};

const moveTextAwayFromCenter = (x, y, angle, radius) => {
  const translateX = x + radius * Math.cos(angle);
  const translateY = y + radius * Math.sin(angle);
  return `translate(${translateX}, ${translateY})`;
};

const truncateText = (text: string, maxLength: number = 15) => {
  return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
};

const tippyStyles = css`
  .tippy-box {
    background-color: rgba(255, 255, 255, 0.9) !important;
    border: 1px solid ${lightBorderColor} !important;
    border-radius: 4px !important;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15) !important;
  }

  .tippy-content {
    padding: 0 !important;
  }
`;

const ProductRing = ({
  linkLookup,
  selection,
  nodeLookup,
  metaDataLookup,
  colorMap,
  setRingItem,
  highlighted,
  highlight,
  currentColorBySelection,
  clusterColorLookup,
  complexityColorScale,
  totalExportLookup,
  countryExportLookup,
  rcaThreshold,
}) => {
  const width = 600;
  const height = 600;

  const focusNode = nodeLookup.get(selection);
  const focusProductData = metaDataLookup.get(focusNode.productId)?.data;
  const focusTopParentId = focusProductData?.topParent?.productId;
  const fellowNodes = linkLookup[selection]?.map((n: any) => nodeLookup.get(n));

  const angleScale = scaleLinear()
    .domain([0, fellowNodes.length])
    .range([0, 2 * Math.PI]);
  const radius = Math.min(width, height) / 2 - 120;

  const centerX = width / 2;
  const centerY = height / 2;
  const { fill: parentFill } = getFillColor(
    focusNode,
    focusProductData,
    focusTopParentId,
    currentColorBySelection,
    colorMap,
    clusterColorLookup,
    complexityColorScale,
    totalExportLookup,
    [],
    countryExportLookup,
    rcaThreshold,
  );
  return (
    <>
      <Global styles={tippyStyles} />
      <svg width="100%" height="100%" viewBox={`0 0 ${height} ${width}`}>
        <circle
          cx={centerX}
          cy={centerY}
          r={radius}
          fill="none"
          stroke="#ccc"
        />
        <circle
          cx={centerX}
          cy={centerY}
          r={nodeLookup.get(selection).r}
          fill={parentFill}
        />
        <Text
          x={centerX}
          y={centerY + nodeLookup.get(selection).r}
          textAnchor="middle"
          width={100}
          verticalAnchor="start"
        >
          {focusProductData.nameShortEn}
        </Text>
        {fellowNodes.map((d, i) => {
          const productData = metaDataLookup.get(d.productId)?.data;
          const topParentId = productData?.topParent?.productId;
          const x = centerX + radius * Math.cos(angleScale(i) - Math.PI / 2);
          const y = centerY + radius * Math.sin(angleScale(i) - Math.PI / 2);
          const angle = angleScale(i) - Math.PI / 2;
          const r = nodeLookup.get(d.productId).r;
          const transform = moveTextAwayFromCenter(x, y, angle, r + 10);
          const { fill } = getFillColor(
            d,
            productData,
            topParentId,
            currentColorBySelection,
            colorMap,
            clusterColorLookup,
            complexityColorScale,
            totalExportLookup,
            [],
            countryExportLookup,
            rcaThreshold,
          );
          return (
            <>
              <Tippy
                key={`tip-${d.productId}-${i}`}
                content={
                  <TooltipContent>
                    <TooltipBody>{productData.nameShortEn}</TooltipBody>
                  </TooltipContent>
                }
                plugins={[followCursor]}
                followCursor={true}
                trigger="mouseenter"
                arrow={true}
                animation={false}
                duration={150}
                zIndex={99999999}
              >
                <circle
                  key={i}
                  cx={x}
                  cy={y}
                  r={r}
                  fill={fill}
                  onClick={() => setRingItem(d.productId)}
                  cursor="pointer"
                  strokeWidth="2"
                  stroke="black"
                  strokeOpacity={d.productId === highlighted ? 1 : 0}
                  onMouseEnter={() => highlight(d.productId)}
                  onMouseLeave={() => highlight(null)}
                />
              </Tippy>
              <Text
                key={`label-${i}`}
                dy="0.35em"
                textAnchor={getTextAnchor(angle)}
                verticalAnchor={getVerticalAnchor(angle)}
                width={125}
                style={{ pointerEvents: "auto", cursor: "default" }}
                transform={transform}
              >
                {truncateText(productData.nameShortEn)}
              </Text>
            </>
          );
        })}
      </svg>
    </>
  );
};

export default ProductRing;
