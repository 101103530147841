import { useQuery } from "@apollo/client";
import {
  VisualizationContentContainer,
  VisualizationHandlesContainer,
  VisualizationBottomRowContainer,
} from "../../VizGrid";
import {
  ColorBy,
  ProductLevel,
  determineAPICallLocationOrder,
  getCategoriesForChartLegend,
  getTradeDirection,
} from "../../Utils";
import CategoryLabels from "../../components/CategoryLabels";
import {
  useRef,
  useState,
  useEffect,
  useCallback,
  useContext,
  useMemo,
} from "react";
import Chart from "./Chart";
import { UIView } from "../../Utils";
import determineEndpointFacet from "../../../graphql/determineEndpointFacet";
import { CategoryDatum } from "../../components/LegendLabel";
import Tooltip, { TooltipContent } from "../../components/Tooltip";
import { convertProductStringToNumberId } from "../../../sharedUtilities/Utils";
import { allProductsDatum } from "../../../graphql/queries/getProductsMetadata";
import { memo } from "react";
import Loading from "../../components/GraphLoading";
import { mapQueryArgumentsToAPIEndpointInputs } from "../../../graphql/Utils";
import { useOutletContext } from "react-router-dom";
import ComplexityColorScaleLegend from "./ComplexityColorScaleLegend";
import { ChartContainerSizeContext } from "../../components/GenericResizeContainer";
import GraphNotice, { GraphNoticeType } from "../../components/GraphNotice";
import { usePageQueryParams } from "../../defaultSettings";
import { useTotalValue } from "../../TotalValueContext";

const Treemap = () => {
  const [
    {
      exporter,
      importer,
      product: productId,
      view,
      productClass,
      productLevel,
      year,
      colorBy: currentColorBy,
      locationLevel: currentLocationDetailLevel,
      tradeFlow,
      servicesClass,
    },
  ] = usePageQueryParams();

  const tooltipContentRef = useRef<HTMLDivElement | null>(null);
  const {
    visualizationElementRef: treemapElement,
    findInVizOptions,
    setFindInVizOptions: actuallySetFindInVizOptions,
    highlightedItem,
    setHighlightedItem,
  }: any = useOutletContext();

  const [chartContainerOffset, setChartContainerOffset] = useState<
    { left: number; top: number } | undefined
  >(undefined);

  const [tooltipContent, actuallySetTooltipContent] = useState<
    | {
        isFixed: boolean | undefined;
        isFixedCoords: [number, number] | undefined;
        contentData: any | undefined;
        fillColor: string | undefined;
      }
    | undefined
  >(undefined);

  const [hiddenCategories, setHiddenCategories] = useState<string[]>([]);
  useEffect(() => {
    if (currentColorBy === ColorBy.Complexity) {
      setHiddenCategories([]);
    }
  }, [setHiddenCategories, currentColorBy]);
  const [totalValue, setTotalValue] = useTotalValue();

  const { chartHeight } = useContext(ChartContainerSizeContext);

  const setTooltipContent = useCallback(
    (input: any) => {
      if (input === undefined) {
        setHighlightedItem(undefined);
      }
      actuallySetTooltipContent(input);
    },
    [setHighlightedItem, actuallySetTooltipContent],
  );

  const setFindInVizOptions = useCallback(
    (input: any) => {
      let newFindInVizIds = input.map((option: any) => option.id);
      let currentFindInVizIds = findInVizOptions.map(
        (option: any) => option.id,
      );
      let findInVizOptionsAreEqual = newFindInVizIds.every((id: string) =>
        currentFindInVizIds.includes(id),
      );

      if (currentFindInVizIds.length == 0 || !findInVizOptionsAreEqual) {
        actuallySetFindInVizOptions(input);
      }
    },
    [findInVizOptions, actuallySetFindInVizOptions],
  );

  useEffect(() => {
    if (treemapElement && treemapElement.current) {
      const node = treemapElement.current;
      const { top, left } = node.getBoundingClientRect();

      if (
        chartContainerOffset === undefined ||
        chartContainerOffset.left !== left ||
        chartContainerOffset.top !== top
      ) {
        setChartContainerOffset({ left, top });
      }
    }
  }, [chartContainerOffset, treemapElement]);

  useEffect(() => {
    setHighlightedItem(undefined);
    setTooltipContent(undefined);
  }, [setHighlightedItem, setTooltipContent, view]);

  const useProductLevel = useMemo(() => {
    if (view === UIView.Markets) {
      if (productId === allProductsDatum.productId) {
        return ProductLevel.ProductSection;
      } else {
        return undefined;
      }
    } else {
      return productLevel;
    }
  }, [view, productId, productLevel]);

  const narrowedTreeMapInputVariables = useMemo(
    () =>
      mapQueryArgumentsToAPIEndpointInputs({
        exporter,
        importer,
        view,
      }),
    [exporter, importer, view],
  );

  const treeMapInputVariables: any = useMemo(
    () => ({
      productClass: productClass,
      yearMax: year,
      yearMin: year,
      productLevel: useProductLevel,
      servicesClass,
      productId:
        productId === allProductsDatum.productId
          ? undefined
          : convertProductStringToNumberId(productId),
      ...narrowedTreeMapInputVariables,
    }),
    [
      productClass,
      year,
      useProductLevel,
      servicesClass,
      productId,
      narrowedTreeMapInputVariables,
    ],
  );

  // Update API call location order
  const { locationForAPI, partnerForAPI } = useMemo(
    () =>
      determineAPICallLocationOrder({
        variables: treeMapInputVariables,
      }),
    [treeMapInputVariables],
  );

  const { queryToUse, queryIsInvalid } = useMemo(
    () =>
      determineEndpointFacet({
        view: view as any,
        variables: treeMapInputVariables,
      }),
    [view, treeMapInputVariables],
  );

  const onHover = useCallback(
    ({
      datum,
      leaf,
      setTooltipFixed,
      setTooltipCoords,
      fillColor,
    }: {
      datum: any;
      leaf: any | undefined;
      setTooltipFixed: boolean | undefined;
      setTooltipCoords: [number, number] | undefined;
      fillColor: string | undefined;
    }) => {
      // The following adjusts the highlighted cell's x and y position
      // to account for the translation of the chart container relative
      // to the page -- this is required to correctly position
      // the tooltip when a cell is highlighted
      let coordsAdjustedWithOffset;
      if (setTooltipCoords && chartContainerOffset) {
        let [unadjustedX, unadjustedY] = setTooltipCoords;
        if (leaf && leaf.y1 < chartHeight / 2) {
          coordsAdjustedWithOffset = [
            unadjustedX + chartContainerOffset.left,
            leaf.y1 + chartContainerOffset.top,
          ];
        } else {
          coordsAdjustedWithOffset = [
            unadjustedX + chartContainerOffset.left,
            unadjustedY + chartContainerOffset.top,
          ];
        }
      } else {
        coordsAdjustedWithOffset = undefined;
      }
      if (datum) {
        let { productLevel } = datum;
        if (productLevel && productLevel === ProductLevel.ProductSection) {
          let mappedDatum = {
            ...datum,
            topLevelParent: datum.productId,
          };
          setTooltipContent({
            isFixed: setTooltipFixed,
            isFixedCoords: coordsAdjustedWithOffset,
            contentData: mappedDatum,
            fillColor,
          });
        } else {
          setTooltipContent({
            isFixed: setTooltipFixed,
            isFixedCoords: coordsAdjustedWithOffset,
            contentData: datum,
            fillColor,
          });
        }
      } else {
        setTooltipContent(undefined);
      }
    },
    [chartContainerOffset, chartHeight, setTooltipContent],
  );

  const { loading, error, data, previousData } = useQuery(queryToUse, {
    variables: treeMapInputVariables,
    skip: queryIsInvalid,
  });
  useEffect(() => {
    setTooltipContent(null);
  }, [data, setTooltipContent]);
  const successResponse = data ? data : previousData;

  let content: any = null;
  let graphLoading: any = <Loading />;
  let graphLoadingHasError: boolean = false;
  const currentTradeDirection = useMemo(
    () =>
      getTradeDirection({
        exporter,
        importer,
        locationForAPI,
        partnerForAPI,
      }),
    [exporter, importer, locationForAPI, partnerForAPI],
  );

  if (successResponse) {
    if (exporter || importer || productId) {
      content = (
        <Chart
          handleTooltip={onHover}
          inputData={successResponse}
          queryVariables={{
            view,
            exporter,
            importer,
            productId,
            year,
            productClass,
            productLevel,
            currentLocationDetailLevel,
            tradeFlow,
            currentColorBy,
          }}
          hiddenCategories={hiddenCategories}
          setTotalValue={setTotalValue}
          setFindInVizOptions={setFindInVizOptions}
          findInVizOptions={findInVizOptions}
          highlightedItem={highlightedItem}
          tradeDirection={currentTradeDirection}
          isLoading={loading}
        />
      );
    }
  } else if (error) {
    content = <GraphNotice graphNoticeType={GraphNoticeType.Error} />;
    graphLoadingHasError = true;
    console.log(error);
  }

  let treemapLegend;
  if (view === UIView.Products && currentColorBy === ColorBy.Complexity) {
    treemapLegend = <ComplexityColorScaleLegend />;
  } else {
    let categoriesForLegend: CategoryDatum[] = getCategoriesForChartLegend({
      view,
      productClass,
    });
    let resetText: string = "";
    if (view === UIView.Markets) {
      resetText = "Show All Regions";
    } else if (view === UIView.Products) {
      resetText = "Show All Sectors";
    }

    treemapLegend = (
      <CategoryLabels
        categories={categoriesForLegend}
        allowToggle={true}
        hiddenCategories={hiddenCategories}
        setHiddenCategories={setHiddenCategories}
        resetText={resetText}
        fullWidth={true}
      />
    );
  }

  let tooltipExplanationElement;
  if (tooltipContent && !loading) {
    tooltipExplanationElement = (
      <TooltipContent
        datum={tooltipContent && tooltipContent.contentData}
        view={view}
        totalValue={totalValue}
        tradeDirection={currentTradeDirection}
        productClass={productClass}
        ref={tooltipContentRef}
        fillColor={tooltipContent && tooltipContent.fillColor}
      />
    );
  } else {
    tooltipExplanationElement = null;
  }

  const clearHighlightedItem = () => setHighlightedItem(undefined);

  let graphLoadingElement;
  if (graphLoadingHasError) {
  } else if (!content || loading) {
    graphLoadingElement = graphLoading;
  } else {
    graphLoadingElement = null;
  }

  return (
    <>
      <Tooltip
        explanation={tooltipExplanationElement}
        isFixed={tooltipContent && tooltipContent.isFixed}
        isFixedCoords={tooltipContent && tooltipContent.isFixedCoords}
        cursor={"default"}
        overrideStyles={true}
        clearHighlightedItem={clearHighlightedItem}
        key={`${exporter}-${importer}-${productId}-${year}-${view}`}
      >
        <VisualizationContentContainer
          ref={treemapElement}
          key={"visualization_content_container"}
        >
          {content}
          {graphLoadingElement}
        </VisualizationContentContainer>
      </Tooltip>
      <VisualizationBottomRowContainer>
        <VisualizationHandlesContainer>
          {treemapLegend}
        </VisualizationHandlesContainer>
      </VisualizationBottomRowContainer>
    </>
  );
};

const TreemapEntry = () => {
  return <Treemap />;
};

export default memo(TreemapEntry);
