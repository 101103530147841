import styled from "@emotion/styled";
import { RouteID } from "../../routing/routes";
import Card from "./Card";
import { breakpointSmall } from "./Utils";
import { googleAnalyticsCustomEvent } from "../../sharedUtilities/googleAnalyticsEvent";

const Container = styled.div`
  display: grid;
  grid-gap: 3rem;
  max-width: 1200px;
  margin: auto;
  grid-column: 2;

  @media (min-width: ${breakpointSmall}px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const IntroCards = () => {
  return (
    <Container>
      <Card
        title="Explore"
        body="Build custom data visualizations for any country to reveal 50+ years of global trade flows across 6,000+ goods & services, along with new opportunities to drive growth."
        ctaText="Go To Explore"
        ctaDestination={RouteID.TreeMap}
        placeholderImageUrl={require("./img/export-image-thumbnail.png")}
        videoUrl={require("./video/export-intro-video.mp4")}
        showAward={false} // TO DO: showAward is ridiculous.
        href="/explore/treemap"
        analyticsEvent={() => {
          googleAnalyticsCustomEvent("card", "click", "explore", "home");
        }}
      />
      <Card
        title="Country Profiles"
        body="Take an interactive journey through a country's economic structure and dynamic growth patterns to uncover the strategy necessary to achieve greater prosperity."
        ctaText="Go To The Country Profiles"
        ctaDestination={RouteID.CountryProfiles}
        placeholderImageUrl={require("./img/country-profiles-landing.png")}
        videoUrl={require("./video/cp-intro-video.mp4")}
        showAward={true}
        href="/countries"
        analyticsEvent={() => {
          googleAnalyticsCustomEvent("card", "click", "cp", "home");
        }}
      />
      <Card
        title="Annual Growth Projections"
        body="Discover the countries rising and falling in Harvard Growth Lab's annual country growth projections."
        ctaText="Go To Growth Projections"
        ctaDestination={RouteID.GrowthProjections}
        placeholderImageUrl={require("./img/growth-image-thumbnail.png")}
        videoUrl={undefined}
        showAward={false}
        href="/growth-projections"
        analyticsEvent={() => {
          googleAnalyticsCustomEvent("card", "click", "gp", "home");
        }}
      />
      <Card
        title="Complexity Rankings"
        body="Explore Harvard Growth Lab’s annual ranking of country and product complexity."
        ctaText="Go To Complexity Rankings"
        ctaDestination={RouteID.CountryRankings}
        placeholderImageUrl={require("./img/research-image-thumbnail.png")}
        videoUrl={undefined}
        showAward={false}
        href="/rankings"
        analyticsEvent={() => {
          googleAnalyticsCustomEvent("card", "click", "complexity", "home");
        }}
      />
    </Container>
  );
};

export default IntroCards;
