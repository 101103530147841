import nodes from "../../visualization/charts/productspace/umap_ps_pruned.json";
import randomDistribution from "./randomDistribution.json";
import { scaleLinear, scaleSqrt } from "@visx/scale";
import {
  useTransition,
  animated,
  useSpring,
  useSpringRef,
  useChain,
  to,
} from "@react-spring/web";
import clusterColors from "../../visualization/charts/productspace/cluster_colors.json";
import { index, extent, stratify } from "d3";
import { useMemo, useState, useEffect } from "react";
import centroids from "../../visualization/charts/productspace/centroids.json";
import { ReactComponent as ComputerIcon } from "./img/computer.svg";
import { ReactComponent as PantsIcon } from "./img/pants.svg";
import { ReactComponent as ShirtIcon } from "./img/shirt.svg";
import { ReactComponent as PhoneIcon } from "./img/phone.svg";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import links from "../../visualization/charts/productspace/top_edges_pruned.json";
import ClickIcon from "./img/click.svg";

const clusterColorLookup = index(clusterColors, (d) => d.cluster_name_short);

const steps = [
  //random
  {
    layout: randomDistribution,
    fill: "#E4E4E4",
    stroke: "#727272",
    radius: 4,
    productLabels: true,
    clusterLabels: false,
    links: false,
  },
  //product space
  {
    layout: nodes,
    fill: null,
    stroke: "grey",
    radius: 4,
    productLabels: true,
    clusterLabels: true,
    links: false,
  },
  //node sizing
  {
    layout: nodes,
    fill: null,
    stroke: "grey",
    radius: "globalTrade",
    productLabels: false,
    clusterLabels: true,
    links: false,
  },
  //rca coloring
  {
    layout: nodes,
    fill: "rca",
    stroke: "grey",
    radius: "globalTrade",
    productLabels: false,
    clusterLabels: true,
    links: true,
    interactive: true,
  },

  {
    layout: nodes,
    fill: "rca",
    stroke: "grey",
    radius: "globalTrade",
    productLabels: false,
    clusterLabels: false,
    links: true,
    zoom: true,
    zoomTarget: "8701",
    interactive: false,
  },
];

export const ProductHighlights = ({
  step,
  width,
  height,
  xScale,
  yScale,
  layout,
}: {
  step: number;
  width: number;
  height: number;
  xScale: any;
  yScale: any;
  layout: any[];
}) => {
  const lineSpringRef = useSpringRef();
  const iconSpringRef = useSpringRef();

  const getLineLength = (x1: number, y1: number, x2: number, y2: number) => {
    return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
  };
  const lineSpring = useSpring({
    ref: lineSpringRef,
    from: { progress: 1 },
    to: { progress: 0 },
    delay: 1000,
    config: { duration: 1000 },
  });

  const iconSpring = useSpring({
    ref: iconSpringRef,
    from: { opacity: 0 },
    to: { opacity: step === 0 || step === 1 ? 1 : 0 },
    delay: 1500,
    reset: true,
  });

  useChain(
    step === 0 || step === 1 ? [lineSpringRef, iconSpringRef] : [],
    [0, 0.55],
  );

  const productHighlights = {
    0: [
      {
        name: "Pants",
        Icon: PantsIcon,
        x: width - 40,
        y: 40,
        index: 551,
        fill: "#36B250",
      },
      {
        name: "Computers",
        Icon: ComputerIcon,
        x: 40,
        y: 40,
        index: 532,
        fill: "#52E2DE",
      },
      {
        name: "Shirts",
        x: 40,
        y: height - 40,
        Icon: ShirtIcon,
        index: 96,
        fill: "#36B250",
      },
      {
        name: "Phones",
        x: width - 40,
        y: height - 40,
        Icon: PhoneIcon,
        index: 442,
        fill: "#52E2DE",
      },
    ],
    1: [
      {
        name: "Pants",
        Icon: PantsIcon,
        x: width - 67.5,
        y: height - 160,
        index: 783,
      },
      {
        name: "Computers",
        Icon: ComputerIcon,
        x: 40,
        y: height - 40,
        index: 429,
      },
      {
        name: "Shirts",
        x: width - 25,
        y: height - 160,
        Icon: ShirtIcon,
        index: 583,
      },
      {
        name: "Phones",
        x: 40,
        y: height * 0.6,
        Icon: PhoneIcon,
        index: 454,
      },
    ],
  };

  const activeHighlights =
    productHighlights[step as keyof typeof productHighlights] || [];

  return (
    <>
      {(step === 0 || step === 1) &&
        activeHighlights.map((product: any) => {
          let x1 = product.x === 40 ? product.x + 25 : product.x - 15;
          let y1 = product.y;
          if (step === 1 && product.x !== 40) {
            y1 = product.y + 40;
            x1 = product.x;
          }
          const x2 = xScale(Number(layout[product.index].x));
          const y2 = yScale(Number(layout[product.index].y));
          const lineLength = getLineLength(x1, y1, x2, y2);

          return (
            <g key={product.name}>
              <animated.line
                x2={x1}
                y2={y1}
                x1={x2}
                y1={y2}
                stroke="#000"
                strokeWidth={1}
                strokeDasharray={lineLength}
                style={{
                  strokeDashoffset: lineSpring.progress.to(
                    (p) => p * lineLength,
                  ),
                }}
              />
              {step === 0 && (
                <>
                  <animated.circle
                    cx={xScale(Number(layout[product.index].x))}
                    cy={yScale(Number(layout[product.index].y))}
                    r={4}
                    fill={product.fill}
                    stroke="#727272"
                  />
                  <animated.circle
                    cx={xScale(Number(layout[product.index].x))}
                    cy={yScale(Number(layout[product.index].y))}
                    r={15}
                    stroke="none"
                    fill={product.fill}
                    fillOpacity={iconSpring.opacity.to((o) => o * 0.4)}
                  />
                </>
              )}
              <animated.g
                transform={`translate(${product.x - 24}, ${product.y - 24})`}
                style={{ opacity: iconSpring.opacity }}
              >
                <product.Icon height={48} width={48} />
                <text
                  x="24"
                  y="60"
                  textAnchor="middle"
                  fontSize="12"
                  fill="#000"
                >
                  {product.name}
                </text>
              </animated.g>
            </g>
          );
        })}
    </>
  );
};

interface AnimatedProductSpaceProps {
  step: number;
  width: number;
  height: number;
  data: any;
}

interface LayoutNode {
  product_code: string;
  x: string | number;
  y: string | number;
  cluster_name: string;
  cluster_name_short: string;
}

const AnimatedProductSpace: React.FC<AnimatedProductSpaceProps> = ({
  step,
  width,
  height,
  data,
}) => {
  const [hoveredNode, setHoveredNode] = useState<string | null>(null);

  const activeStep = steps[step] || steps[0]; // Provide fallback to first step

  const padding = 20;

  const xScale = scaleLinear({
    domain: [
      Math.min(...activeStep.layout.map((d) => d.x)),
      Math.max(...activeStep.layout.map((d) => d.x)),
    ],
    range: [8, width - 10],
  });

  const yScale = scaleLinear({
    domain: [
      Math.min(...activeStep.layout.map((d) => d.y)),
      Math.max(...activeStep.layout.map((d) => d.y)),
    ],
    range: [height - padding, 8],
  });

  const metaDataTree = useMemo(() => {
    if (!data) {
      return stratify()([{ id: "root" }]);
    }
    const metaData =
      Object.entries(data).find(([k, v]) => k.includes("metadata"))?.[1] || [];

    return stratify()
      .id((d: any) => d.productId)
      .parentId((d: any) => {
        if (d.productLevel === 1) return "root";
        return d?.parent?.productId || d?.topParent?.productId;
      })([{ productId: "root", parent: null }, ...(metaData as Array<any>)]);
  }, [data]);

  const productIdLookup = useMemo(
    () =>
      index(
        metaDataTree
          .descendants()
          .filter((d: any) => d?.data?.productLevel === 4 && d?.data?.code),
        (d: any) => d.data.code,
      ),
    [metaDataTree],
  ) as any;

  const countryProductYearLookup = useMemo(() => {
    if (!data?.countryProductYear) return new Map();
    return index(data.countryProductYear, (d: any) => d.productId);
  }, [data]) as any;

  const productYearLookup = useMemo(() => {
    if (!data?.productYear) return new Map();
    return index(data.productYear, (d: any) => d.productId);
  }, [data]) as any;

  const getExportValue = (productCode: string, radiusType: string) => {
    const productData = productIdLookup.get(productCode)?.data;
    if (!productData) return 0;

    if (radiusType === "globalTrade") {
      return productYearLookup.get(productData.productId)?.exportValue || 0;
    } else if (radiusType === "countryTrade") {
      return (
        countryProductYearLookup.get(productData.productId)?.exportValue || 0
      );
    }
    return 0;
  };

  const rScale = useMemo(() => {
    if (!data) return () => 4;

    const getScaleData = (radiusType: string) => {
      if (radiusType === "globalTrade") {
        return data.productYear || [];
      } else if (radiusType === "countryTrade") {
        return data.countryProductYear || [];
      }
      return [];
    };

    const scaleData = getScaleData(activeStep.radius as string);

    return scaleSqrt()
      .domain(extent(scaleData, (d: any) => d.exportValue) as [number, number])
      .range([2, 40]);
  }, [data, activeStep.radius]);

  const getRadius = (item: any, radiusType: string | number) => {
    if (typeof radiusType === "number") return radiusType;
    return rScale(getExportValue(item.product_code, radiusType));
  };

  const getFillColor = (item: any, step: number, fillType: string | null) => {
    if (step === 0) {
      return fillType;
    }

    if (fillType === "rca") {
      const productData = productIdLookup.get(item.product_code)?.data;
      if (!productData) return "#E9E9E9";

      const countryProductData = countryProductYearLookup.get(
        productData.productId,
      );
      const rca = countryProductData?.exportRca;

      if (!rca || rca < 1) {
        return "#E9E9E9";
      }

      return (
        clusterColorLookup.get(item.cluster_name_short)?.cluster_col ||
        "#E4E4E4"
      );
    }

    return (
      clusterColorLookup.get(item.cluster_name_short)?.cluster_col || "#E4E4E4"
    );
  };

  const transitions = useTransition(
    activeStep.layout.map((point, index) => ({
      ...point,
      key: index,
    })),
    {
      keys: (item) => item.key,
      initial: (item) => ({
        cx: xScale(item.x),
        cy: yScale(item.y),
        r: getRadius(item, activeStep.radius),
        opacity: 1,
        fill: getFillColor(item, step, activeStep.fill),
        stroke:
          step === 0
            ? activeStep.stroke
            : item.strokeColor || activeStep.stroke,
      }),
      from: (item) => ({
        cx: xScale(item.x),
        cy: yScale(item.y),
        r: 4,
        opacity: 1,
        fill: "#E4E4E4",
        stroke: activeStep.stroke,
      }),
      enter: (item) => ({
        cx: xScale(item.x),
        cy: yScale(item.y),
        r: getRadius(item, activeStep.radius),
        opacity: 1,
        fill: getFillColor(item, step, activeStep.fill),
        stroke:
          step === 0
            ? activeStep.stroke
            : item.strokeColor || activeStep.stroke,
      }),
      update: (item) => ({
        cx: xScale(item.x),
        cy: yScale(item.y),
        r: getRadius(item, activeStep.radius),
        fill: getFillColor(item, step, activeStep.fill),
        stroke:
          step === 0
            ? activeStep.stroke
            : item.strokeColor || activeStep.stroke,
      }),
      config: {
        tension: 170,
        friction: 100,
        duration: 1000,
      },
    },
  );

  const labelTransitions = useTransition(
    activeStep.clusterLabels ? Object.values(centroids) : [],
    {
      keys: (item: any) => item.FID,
      from: { opacity: 0 },
      enter: { opacity: 1, duration: 500 },
      leave: { opacity: 0, duration: 0, delay: 0, immediate: true },
      delay: 1200,
    },
  );

  const linkLookup = useMemo(
    () =>
      links.reduce((obj: any, datum: any) => {
        const sourceId = datum.source;
        const targetId = datum.target;
        if (obj[sourceId]) {
          obj[sourceId] = Array.from(new Set([...obj[sourceId], targetId]));
        } else {
          obj[sourceId] = [targetId];
        }
        if (obj[targetId]) {
          obj[targetId] = Array.from(new Set([...obj[targetId], sourceId]));
        } else {
          obj[targetId] = [sourceId];
        }
        return obj;
      }, {}),
    [],
  );

  const renderLinks = () => {
    if (!hoveredNode || !steps[step].links) return null;

    return linkLookup[hoveredNode]?.map((targetId: string) => {
      const source = (activeStep.layout as any[]).find(
        (d) => d && "product_code" in d && d.product_code === hoveredNode,
      );
      const target = (activeStep.layout as any[]).find(
        (d) => d && "product_code" in d && d.product_code === targetId,
      );
      if (!source || !target) return null;

      return (
        <line
          key={`link-${hoveredNode}-${targetId}`}
          x1={xScale(Number(source.x))}
          y1={yScale(Number(source.y))}
          x2={xScale(Number(target.x))}
          y2={yScale(Number(target.y))}
          stroke="#999"
          strokeWidth={1}
          opacity={0.5}
          style={{ pointerEvents: "none" }}
        />
      );
    });
  };

  const targetNode = activeStep.zoom
    ? (activeStep.layout as LayoutNode[]).find(
        (d) => d.product_code === activeStep.zoomTarget,
      )
    : null;
  const targetX = targetNode ? xScale(Number(targetNode.x)) : 0;
  const targetY = targetNode ? yScale(Number(targetNode.y)) : 0;

  const viewBoxSpring = useSpring({
    from: {
      x: 0,
      y: 0,
      width: width,
      height: height,
    },
    to: {
      x: activeStep.zoom ? targetX - width / (4 * 2) : 0,
      y: activeStep.zoom ? targetY - height / (4 * 2) : 0,
      width: activeStep.zoom ? width / 4 : width,
      height: activeStep.zoom ? height / 4 : height,
    },
    delay: 500,
    config: { duration: 3000 },
  });

  const pulseSpring = useSpring({
    from: { scale: 0, opacity: 0 },
    to: [
      { scale: 1, opacity: 0.65 },
      { scale: 0.25, opacity: 0 },
    ],
    loop: true,
    config: { duration: 1500 },
  });

  useEffect(() => {
    if (activeStep.zoom && activeStep.zoomTarget) {
      const targetNode = (activeStep.layout as LayoutNode[]).find(
        (d) => d.product_code === activeStep.zoomTarget,
      );

      if (targetNode) {
        setTimeout(() => {
          setHoveredNode(activeStep.zoomTarget);
        }, 500);
      }
    }
  }, [step, width, height, activeStep.zoom, activeStep.zoomTarget]);

  return (
    <div style={{ position: "relative" }}>
      <animated.svg
        width={width}
        height={height}
        viewBox={to(
          [
            viewBoxSpring.x,
            viewBoxSpring.y,
            viewBoxSpring.width,
            viewBoxSpring.height,
          ],
          (x, y, width, height) => `${x} ${y} ${width} ${height}`,
        )}
      >
        <g>
          {transitions((style: any, item) => (
            <Tippy
              key={`tip-${item.key}`}
              content={
                <div
                  style={{
                    backgroundColor: "black",
                    opacity: 0.75,
                    padding: "4px 8px",
                    color: "white",
                    borderRadius: "4px",
                  }}
                >
                  {productIdLookup.get(item.product_code)?.data?.nameShortEn ||
                    item.name}
                </div>
              }
              followCursor={true}
              zIndex={10001}
              trigger={
                step === 0 || !activeStep.interactive ? "manual" : "mouseenter"
              }
            >
              <animated.circle
                {...style}
                onMouseEnter={() =>
                  steps[step].links && activeStep.interactive
                    ? setHoveredNode(item.product_code)
                    : null
                }
                onMouseLeave={() =>
                  steps[step].links && activeStep.interactive
                    ? setHoveredNode(null)
                    : null
                }
                style={{
                  cursor:
                    steps[step].links && activeStep.interactive
                      ? "pointer"
                      : "default",
                }}
              />
            </Tippy>
          ))}
          {renderLinks()}
        </g>

        <ProductHighlights
          step={step}
          width={width}
          height={height}
          xScale={xScale}
          yScale={yScale}
          layout={activeStep.layout}
          key={step}
        />

        {labelTransitions((style, centroid: any) => (
          <animated.text
            key={centroid.FID}
            textAnchor="middle"
            x={xScale(centroid.X)}
            y={yScale(centroid.Y)}
            style={{
              pointerEvents: "none",
              opacity: style.opacity,
              fontWeight: 800,
            }}
            stroke="#FBFBFB"
            strokeWidth="5"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            paintOrder="stroke"
            fontSize={10}
          >
            {clusterColors[
              parseInt(centroid.FID)
            ]?.cluster_name_short.toUpperCase()}
          </animated.text>
        ))}

        {activeStep.zoom && hoveredNode && (
          <g
            transform={`translate(${xScale(
              Number(
                (activeStep.layout as LayoutNode[]).find(
                  (d) => d.product_code === hoveredNode,
                )?.x,
              ),
            )}, ${yScale(
              Number(
                (activeStep.layout as LayoutNode[]).find(
                  (d) => d.product_code === hoveredNode,
                )?.y,
              ),
            )})`}
          >
            <g style={{ transform: "translate(0, 5px)" }}>
              <animated.circle
                r={10}
                fill="white"
                style={{
                  opacity: pulseSpring.opacity,
                  transform: pulseSpring.scale.to((s) => `scale(${s})`),
                }}
              />
            </g>
            <image
              href={ClickIcon}
              x={-16}
              y={0}
              width={32}
              height={32}
              style={{ pointerEvents: "none" }}
            />
          </g>
        )}
      </animated.svg>
    </div>
  );
};

export default AnimatedProductSpace;
