import styled from "@emotion/styled";
import {
  sum,
  rollups,
  groups,
  stackOrderAscending,
  stackOffsetExpand,
  curveMonotoneX,
} from "d3";
import {
  getCategorySortingOrderFromProductClass,
  getRegionSortingOrder,
} from "../../../sharedUtilities/Utils";
import {
  MetadataMergeType,
  ProductClass,
  UIView,
  computeTotalSumByTradeFlow,
  getColorMap,
  getLocationQualifiers,
  mergeDataWithTopLevelParent,
} from "../../Utils";
import { MetadataFetchStatus } from "../../../sharedUtilities/useFetchMetadata";
import { getTradeDirectionSelector } from "../../Utils";
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { stack, area } from "d3";
import transformLocations from "./transformLocations";
import { LocationDetailLevel } from "../../Utils";
import { stackOrderNone, stackOffsetNone, scaleLinear } from "d3";
import StackRibbon from "./StackRibbon";
import transformProducts from "./transformProducts";
import { chartMargin as chartMarginBase } from "../../components/styles";
import XAxisYear from "../../components/XAxisYear";
import YAxis from "../../components/YAxis";
import YAxisGridlines from "../../components/YAxisGridlines";
import { determineClosestXValue } from "../../components/Tooltip";
import { ChartContainerSizeContext } from "../../components/GenericResizeContainer";
import YAxisScalingOptionsSelector from "./YAxisScalingOptionsSelector";
import {
  OverTimeLayoutOption,
  OverTimeOrderingOption,
  sortLocationsForRibbons,
  sortProductsForRibbons,
} from "./Utils";
import { yAxisScalingOptionsSelectorMargin } from "./index";
import GraphNotice, { GraphNoticeType } from "../../components/GraphNotice";
import { usePageQueryParams } from "../../defaultSettings";
import { isEqual } from "lodash";
import GraphLoading from "../../components/GraphLoading";
import { useDownload } from "../../DownloadContext";

const Root = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;

  & svg {
    margin: 0px;
    padding: 0px;
  }
`;

const BackgroundTooltipCatcher = styled.rect`
  fill: #ffffff;
  pointer-events: all;
`;

const FullWidthAndHeight = styled.rect`
  fill: none;
  stroke: none;
  pointer-events: none;
`;

const axisExtentMultiplierByFactor = 1.1; // Extends a scale and axis extent by 10% of the maximum value, for legibility

export enum YAxisScalingOption {
  Current = "Current",
  Constant = `Constant (2023 USD)`,
  PerCapita = "Per Capita",
  PerCapitaConstant = `Per Capita Constant (2023 USD)`,
}

export enum TradeValueScaledByYAxisOption {
  Current = "current",
  Constant = "constant",
  PerCapita = "perCapita",
  PerCapitaConstant = "perCapitaConstant",
}

const determineScaledTradeValueSelector = (input: YAxisScalingOption) => {
  let scaledTradeValueSelector: TradeValueScaledByYAxisOption;

  if (input === YAxisScalingOption.Current) {
    scaledTradeValueSelector = TradeValueScaledByYAxisOption.Current;
  } else if (input === YAxisScalingOption.Constant) {
    scaledTradeValueSelector = TradeValueScaledByYAxisOption.Constant;
  } else if (input === YAxisScalingOption.PerCapita) {
    scaledTradeValueSelector = TradeValueScaledByYAxisOption.PerCapita;
  } else if (input === YAxisScalingOption.PerCapitaConstant) {
    scaledTradeValueSelector = TradeValueScaledByYAxisOption.PerCapitaConstant;
  } else {
    // This will never run
    scaledTradeValueSelector = TradeValueScaledByYAxisOption.Current;
  }

  return scaledTradeValueSelector;
};

const Chart = memo(
  ({
    inputData,
    yearRangeForProductClass,
    hiddenCategories,
    handleTooltip,
    clearTooltip,
    setTotalValue,
    setXAxisTooltipValue,
    highlightedItem,
    setFindInVizOptions,
    tradeDirection,
    tradeFlow,
    locationMetadataFetch,
    hs92MetadataFetch,
    hs12MetadataFetch,
    sitcMetadataFetch,
    locationYearMetadataFetch,
    deflatorsMetadataFetch,
    queryVariables,
    findInVizOptions,
  }: any) => {
    const {
      view,
      productClass,
      productLevel,
      exporter,
      importer,
      yearMin,
      yearMax,
      yAxis: currentYAxisScalingOption,
    } = queryVariables;
    const [
      {
        layout: currentOverTimeLayout,
        ordering: currentOverTimeOrdering,
        locationLevel: currentLocationDetailLevel,
      },
    ] = usePageQueryParams();
    const [yAxisExtentDomain, setYAxisExtentDomain] = useState<
      { min: number; max: number } | undefined
    >(undefined);
    const { svgRef, dataRef } = useDownload();
    const setYAxisDomain = useCallback(
      (domainToSet: { min: number; max: number }) => {
        if (
          !yAxisExtentDomain ||
          (yAxisExtentDomain &&
            (domainToSet.min != yAxisExtentDomain.min ||
              domainToSet.max != yAxisExtentDomain.max))
        ) {
          setYAxisExtentDomain({ min: domainToSet.min, max: domainToSet.max });
        }
      },
      [yAxisExtentDomain],
    );

    const { chartWidth, chartHeight } = useContext(ChartContainerSizeContext);

    const chartMargin = useMemo(
      () => ({
        ...chartMarginBase,
        left: yAxisScalingOptionsSelectorMargin,
      }),
      [],
    );

    const tradeDirectionSelector: string = useMemo(
      () =>
        getTradeDirectionSelector({
          tradeDirection,
        }),
      [tradeDirection],
    );

    const useColorMap: any = useMemo(
      () => getColorMap({ view, productClass }),
      [view, productClass],
    );

    const {
      metadataStatus: locationMetadataStatus,
      metadata: locationMetadata,
      error: locationError,
    } = locationMetadataFetch;
    const {
      metadataStatus: productsHs92MetadataStatus,
      metadata: productsHs92Metadata,
      error: productsHs92Error,
    } = hs92MetadataFetch;
    const {
      metadataStatus: productsHs12MetadataStatus,
      metadata: productsHs12Metadata,
      error: productsHs12Error,
    } = hs12MetadataFetch;
    const {
      metadataStatus: productsSitcMetadataStatus,
      metadata: productsSitcMetadata,
      error: productsSitcError,
    } = sitcMetadataFetch;
    const {
      metadataStatus: locationYearMetadataStatus,
      metadata: locationYearMetadata,
      error: locationYearError,
    } = locationYearMetadataFetch;
    const {
      metadataStatus: deflatorsMetadataStatus,
      metadata: deflatorsMetadata,
      error: deflatorsError,
    } = deflatorsMetadataFetch;
    const {
      seriesData,
      totalSum,
      stackKeysSet,
      noDataToDisplay,
      scaledTradeValueSelector,
    } = useMemo(() => {
      let seriesData: any = undefined;
      let totalSum: number | undefined = undefined;
      let stackKeysSet: any;
      let findInVizOptions: any[] | undefined = undefined;
      let noDataToDisplay: boolean = false;

      const scaledTradeValueSelector: TradeValueScaledByYAxisOption =
        determineScaledTradeValueSelector(currentYAxisScalingOption);

      let stackOrderFunction;
      if (currentOverTimeOrdering === OverTimeOrderingOption.Community) {
        stackOrderFunction = stackOrderNone;
      } else if (currentOverTimeOrdering === OverTimeOrderingOption.Totals) {
        stackOrderFunction = stackOrderAscending;
      } else {
        stackOrderFunction = stackOrderNone;
      }

      let stackOffsetFunction;
      if (currentOverTimeLayout === OverTimeLayoutOption.Value) {
        stackOffsetFunction = stackOffsetNone;
      } else if (currentOverTimeLayout === OverTimeLayoutOption.Share) {
        stackOffsetFunction = stackOffsetExpand;
      } else {
        stackOffsetFunction = stackOffsetNone;
      }

      let { data } = inputData;

      if (view === UIView.Products) {
        let products: any[] | undefined;
        let deflators: any[] | undefined;
        let locationYearPopulations: any[] | undefined;
        let transformedData;

        if (
          productClass === ProductClass.HS92Products &&
          productsHs92MetadataStatus === MetadataFetchStatus.Success
        ) {
          let { section, twoDigit, fourDigit, sixDigit } = productsHs92Metadata;
          products = [...section, ...twoDigit, ...fourDigit, ...sixDigit];
        } else if (
          productClass === ProductClass.HS12Products &&
          productsHs12MetadataStatus === MetadataFetchStatus.Success
        ) {
          let { section, twoDigit, fourDigit, sixDigit } = productsHs12Metadata;
          products = [...section, ...twoDigit, ...fourDigit, ...sixDigit];
        } else if (
          productClass === ProductClass.SITCProducts &&
          productsSitcMetadataStatus === MetadataFetchStatus.Success
        ) {
          let { section, twoDigit, fourDigit } = productsSitcMetadata;
          products = [...section, ...twoDigit, ...fourDigit];
        } else {
          products = undefined;
        }

        if (deflatorsMetadataStatus === MetadataFetchStatus.Success) {
          deflators = deflatorsMetadata;
        } else {
          deflators = undefined;
        }

        if (locationYearMetadataStatus === MetadataFetchStatus.Success) {
          locationYearPopulations = locationYearMetadata;
        } else {
          locationYearPopulations = undefined;
        }

        if (products !== undefined) {
          let dataWithTopLevelProductId = mergeDataWithTopLevelParent({
            data,
            mergeType: MetadataMergeType.Sector,
            productsMetadata: products,
          });

          let dataSortedByCategory;

          let sortingOrder: string[] = getCategorySortingOrderFromProductClass({
            productClass: productClass as any,
          });
          if (sortingOrder) {
            dataSortedByCategory = [...dataWithTopLevelProductId].sort(
              (productA: any, productB: any) =>
                sortProductsForRibbons({ productA, productB, sortingOrder }),
            );
          } else {
            dataSortedByCategory = dataWithTopLevelProductId;
          }

          let filteredData = dataSortedByCategory.filter((d: any) => {
            const topLevelParent = d.topLevelParent;
            if (hiddenCategories.includes(topLevelParent)) {
              return false;
            }

            return true;
          });

          let filteredDataWithinYearLimits = filteredData.filter(
            (d: any) => d.year >= yearMin && d.year <= yearMax,
          );

          totalSum =
            filteredDataWithinYearLimits.length > 0
              ? computeTotalSumByTradeFlow({
                  data: filteredDataWithinYearLimits,
                  tradeFlow,
                  tradeDirection,
                })
              : 0;

          transformedData = transformProducts({
            yAxisScalingOption: currentYAxisScalingOption,
            productLevel,
            untransformedData: filteredData,
            products,
            deflators,
            locationYearPopulations,
            yearRangeForProductClass,
            tradeFlow,
          });

          if (transformedData) {
            const filteredDataWithinYearLimits = transformedData.filter(
              (d: any) => d.year >= yearMin && d.year <= yearMax,
            );

            // Compute the max value for the y-scale
            const sumTradeValueByYear = rollups(
              filteredDataWithinYearLimits,
              (d: any) =>
                sum(
                  d,
                  (v: any) =>
                    v[scaledTradeValueSelector][tradeDirectionSelector],
                ),
              (d: any) => d.year,
            ).map((d: any) => d[1]);
            let maxTradeValue = Math.max(...sumTradeValueByYear);

            if (maxTradeValue) {
              setYAxisDomain({ min: 0, max: maxTradeValue });
            }

            let stackProductInputData: any[] = [];
            groups(
              filteredDataWithinYearLimits,
              ({ year }) => year,
              ({ productId }) => productId,
            ).map((yearGrouping) => {
              const year = yearGrouping[0];
              const products = yearGrouping[1];

              let yearProductDatum: any = {
                year,
              };

              products.forEach(([productId, [datum]]: any) => {
                let { exportValue, importValue } =
                  datum[scaledTradeValueSelector];
                yearProductDatum[productId] = {};
                yearProductDatum[productId][scaledTradeValueSelector] = {
                  exportValue,
                  importValue,
                };
              });

              stackProductInputData.push(yearProductDatum);
            });

            // Set stack input variables
            stackKeysSet = new Set(
              filteredDataWithinYearLimits.map((d: any) => d.productId),
            );

            // Order the data by year
            const sortedStackInputData = stackProductInputData
              .map((d) => d)
              .sort((a: any, b: any) => a.year - b.year);

            const stackGenerator = stack()
              .keys([...stackKeysSet])
              .value((d: any, key: any) =>
                d.hasOwnProperty(key)
                  ? d[key][scaledTradeValueSelector][tradeDirectionSelector]
                  : 0,
              )
              .order(stackOrderFunction)
              .offset(stackOffsetFunction);

            const series = stackGenerator(sortedStackInputData);

            const seriesWithMetadata = series.map((d: any) => {
              let productId = d.key;
              let nameEn;
              let findMatchingMetadata = products!.find(
                (product: any) => product.productId === productId,
              );
              let topLevelParent: string | undefined = undefined;
              let topLevelParentName: string | undefined = undefined;
              let productCode: string | undefined = undefined;
              if (findMatchingMetadata) {
                topLevelParent = findMatchingMetadata.topParent.productId;
                let topLevelParentMetadata = products!.find(
                  (product: any) => product.productId === topLevelParent,
                );
                topLevelParentName = topLevelParentMetadata?.nameShortEn;
                nameEn = findMatchingMetadata.nameShortEn;
                productCode = findMatchingMetadata.code;
              }

              let copy = {
                key: productId,
                topLevelParent,
                topLevelParentName,
                nameEn,
                productCode,
                seriesData: [...d],
              };
              return copy;
            });

            seriesData = seriesWithMetadata;

            findInVizOptions = seriesWithMetadata;
          } else {
            noDataToDisplay = true;
          }
        }
      } else if (view === UIView.Markets) {
        let locations: any | undefined;
        let transformedData;
        let deflators: any[] | undefined;

        if (locationMetadataStatus === MetadataFetchStatus.Success) {
          locations = locationMetadata;
        } else {
          locations = undefined;
        }

        if (deflatorsMetadataStatus === MetadataFetchStatus.Success) {
          deflators = deflatorsMetadata;
        } else {
          deflators = undefined;
        }

        if (locations) {
          const { regions, subregions, countries } = locations;

          let dataWithTopLevelParentId = mergeDataWithTopLevelParent({
            data,
            mergeType: MetadataMergeType.Region,
            regionsMetadata: regions,
          });

          let dataSortedByCategory;
          let sortingOrder: string[] = getRegionSortingOrder();
          if (sortingOrder) {
            dataSortedByCategory = [...dataWithTopLevelParentId].sort(
              (locationA: any, locationB: any) =>
                sortLocationsForRibbons({ locationA, locationB, sortingOrder }),
            );
          } else {
            dataSortedByCategory = dataWithTopLevelParentId;
          }

          const {
            exporterIsWorld,
            exporterIsUndefined,
            importerIsWorld,
            importerIsUndefined,
          } = getLocationQualifiers({ exporter, importer });
          let referenceLocation;
          if (!exporterIsUndefined && !importerIsUndefined) {
            if (!exporterIsWorld && importerIsWorld) {
              referenceLocation = exporter;
            } else if (!importerIsWorld && exporterIsWorld) {
              referenceLocation = importer;
            } else if (exporterIsWorld && importerIsWorld) {
              referenceLocation = exporter;
            }
          } else if (
            (exporterIsWorld && importerIsUndefined) ||
            (importerIsWorld && exporterIsUndefined)
          ) {
            if (exporterIsWorld) {
              referenceLocation = exporter;
            } else if (importerIsWorld) {
              referenceLocation = importer;
            }
          } else {
            // This will never run
            referenceLocation = exporter;
          }

          let filteredData = dataSortedByCategory.filter((d: any) => {
            const topLevelParent = d.topLevelParent;

            if (hiddenCategories.includes(topLevelParent)) {
              return false;
            }

            return true;
          });

          let filteredDataWithinYearLimits = filteredData.filter(
            (d: any) => d.year >= yearMin && d.year <= yearMax,
          );

          totalSum =
            filteredDataWithinYearLimits.length > 0
              ? computeTotalSumByTradeFlow({
                  data: filteredDataWithinYearLimits,
                  tradeFlow,
                  tradeDirection,
                })
              : 0;

          transformedData = transformLocations({
            view,
            untransformedData: filteredData,
            location: referenceLocation,
            currentLocationDetailLevel,
            yAxisScalingOption: currentYAxisScalingOption,
            regions,
            subregions,
            countries,
            deflators,
            yearRangeForProductClass,
            tradeFlow,
          });

          if (transformedData) {
            const filteredDataWithinYearLimits = transformedData.filter(
              (d: any) => d.year >= yearMin && d.year <= yearMax,
            );

            // Compute the max value for the y-scale
            const sumTradeValueByYear = rollups(
              filteredDataWithinYearLimits,
              (d: any) =>
                sum(
                  d,
                  (v: any) =>
                    v[scaledTradeValueSelector][tradeDirectionSelector],
                ),
              (d: any) => d.year,
            ).map((d: any) => d[1]);
            let maxTradeValue = Math.max(...sumTradeValueByYear);

            if (maxTradeValue) {
              setYAxisDomain({ min: 0, max: maxTradeValue });
            }

            let stackMarketInputData: any[] = [];
            groups(
              filteredDataWithinYearLimits,
              ({ year }) => year,
              ({ partnerId }) => partnerId,
            ).map((yearGrouping) => {
              const year = yearGrouping[0];
              const partners = yearGrouping[1];

              let yearPartnerDatum: any = {
                year,
              };

              partners.forEach(([partnerId, [datum]]: any) => {
                let { exportValue, importValue } =
                  datum[scaledTradeValueSelector];
                yearPartnerDatum[partnerId] = {};
                yearPartnerDatum[partnerId][scaledTradeValueSelector] = {
                  exportValue,
                  importValue,
                };
                // yearPartnerDatum[partnerId] = {exportValue: datum.exportValue, importValue: datum.importValue};
              });

              stackMarketInputData.push(yearPartnerDatum);
            });

            // Order the data by year
            const sortedStackInputData = stackMarketInputData
              .map((d) => d)
              .sort((a: any, b: any) => a.year - b.year);

            stackKeysSet = new Set(
              filteredDataWithinYearLimits.map((d: any) => d.partnerId),
            );

            const stackGenerator = stack()
              .keys([...stackKeysSet])
              .value((d: any, key: any) =>
                d.hasOwnProperty(key)
                  ? d[key][scaledTradeValueSelector][tradeDirectionSelector]
                  : 0,
              )
              .order(stackOrderFunction)
              .offset(stackOffsetFunction);

            const series = stackGenerator(sortedStackInputData);

            seriesData = series;

            const seriesWithMetadata = series.map((d: any) => {
              let partnerId = d.key;
              let topLevelParent;
              let nameEn;
              let findMatchingMetadataForPartner = [
                ...regions,
                ...subregions,
                ...countries,
              ].find(
                (location: any) =>
                  (location.countryId && location.countryId === partnerId) ||
                  (location.groupId && location.groupId === partnerId),
              );
              let countryIso3Code = undefined;
              let productCode = undefined;
              let topLevelParentName = undefined;
              if (findMatchingMetadataForPartner) {
                let { nameShortEn, groupName, iso3Code } =
                  findMatchingMetadataForPartner;
                if (nameShortEn) {
                  nameEn = nameShortEn;
                } else if (groupName) {
                  nameEn = groupName;
                }

                if (iso3Code) {
                  countryIso3Code = iso3Code;
                }
              }
              if (currentLocationDetailLevel === LocationDetailLevel.region) {
                // If location detail level is `Region`, then each partner ID
                // that identifies each ribbon is already a region, so use
                // the partner ID as the top level region ID
                topLevelParent = partnerId;
              } else if (
                currentLocationDetailLevel === LocationDetailLevel.subregion
              ) {
                let findMatchingMetadata = subregions.find(
                  (subregion: any) => subregion.groupId === partnerId,
                );
                if (findMatchingMetadata) {
                  topLevelParent = findMatchingMetadata.parentId;
                }
              } else {
                let findMatchingMetadata = regions.find((region: any) =>
                  region.members.includes(partnerId),
                );
                if (findMatchingMetadata) {
                  topLevelParent = findMatchingMetadata.groupId;
                }
              }

              let copy = {
                key: partnerId,
                topLevelParent,
                nameEn,
                countryIso3Code,
                productCode,
                topLevelParentName,
                seriesData: [...d],
              };
              return copy;
            });

            seriesData = seriesWithMetadata;
          } else {
            noDataToDisplay = true;
          }
        }
      }
      return {
        seriesData,
        totalSum,
        stackKeysSet,
        noDataToDisplay,
        scaledTradeValueSelector,
      };
    }, [
      currentYAxisScalingOption,
      currentOverTimeOrdering,
      currentOverTimeLayout,
      inputData,
      view,
      productClass,
      productsHs92MetadataStatus,
      productsHs12MetadataStatus,
      productsSitcMetadataStatus,
      deflatorsMetadataStatus,
      locationYearMetadataStatus,
      productsHs92Metadata,
      productsHs12Metadata,
      productsSitcMetadata,
      deflatorsMetadata,
      locationYearMetadata,
      tradeFlow,
      tradeDirection,
      productLevel,
      yearRangeForProductClass,
      hiddenCategories,
      yearMin,
      yearMax,
      tradeDirectionSelector,
      setYAxisDomain,
      locationMetadataStatus,
      locationMetadata,
      exporter,
      importer,
      currentLocationDetailLevel,
    ]);

    // Update the total value for display
    totalSum && setTotalValue(totalSum);

    useEffect(() => {
      const valueKey =
        tradeDirectionSelector === "exportValue"
          ? "gross exports"
          : "gross imports";
      const scaledTradeValueSelector = determineScaledTradeValueSelector(
        currentYAxisScalingOption,
      );
      if (seriesData && seriesData?.length) {
        const downloadData = [];
        seriesData.forEach((series) => {
          const {
            nameEn,
            key,
            seriesData = [],
            topLevelParentName,
            productCode,
          } = series;

          seriesData.forEach((d) => {
            const actualValue = d[1] - d[0];

            const downloadDatum = {
              Name: nameEn,
              Code: key,
              year: d.data.year,
              [`${scaledTradeValueSelector} ${valueKey}`]: actualValue,
            };
            if (topLevelParentName) {
              downloadDatum.Sector = topLevelParentName;
            }
            if (productCode) {
              downloadDatum.Code = productCode;
            }
            downloadData.push(downloadDatum);
          });
        });
        dataRef.current = downloadData;
      }
    }, [
      seriesData,
      dataRef,
      tradeDirectionSelector,
      currentYAxisScalingOption,
    ]);
    /*
    In the scale generator below for xScale, note that we are using d3.scaleLinear().
    Another candidate scale generator that would be more semantically aligned with the
    variable represented on the x-axis is d3.scaleTime(). However, the x-axis variable
    is encoded as discrete years, and so to use d3.scaleTime(), all of the `year` values
    would need to be coerced into JS Date() objects. This would introduce a number of
    unnecessary code acrobatics, as well as the need to manage the oddities of JS Date()
    representations, e.g., representations across time zones. Thus, the choice here is
    to use d3.scaleLinear() and encode `year` as a continuous quantitative variable.
    */
    const xScale = useMemo(
      () =>
        scaleLinear()
          .domain([yearMin, yearMax])
          .range([chartMargin.left, chartWidth - chartMargin.right]),
      [yearMin, yearMax, chartMargin.left, chartMargin.right, chartWidth],
    );

    const yScale = useMemo(() => {
      const scale = scaleLinear().range([
        chartHeight - chartMargin.bottom,
        chartMargin.top,
      ]);

      if (currentOverTimeLayout === OverTimeLayoutOption.Share) {
        scale.domain([0, 1]);
      } else {
        if (yAxisExtentDomain) {
          scale
            .domain([
              yAxisExtentDomain.min,
              yAxisExtentDomain.max * axisExtentMultiplierByFactor,
            ])
            .nice();
        }
      }

      return scale;
    }, [
      chartHeight,
      chartMargin.bottom,
      chartMargin.top,
      currentOverTimeLayout,
      yAxisExtentDomain,
    ]);

    const stackAreaGenerator = useCallback(({ x, y }: any) => {
      return area()
        .x((d: any) => x(d.year))
        .y0((d: any) => y(d.tradeValueMin))
        .y1((d: any) => y(d.tradeValueMax))
        .defined(
          (d: any) => d.year && d.tradeValueMin >= 0 && d.tradeValueMax >= 0,
        )
        .curve(curveMonotoneX);
    }, []);

    const stackArea = useMemo(
      () => stackAreaGenerator({ x: xScale, y: yScale }),
      [stackAreaGenerator, xScale, yScale],
    );

    useEffect(() => {
      let cleanedFindInVizOptions;
      if (seriesData) {
        if (view === UIView.Markets) {
          cleanedFindInVizOptions = seriesData
            .filter((location: any) => location.key && location.nameEn)
            .map((location: any) => {
              let { key: locationId, nameEn, countryIso3Code } = location;

              return {
                id: locationId,
                label: countryIso3Code
                  ? `${nameEn} (${countryIso3Code})`
                  : nameEn,
              };
            });
        } else if (view === UIView.Products) {
          cleanedFindInVizOptions = seriesData
            .filter((product: any) => product.key && product.nameEn)
            .map((product: any) => {
              let { key: productId, nameEn, productCode } = product;

              return {
                id: productId,
                label: productCode
                  ? `${nameEn} (${productCode} ${productClass})`
                  : nameEn,
              };
            });
        }
      } else {
        cleanedFindInVizOptions = [];
      }
      if (
        cleanedFindInVizOptions &&
        !isEqual(cleanedFindInVizOptions, findInVizOptions)
      ) {
        setFindInVizOptions(cleanedFindInVizOptions);
      }
    }, [
      inputData,
      locationMetadata,
      productsHs92Metadata,
      productsHs12Metadata,
      productsSitcMetadata,
      locationYearMetadata,
      deflatorsMetadata,
      findInVizOptions,
      view,
      seriesData,
      productClass,
      setFindInVizOptions,
    ]);

    const handleChartMouseMove = useCallback(
      (e: React.MouseEvent<SVGElement>) => {
        determineClosestXValue({
          e,
          xScale,
          containerSize: { width: chartWidth, height: chartHeight },
          chartMargin,
          setXAxisTooltipValue,
        });
      },
      [xScale, chartWidth, chartHeight, chartMargin, setXAxisTooltipValue],
    );

    const mappedSeriesData = useMemo(() => {
      if (!seriesData) return [];

      return seriesData.map((series: any) => {
        let seriesKey = series.key;
        let useId: string | undefined = series.topLevelParent;
        let nameEn: string | undefined = series.nameEn;
        let topLevelParentName: string | undefined = series.topLevelParentName;

        let metadataProperties: any;
        if (view === UIView.Markets) {
          if (currentLocationDetailLevel === LocationDetailLevel.country) {
            metadataProperties = {
              countryId: seriesKey,
              nameEn,
              topLevelParent: series.topLevelParent,
            };
          } else {
            metadataProperties = {
              groupId: seriesKey,
              nameEn,
              groupName: nameEn,
              topLevelParent: series.topLevelParent,
            };
          }
        } else if (view === UIView.Products) {
          metadataProperties = {
            productId: seriesKey,
            nameEn,
            topLevelParent: series.topLevelParent,
            topLevelParentName,
            productLevel,
          };
        }

        const mappedSeries = series.seriesData
          .map((d: any) => {
            let year = d.data.year;
            if (seriesKey in d.data) {
              let tradeDataForSeries = d.data[seriesKey];
              let { exportValue, importValue } =
                tradeDataForSeries[scaledTradeValueSelector];
              let shareForYear;
              if (currentOverTimeLayout === OverTimeLayoutOption.Share) {
                /* When the stack offset is set to stackOffsetExpand, the share 
                  of the product for the given year is just the difference between the 
                                   min and max coordinates for the ribbon in the year, since all ribbons 
                                   are expanded to a baseline of 0% and maximum of 100%. 

                  Important: Note that since this value is being computed using the stack generator, 
                  we don't compute a separate share for exports or imports -- rather, 
                  the imputed share value here is based on which trade direction 
                  is being used to generate the ribbons themselves.

                  See: https://d3js.org/d3-shape/stack#stackOffsetExpand
                  */
                shareForYear = d[1] - d[0];
              } else {
                shareForYear = undefined;
              }
              return {
                year,
                tradeValueMin: d[0],
                tradeValueMax: d[1],
                pointMin: yScale(d[0]),
                pointMax: yScale(d[1]),
                properties: {
                  exportValue,
                  importValue,
                  shareForYear,
                  ...metadataProperties,
                },
              };
            } else {
              return undefined;
            }
          })
          .filter((d: any) => d !== undefined);

        return {
          seriesKey,
          mappedSeries,
          useId,
        };
      });
    }, [
      seriesData,
      view,
      currentLocationDetailLevel,
      productLevel,
      scaledTradeValueSelector,
      currentOverTimeLayout,
      yScale,
    ]);
    const containerSize = useMemo(
      () => ({ width: chartWidth, height: chartHeight }),
      [chartWidth, chartHeight],
    );
    const contentToRender = useMemo(() => {
      // Check for no data conditions
      const hasNoData =
        (seriesData === undefined && noDataToDisplay === true) ||
        seriesData?.length === 0 ||
        totalSum === 0;

      if (hasNoData) {
        return <GraphNotice graphNoticeType={GraphNoticeType.NoData} />;
      }

      if (!seriesData) {
        return <GraphLoading />;
      }

      return (
        <>
          <YAxisScalingOptionsSelector
            view={view}
            tradeDirection={tradeDirection}
            tradeFlow={tradeFlow}
            yAxisScalingOptionsSelectorMargin={
              yAxisScalingOptionsSelectorMargin
            }
          />
          <svg
            style={{ backgroundColor: "white" }}
            width={chartWidth}
            height={chartHeight}
            onMouseMoveCapture={handleChartMouseMove}
            ref={svgRef}
          >
            <BackgroundTooltipCatcher
              x={0}
              y={0}
              width={chartWidth}
              height={chartHeight}
              onMouseMove={clearTooltip}
              fill="none"
            />

            <YAxisGridlines
              scale={yScale}
              containerSize={containerSize}
              chartMargin={chartMargin}
            />
            <g>
              <FullWidthAndHeight
                x={0}
                y={0}
                width={chartWidth}
                height={chartHeight}
                fill="none"
              />
              {mappedSeriesData
                .reverse()
                .map(({ seriesKey, mappedSeries, useId }) => (
                  <StackRibbon
                    key={seriesKey}
                    series={mappedSeries}
                    seriesKey={seriesKey}
                    areaGenerator={stackArea}
                    useId={useId}
                    colorMap={useColorMap}
                    handleTooltip={handleTooltip}
                    highlightedItem={highlightedItem}
                  />
                ))}
            </g>

            <XAxisYear
              scale={xScale}
              containerSize={{ width: chartWidth, height: chartHeight }}
            />
            <YAxis
              scale={yScale}
              overTimeLayout={currentOverTimeLayout}
              chartMargin={chartMargin}
            />
          </svg>
        </>
      );
    }, [
      seriesData,
      noDataToDisplay,
      totalSum,
      view,
      tradeDirection,
      tradeFlow,
      yAxisScalingOptionsSelectorMargin,
      chartWidth,
      chartHeight,
      handleChartMouseMove,
      svgRef,
      clearTooltip,
      yScale,
      containerSize,
      chartMargin,
      mappedSeriesData,
      stackArea,
      useColorMap,
      handleTooltip,
      highlightedItem,
      xScale,
      currentOverTimeLayout,
    ]);

    return <Root>{contentToRender}</Root>;
  },
);

const arePropsEqual = (_, { isLoading }) => {
  return isLoading;
};

export default memo(Chart, arePropsEqual);
