import React, { SetStateAction, Dispatch } from "react";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import Label, { CategoryDatum } from "./LegendLabel";
import { breakPoints } from "../../components/styling/GlobalGrid";
import { backgroundMedium, baseColor } from "./styles";
import raw from "raw.macro";
import { googleAnalyticsCustomEvent } from "../../sharedUtilities/googleAnalyticsEvent";

const ReloadImgSrc = raw("../../assets/images/ui/reload.svg");

const RootBase = styled.div`
  grid-row: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  @media ${breakPoints.small} {
    grid-row: 4;
  }
`;

const StandardRoot = styled(RootBase)`
  grid-column: 1;
  justify-content: center;
`;

const FullWidthRoot = styled(RootBase)`
  grid-column: 1 / -1;
`;

const StandardContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  align-items: center;
  box-sizing: border-box;
  padding: 0.875rem 0;

  @media ${breakPoints.small} {
    margin-bottom: 3rem;
  }
`;

const FullWidthContent = styled(StandardContent)`
  padding: 0.875rem 0;

  @media ${breakPoints.small} {
    padding-right: 0;
  }
`;

const fadeAndSlideIn = keyframes`
  0% {
    transform: translate(300%, 0);
    opacity: 0;
  }

  70% {
    transform: translate(90%, 0);
    opacity: 0.7;
  }

  100% {
    transform: translate(110%, 0);
    opacity: 1;
  }
`;

const fadeAndSlideDown = keyframes`
  0% {
    transform: translate(0, -50%);
    opacity: 0;
  }

  70% {
    transform: translate(0, 20%);
    opacity: 0.7;
  }

  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
`;

const ResetLabelsButtonContainer = styled.div`
  position: absolute;
  pointer-events: none;
  right: 0;

  @media (max-width: 1000px) {
    left: 0;
    bottom: -0.5rem;
  }
`;

const ResetLabelsButton = styled.button`
  pointer-events: all;
  animation: ${fadeAndSlideIn} 0.2s ease-in-out 1 forwards;
  text-transform: uppercase;
  color: ${baseColor};
  background-color: ${backgroundMedium};
  font-size: 0.5rem;
  font-weight: 600;
  outline-color: ${backgroundMedium};
  transition: outline 0.1s ease-in-out;
  padding: 0.3rem 0.5rem;
  display: inline-flex;
  align-items: center;
  width: 6rem;
  cursor: pointer;
  border: none;
  text-align: left;

  &:hover,
  &:focus {
    outline: solid 2px ${backgroundMedium};
  }

  @media (max-width: 1000px) {
    animation: ${fadeAndSlideDown} 0.2s ease-in-out 1 forwards;
    width: auto;
    left: 0;
    bottom: 0;
  }
`;

const ReloadIcon = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;

  svg {
    width: 100%;
    height: 100%;

    path {
      fill: ${baseColor};
    }
  }

  @media (max-width: 1000px) {
    width: 0.7rem;
    height: 0.7rem;
  }
`;

interface BaseProps {
  categories: CategoryDatum[];
  fullWidth?: boolean;
}

type Props = BaseProps &
  (
    | {
        allowToggle: true;
        hiddenCategories: string[];
        setHiddenCategories: Dispatch<SetStateAction<string[]>>;
        resetText: string;
      }
    | {
        allowToggle: false;
      }
  );

const CategoryLabels = (props: Props) => {
  const { categories, fullWidth } = props;
  let output: React.ReactElement<any>;
  if (props.allowToggle) {
    const { hiddenCategories, setHiddenCategories, resetText } = props;

    const toggleCategory = (valueToToggle: string) => {
      let newHiddenCategories = [...hiddenCategories];
      let valueIsHidden = newHiddenCategories.find(
        (category) => category === valueToToggle,
      );
      if (valueIsHidden) {
        let hiddenIndex = newHiddenCategories.indexOf(valueToToggle);
        newHiddenCategories.splice(hiddenIndex, 1);
      } else if (!valueIsHidden) {
        newHiddenCategories.push(valueToToggle);
      }
      if (newHiddenCategories.length === categories.length) {
        newHiddenCategories = [];
      }
      setHiddenCategories(newHiddenCategories);
    };

    const isolateCategory = (valueToIsolate: string) => {
      if (
        hiddenCategories.length == categories.length - 1 &&
        !hiddenCategories.includes(valueToIsolate)
      ) {
        // If click on "Keep Only" again when category is already isolated,
        // reset hidden categories to display all categories
        resetCategories();
      } else {
        let newHiddenCategories = categories
          .filter((category) => category.id !== valueToIsolate)
          .map((category) => category.id);
        setHiddenCategories(newHiddenCategories);
      }
    };

    const resetCategories = () => {
      setHiddenCategories([]);
    };

    const labels = categories.map((category) => {
      const isHidden = !!hiddenCategories.find((id) => id === category.id);
      const isIsolated =
        hiddenCategories.length === categories.length - 1 && !isHidden;
      return (
        <Label
          key={"sector-label-" + category.id}
          category={category}
          toggleCategory={() => toggleCategory(category.id)}
          isolateCategory={() => isolateCategory(category.id)}
          isHidden={isHidden}
          isIsolated={isIsolated}
        />
      );
    });

    const resetButton = hiddenCategories.length ? (
      <ResetLabelsButtonContainer>
        <ResetLabelsButton onClick={resetCategories}>
          <ReloadIcon dangerouslySetInnerHTML={{ __html: ReloadImgSrc }} />{" "}
          {resetText}
        </ResetLabelsButton>
      </ResetLabelsButtonContainer>
    ) : null;

    output = (
      <>
        {labels}
        {resetButton}
      </>
    );
  } else {
    const labels = categories.map((category) => (
      <Label
        key={"sector-label-" + category.id}
        category={category}
        isHidden={false}
        isIsolated={false}
      />
    ));

    output = <>{labels}</>;
  }

  const Root = fullWidth ? FullWidthRoot : StandardRoot;
  const Content = fullWidth ? FullWidthContent : StandardContent;

  return (
    <Root
      onMouseEnter={() => {
        googleAnalyticsCustomEvent("legend", "hover", "viz", "explore");
      }}
    >
      <Content>{output}</Content>
    </Root>
  );
};

export default CategoryLabels;
