import { memo, useEffect, useMemo } from "react";
import { highlightInVizReducedOpacity } from "../../Utils";

const ribbonHighlightedStyle = {
  opacity: 1,
};

const ribbonUnhighlightedStyle = {
  opacity: highlightInVizReducedOpacity,
};

const StackRibbon = ({
  series,
  seriesKey,
  areaGenerator,
  useId,
  colorMap,
  handleTooltip,
  highlightedItem,
}: any) => {
  const memoizedHighlightedItem = useMemo(
    () => highlightedItem,
    [highlightedItem],
  );
  let useColor = colorMap.get(useId);
  let useStyle = useMemo(() => {
    if (memoizedHighlightedItem) {
      if (seriesKey === memoizedHighlightedItem) {
        return ribbonHighlightedStyle;
      } else {
        return ribbonUnhighlightedStyle;
      }
    }
  }, [memoizedHighlightedItem, seriesKey]);

  useEffect(() => {
    if (highlightedItem) {
      if (seriesKey === memoizedHighlightedItem) {
        handleTooltip({ setTooltipFixed: true, fillColor: useColor });
      }
    }
  }, [
    handleTooltip,
    highlightedItem,
    memoizedHighlightedItem,
    seriesKey,
    useColor,
  ]);

  return (
    <g
      onMouseMoveCapture={() => handleTooltip({ series, fillColor: useColor })}
    >
      <path
        d={areaGenerator(series)}
        fill={useColor}
        stroke={"darkgrey"}
        strokeWidth={0.0}
        strokeOpacity={0.5}
        style={useStyle}
      />
    </g>
  );
};

export default memo(StackRibbon);
