import { RouteID } from "../../routing/routes";
import { googleAnalyticsCustomEvent } from "../../sharedUtilities/googleAnalyticsEvent";
import { Side, InnerUl, InnerLi, HeaderNavigationListItem } from "./styles";
import { countryProfileRoutes, LinkMetadataItemProps } from "./Utils";
import { useState } from "react";
import { Link } from "react-router-dom";

interface FlyoutMenuItemProps {
  route: string;
  routeId: RouteID;
  label: string;
  subMenuItems: LinkMetadataItemProps[] | undefined;
}

const flyoutDirectionMap = new Map<RouteID, Side>([
  [RouteID.CountryProfiles, Side.Left],
  [RouteID.DataDownloads, Side.Right],
  // [RouteID.KeyConcepts, Side.Right],
  [RouteID.About, Side.Right],
]);

const FlyoutMenuItem = ({
  routeId,
  route,
  label,
  subMenuItems,
}: FlyoutMenuItemProps) => {
  let [isOpen, setIsOpen] = useState<boolean>(false);

  const handleMouseEnter = () => setIsOpen(true);
  const handleMouseLeave = () => setIsOpen(false);
  let highlightClassName: string | null;

  let flyout: JSX.Element | null;
  if (isOpen && subMenuItems) {
    highlightClassName = "highlight";

    const flyoutDirection = flyoutDirectionMap.get(routeId) as Side;
    flyout = (
      <InnerUl side={flyoutDirection}>
        {subMenuItems.map(({ route, label }) => {
          let innerMenuLink: any;
          if (routeId !== RouteID.Explore) {
            innerMenuLink = <a href={route}>{label}</a>;
          } else {
            innerMenuLink = <Link to={route}>{label}</Link>;
          }
          return <InnerLi>{innerMenuLink}</InnerLi>;
        })}
      </InnerUl>
    );
  } else {
    highlightClassName = null;
    flyout = null;
  }
  let outerMenuLink: any;
  if (
    //served by country profiles shell
    countryProfileRoutes.includes(routeId)
  ) {
    outerMenuLink = (
      <a
        onClick={() =>
          googleAnalyticsCustomEvent(
            "nav",
            "click",
            label.split(" ").join("-"),
            "topnav",
          )
        }
        href={route}
      >
        {label}
      </a>
    );
  } else if (routeId === RouteID.Learn) {
    outerMenuLink = <span>{label}</span>;
  } else {
    outerMenuLink = <Link to={route}>{label}</Link>;
  }

  return (
    <>
      <HeaderNavigationListItem
        className={highlightClassName ? highlightClassName : ""}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {outerMenuLink}
        {flyout}
      </HeaderNavigationListItem>
    </>
  );
};

export default FlyoutMenuItem;
