import styled from "@emotion/styled";
import { highlightColor } from "../../styling/cssVariables";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export const DropdownContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  height: 34px;
  line-height: 1.15;
  box-sizing: border-box;
  cursor: pointer;

  padding: 0px;
  border: 1px solid #5d5d5d;
  border-radius: 4px;

  &.dropdown-open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

export const SelectBoxContainer = styled.div`
  width: 100%;
  position: relative;
`;

const ArrowBase = styled.div`
  --triangle-half-base: 5px;
  --triangle-height: 5px;
  --triangle-color: #999;

  width: 0;
  height: 0;
  border-left: var(--triangle-half-base) solid transparent;
  border-right: var(--triangle-half-base) solid transparent;
`;

export const ArrowUp = KeyboardArrowUpIcon;
export const ArrowDown = KeyboardArrowDownIcon;

export const ToggleButton = styled.button`
  position: relative;
  // right: 0px;
  // top: 0px;
  display: inline-block;
  margin: auto 0px;
  padding-right: 6px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: none;
  z-index: 100;
  height: 100%;
  cursor: pointer;
`;

export const MainMenu = styled.ul`
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  z-index: 2000;
  background-color: #ffffff;
  list-style-type: none;
  padding-left: 0px;
  padding: 0px;
  margin: 0px;
  width: 100%;
  margin-block: 0;
  border-top: none;

  box-sizing: border-box;

  & .menu-item--highlighted {
    background-color: ${highlightColor};
  }
  border-bottom: 1px solid #5d5d5d;
  border-right: 1px solid #5d5d5d;
  border-left: 1px solid #5d5d5d;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
`;

export const BaseMenuItem = styled.li`
  height: 36px;
  cursor: pointer;
  width: calc(100% - 0px);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  font-size: 0.875rem;
  color: rgb(51, 51, 51);
  // padding: 2px;
  box-sizing: border-box;
  position: relative;
  padding-right: 10px;
  overflow: hidden;
  background-color: #ffffff;

  &.menu-item--highlighted {
    background-color: ${highlightColor};
  }

  &:last-child {
    border-bottom: 1px solid rgb(204, 204, 204);
  }

  &.disabled {
    position: relative;
    width: 100%;
    cursor: not-allowed;
    overflow: visible;

    & > :first-child {
      opacity: 0.4;
    }

    & > * {
      width: 100%;
      pointer-events: none;
    }
  }
`;
