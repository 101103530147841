import styled from "@emotion/styled";
import { controlsPanelBackgroundColor } from "../components/styling/cssVariables";
import { gridLines as globalGridLines } from "../components/styling/GlobalGrid";

export const gridLines = {
  titleHeaderTop: "TitleHeaderTop",
  titleHeaderBottom: "TitleHeaderBottom",
  visualizationContentTop: "VisualizationContentTop",
  visualizationContentBottom: "VisualizationContentBottom",
  intraRegionTradeDisclaimerTop: "IntraRegionTradeDisclaimerTop",
  intraRegionTradeDisclaimerBottom: "IntraRegionTradeDisclaimerBottom",
  visualizationHandlesTop: "VisualizationHandlesTop",
  visualizationHandlesBottom: "VisualizationHandlesBottom",
  visualizationAreaLeft: "VisualizationAreaLeft",
  visualizationAreaRight: "VisualizationAreaRight",
  visualizationControlsTop: "VisualizationControlsTop",
  visualizationControlsBottom: "VisualizationControlsBottom",
};

export const gridAreas = {
  visualization: "VisualizationArea",
  controls: "ControlsArea",
  visualizationTitle: "VisualizationTitleArea",
  visualizationContent: "VisualizationContentArea",
  intraRegionTradeDisclaimer: "IntraRegionTradeDisclaimer",
  visualizationBottomRow: "VisualizationBottomRowArea",
  visualizationHandles: "VisualizationHandlesArea",
  visualizationTotal: "VisualizationTotalArea",
};

export const VisualizationContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 0px;
  box-sizing: border-box;
  overflow-x: hidden;

  display: grid;
  column-gap: 1rem;
  grid-template-columns:
    [ ${globalGridLines.globalMainContentAreaLeft}] minmax(0, 1fr)
    [ ${globalGridLines.globalMainContentAreaRight}];
  grid-template-rows:
    [ ${globalGridLines.globalPageTop} ${gridLines.visualizationControlsTop}] auto
    [ ${gridLines.visualizationControlsBottom} ${gridLines.titleHeaderTop}] auto
    [ ${gridLines.titleHeaderBottom} ${gridLines.visualizationContentTop}] minmax(
      0,
      1fr
    )
    [ ${globalGridLines.globalPageBottom}];
  grid-template-areas:
    "${gridAreas.controls}"
    "${gridAreas.visualizationTitle}"
    "${gridAreas.visualization}";
`;

export const ControlsContainer = styled.div`
  grid-area: ${gridAreas.controls};
  background-color: ${controlsPanelBackgroundColor};
  display: flex;
  padding: 10px 30px;
  padding-bottom: 20px;
  padding-top: 25px;
  position: relative;
  border-bottom: 1px solid #b8c0c7;
`;

export const ControlsInputsRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
`;

export const VisualizationTitleContainer = styled.div`
  grid-area: ${gridAreas.visualizationTitle};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1 0 auto;
  padding: 0px 30px;
`;

export const VisualizationTitleBase = styled.div`
  padding: 3px 10px;
  font-weight: 600;
  font-size: 20px;
`;

export const IntraRegionTradeDisclaimerContainer = styled.div`
  grid-area: ${gridAreas.intraRegionTradeDisclaimer};
  padding-top: 0.5rem;
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  text-align: right;
  color: #666666;
  font-size: 11px;
`;

export const VisualizationGrid = styled.div`
  grid-area: ${gridAreas.visualization};
  display: grid;
  box-sizing: border-box;
  padding: 0px 30px;

  grid-template-rows: [ ${gridLines.visualizationContentTop}] 1fr [ ${gridLines.visualizationContentBottom} ${gridLines.visualizationHandlesTop}] 60px [ ${gridLines.visualizationHandlesBottom}];
  grid-template-columns: [ ${gridLines.visualizationAreaLeft}] 1fr [ ${gridLines.visualizationAreaRight}];
  grid-template-areas:
    "${gridAreas.visualizationContent}"
    "${gridAreas.visualizationBottomRow}";
`;

export const VisualizationContentContainer = styled.div`
  grid-area: ${gridAreas.visualizationContent};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 10px 0px 10px 0px;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  height: 100%;
  flex-shrink: 1;
  flex-direction: column;
`;

export const VisualizationBottomRowContainer = styled.div`
  grid-area: ${gridAreas.visualizationBottomRow};
  display: flex;
  flex-direction: row;
`;

export const VisualizationHandlesContainer = styled.div`
  flex: 1 0 auto;
  display: flex;
  justify-content: center;
`;

export const VisualizationTotalContainer = styled.div`
  flex: 0 1 auto;
  height: 100%;
  background-color: #eaedef;
  text-transform: uppercase;
  font-size: 1rem;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
`;

export const TotalValueLabel = styled.span`
  font-size: 1.6rem;
  font-weight: bold;
  margin-left: 10px;
`;
