import ReactGA from "react-ga4";

export enum GoogleAnalyticsCaptureType {
  PageView = "pageview",
  CustomEvent = "customevent",
}

const googleAnalyticsEvent = (
  captureType: GoogleAnalyticsCaptureType,
  category: string,
  action: string,
  label: string,
  location: string,
) => {
  // if (
  //   process.env.NODE_ENV === "production" &&
  //   process.env.REACT_APP_GOOGLE_ANALYTICS_GA4_ID
  // ) {
  // See: https://developers.google.com/analytics/devguides/collection/gtagjs/events
  // See: https://www.npmjs.com/package/react-ga4
  if (captureType === GoogleAnalyticsCaptureType.PageView) {
    // For pageview events, use value of `label` as page path
    ReactGA.send({ hitType: "pageview", page: label });
  } else if (captureType === GoogleAnalyticsCaptureType.CustomEvent) {
    const eventName = `${category}_${action}_${label}_${location}`;

    ReactGA.event(eventName, {
      category,
      action,
      element: label,
      location,
    });
  }
  // }
};

export const googleAnalyticsCustomEvent = (
  action: string,
  category: string,
  label: string,
  element: string,
) => {
  googleAnalyticsEvent(
    GoogleAnalyticsCaptureType.CustomEvent,
    category,
    action,
    label,
    element,
  );
};

export default googleAnalyticsEvent;
