import { memo, useEffect, useMemo, useRef, useState } from "react";
import { ColorBy, highlightInVizReducedOpacity } from "../../Utils";
import { select } from "d3";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";

const treemapRectangleStyle = {
  stroke: "#ffffff",
};

const treemapRectangleHighlightedStyle = {
  ...treemapRectangleStyle,
  opacity: 1,
};

const treemapRectangleUnhlightedStyle = {
  ...treemapRectangleStyle,
  opacity: highlightInVizReducedOpacity,
};

const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const TreemapGroup = styled.g`
  opacity: 1;

  &.enter {
    opacity: 0;
    animation-name: ${fadeIn};
    animation-duration: 0.2s;
    animation-timing-function: linear;
    // animation-delay: 0.3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
`;

const TreemapRectangle = memo(
  ({ leaf, colorMap, useId, colorBy, useStyle }: any) => {
    const useColor = useMemo(() => {
      if (colorBy === ColorBy.Sector) {
        return colorMap.get(useId);
      } else if (colorBy === ColorBy.Complexity) {
        return leaf.data.pci ? colorMap(leaf.data.pci) : "#CCCCCC";
      }
    }, [colorBy, colorMap, useId, leaf.data.pci]);

    return <rect style={useStyle} x="0" y="0" fill={useColor}></rect>;
  },
);

const TreemapCell = ({
  leaf,
  handleTooltip,
  colorMap,
  useId,
  colorBy,
  highlightedItem,
  servicesWidth,
  nonServices,
}: any) => {
  const xPosition = nonServices ? servicesWidth + leaf.x0 : leaf.x0;
  const yPosition = leaf.y0;

  const treemapCellRef = useRef<SVGGElement | null>(null);

  let [previousLeafData, setPreviousLeafData] = useState<any>(undefined);

  const useColor = useMemo(() => {
    if (colorBy === ColorBy.Sector) {
      return colorMap.get(useId);
    } else if (colorBy === ColorBy.Complexity) {
      return leaf.data.pci ? colorMap(leaf.data.pci) : "#CCCCCC";
    }
  }, [colorBy, colorMap, useId, leaf.data.pci]);

  useEffect(() => {
    if (treemapCellRef && treemapCellRef.current) {
      let treemapCellNode = treemapCellRef.current;
      if (previousLeafData === undefined) {
        let cellWidth = leaf.x1 - leaf.x0;
        let cellHeight = leaf.y1 - leaf.y0;

        select(treemapCellNode)
          .select("rect")
          .attr("width", cellWidth)
          .attr("height", cellHeight);

        select(treemapCellNode)
          .attr("transform", `translate(${xPosition}, ${yPosition})`)
          .classed("enter", true);

        setPreviousLeafData(leaf);
      } else {
        let { y0: nextLeafYPosition } = leaf;
        let nextLeafXPosition = nonServices ? servicesWidth + leaf.x0 : leaf.x0;
        let nextWidth = nonServices ? leaf.x1 - leaf.x0 : leaf.x1 - leaf.x0;
        let nextHeight = leaf.y1 - leaf.y0;

        select(treemapCellNode)
          .classed("enter", false)
          .transition()
          .duration(500)
          .attr(
            "transform",
            `translate(${nextLeafXPosition}, ${nextLeafYPosition})`,
          );

        select(treemapCellNode)
          .select("rect")
          .transition()
          .duration(500)
          .attr("width", nextWidth)
          .attr("height", nextHeight);

        setPreviousLeafData(leaf);
      }
    }
  }, [
    treemapCellRef,
    leaf,
    previousLeafData,
    xPosition,
    yPosition,
    nonServices,
    servicesWidth,
  ]);

  const useStyle = useMemo(() => {
    if (
      leaf.data.productId === highlightedItem ||
      leaf.data.id === highlightedItem
    ) {
      return treemapRectangleHighlightedStyle;
    } else {
      return treemapRectangleUnhlightedStyle;
    }
  }, [leaf.data.productId, leaf.data.id, highlightedItem]);
  useEffect(() => {
    if (highlightedItem) {
      if (
        leaf.data.productId === highlightedItem ||
        leaf.data.id === highlightedItem
      ) {
        // If highlighted item, trigger tooltip
        let yLocation = leaf.y0;
        let xLocation = servicesWidth
          ? servicesWidth + (leaf.x0 + leaf.x1) / 2
          : (leaf.x0 + leaf.x1) / 2;

        handleTooltip({
          datum: leaf.data,
          leaf,
          setTooltipFixed: true,
          setTooltipCoords: [xLocation, yLocation],
          fillColor: useColor,
        });
      }
    }
  }, [handleTooltip, highlightedItem, leaf, servicesWidth, useColor]);

  return (
    <TreemapGroup
      ref={treemapCellRef}
      onMouseMove={() =>
        handleTooltip({ datum: leaf.data, fillColor: useColor })
      }
    >
      <TreemapRectangle
        leaf={leaf}
        colorMap={colorMap}
        useId={useId}
        colorBy={colorBy}
        useStyle={useStyle}
      />
    </TreemapGroup>
  );
};

export default memo(TreemapCell);
