import styled from "@emotion/styled";
import { YAxisScalingOption } from "./Chart";
import { memo, useState } from "react";
import { chartMargin as chartMarginBase } from "../../components/styles";
import { UIView } from "../../Utils";
import Popover from "@mui/material/Popover";
import { usePageQueryParams } from "../../defaultSettings";
import { highlightColor } from "../../../components/styling/cssVariables";

interface YAxisScalingOptionsSelectorProperties {
  view: UIView;
  yAxisScalingOptionsSelectorMargin: number;
  tradeDirection: any;
  tradeFlow: any;
}

const YAxisScalingOptionSelectorContainer = styled.div<{
  $containerWidth: number;
}>`
  width: ${({ $containerWidth }) => $containerWidth}px;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  padding-bottom: ${chartMarginBase.bottom}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #232323;
  box-sizing: border-box;
  pointer-events: none;
`;

const YAxisScalingOptionButton = styled.button`
  width: 34px;
  padding: 5px 0px;
  text-transform: capitalize;
  border: 1px solid #5d5d5d;
  letter-spacing: 0.2px;
  writing-mode: vertical-lr;
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  transform: rotate(-180deg);
  line-height: 30px;
  position: relative;
  cursor: pointer;
  pointer-events: all;
  background-color: #ffffff;
  border-radius: 4px;

  &:hover {
    border-color: #334c60;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  // box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
`;

const YAxisOptionsFlyout = styled.div`
  display: flex;
  width: max-content;
  flex-direction: column;
  writing-mode: horizontal-tb;
  list-style-type: none;
  margin: 0px;
  padding: 0px;
  background-color: #ffffff;
  border: 1px solid rgb(204, 204, 204);
  // border-radius: 4px;
  // box-shadow:
  //   rgba(0, 0, 0, 0.2) 0px 5px 5px -3px,
  //   rgba(0, 0, 0, 0.14) 0px 8px 10px 1px,
  //   rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
  box-sizing: border-box;
`;

const YAxisOptionsFlyoutOption = styled.button`
  cursor: pointer;
  padding: 6px 16px;
  text-transform: capitalize;
  background-color: #ffffff;
  border: none;
  text-align: left;
  min-width: 150px;
  height: 32px;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  color: rgb(51, 51, 51);
  box-sizing: border-box;
  position: relative;
  overflow: hidden;

  &:hover,
  &.selected {
    background-color: ${highlightColor};
  }

  &:last-child {
    border-bottom: none;
  }
`;

const YAxisOptionArrow = styled.span`
  border: solid #666;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  margin-top: 5px;
  margin-left: 3px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  transform: rotate(135deg);
`;

const YAxisScalingOptionsSelector = ({
  view,
  tradeDirection,
  tradeFlow,
  yAxisScalingOptionsSelectorMargin,
}: YAxisScalingOptionsSelectorProperties) => {
  const [{ yAxis: currentYAxisScalingOption }, setQuery] = usePageQueryParams();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let flyoutMenuOptions: YAxisScalingOption[] | undefined;
  if (view === UIView.Markets) {
    flyoutMenuOptions = [
      YAxisScalingOption.Current,
      YAxisScalingOption.Constant,
    ];
  } else if (view === UIView.Products) {
    flyoutMenuOptions = [
      YAxisScalingOption.Current,
      YAxisScalingOption.Constant,
      YAxisScalingOption.PerCapita,
      YAxisScalingOption.PerCapitaConstant,
    ];
  }

  let currentYAxisScalingOptionLabel;
  if (currentYAxisScalingOption === YAxisScalingOption.Current) {
    currentYAxisScalingOptionLabel = `Current ${tradeFlow} ${tradeDirection}`;
  } else {
    currentYAxisScalingOptionLabel = currentYAxisScalingOption;
  }
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <YAxisScalingOptionSelectorContainer
      $containerWidth={yAxisScalingOptionsSelectorMargin}
    >
      <YAxisScalingOptionButton aria-describedby={id} onClick={handleClick}>
        {currentYAxisScalingOptionLabel}
        <YAxisOptionArrow />
      </YAxisScalingOptionButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          "& .MuiPopover-paper": {
            backgroundColor: "transparent",
            boxShadow: "none",
            overflow: "visible",
          },
        }}
      >
        <div>
          <YAxisOptionsFlyout>
            {flyoutMenuOptions &&
              flyoutMenuOptions.map((scalingOption: YAxisScalingOption) => {
                let scalingOptionLabel;
                if (scalingOption === YAxisScalingOption.Current) {
                  scalingOptionLabel = `Current ${tradeFlow} ${tradeDirection}`;
                } else {
                  scalingOptionLabel = scalingOption;
                }
                let className =
                  currentYAxisScalingOption &&
                  currentYAxisScalingOption === scalingOption
                    ? "selected"
                    : undefined;

                let handleScalingOptionClick = () => {
                  setQuery({ yAxis: scalingOption });
                  handleClose();
                };
                return (
                  <YAxisOptionsFlyoutOption
                    className={className}
                    onClick={handleScalingOptionClick}
                  >
                    {scalingOptionLabel}
                  </YAxisOptionsFlyoutOption>
                );
              })}
          </YAxisOptionsFlyout>
        </div>
      </Popover>
    </YAxisScalingOptionSelectorContainer>
  );
};

export default memo(YAxisScalingOptionsSelector);
