import { useLocation } from "react-router-dom";
import styled from "@emotion/styled";
import {
  TradeDirection,
  VizType,
  getLocationQualifiers,
} from "../../visualization/Utils";
import LocationDropdown from "./locationDropdown/LocationDropdown";
import ProductDropdown from "./productDropdown/ProductDropdown";
import { UIView } from "../../visualization/Utils";
import UIViewSelectorButton from "../buttons/UIViewSelectorButton";
import { ReactElement, useState } from "react";
import TradeDirectionSelectorButton from "../buttons/TradeDirectionSelectorButton";
import YearDropdown, { YearSelectionRole } from "./yearDropdown/YearDropdown";
import ControlPanelArrow from "./assets/control-panel-arrow.svg";
import SwapIcon from "./assets/swap.svg";
import SettingsIcon from "./assets/settings.svg";
import SettingsPanel from "./SettingsPanel";
import {
  SegmentContainer,
  GroupContainer,
  InputContainer,
  DropdownInputContainer,
  YearInputContainer,
  LabelContainer,
  IconContainer,
  SpacerElement,
} from "./ControlsGrid";
import { DropdownRole } from "../mainDropdownPrimitive";
import ShowTable from "./ShowTable";
import { usePageQueryParams } from "../../visualization/defaultSettings";
import { worldGroupDatum } from "../../graphql/queries/getLocationsMetadata";
import { useTutorial } from "../../components/tutorial/TutorialContext";
import { ReactComponent as LearnIcon } from "../tutorial/img/learn.svg";
import { googleAnalyticsCustomEvent } from "../../sharedUtilities/googleAnalyticsEvent";

const UIViewList = styled.ul`
  display: flex;
  flex-direction: row;
  width: 180px;
  height: 34px;
  border: 1px solid #5d5d5d;
  border-radius: 15px;
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;

  & a {
    text-decoration: none;
    color: inherit;
  }
`;

const TradeDirectionList = styled.ul`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0px;
  margin: 0px;
  list-style-type: none;
  padding: 0px;

  & a {
    text-decoration: none;
    color: inherit;
  }

  & li:hover {
    cursor: pointer;
    color: #567388;
  }
`;

const UIViewArrow = styled.div`
  width: 25px;
  margin: 0px 10px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  box-sizing: border-box;

  & img {
    width: 100%;
  }
`;

const SwapExporterImporter = styled.div`
  margin: 0px 10px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  box-sizing: border-box;
  cursor: pointer;

  & img {
    width: 100%;
  }
`;

const InlineIconContainer = styled.button`
  width: auto; // Change from fixed width to auto
  padding: 0 5px; // Add some horizontal padding
  background: none;
  border: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  & img {
    width: 15.4px;
    height: 15px;
  }
`;

export const SettingsLabelContainer = styled.button`
  text-transform: uppercase;
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 20px;
  cursor: pointer;

  & img {
    width: 20px;
    margin-right: 5px;
  }
  border: none;
  background-color: transparent;
`;

const useHandleExporterImporterSwap = () => {
  const [{ importer, exporter }, setQuery] = usePageQueryParams();
  return () => {
    setQuery({
      importer: exporter,
      exporter: importer,
    });
    googleAnalyticsCustomEvent("btn", "click", "swaptrade", "explore");
  };
};

const TutorialSegment = styled(SegmentContainer)`
  justify-self: flex-end;
  align-self: flex-end;
  margin-left: auto;
`;

const LightbulbIcon = styled.div`
  color: #d1b656;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  svg {
    width: 20px;
    height: 20px;
    fill: white;
  }
`;

const TutorialLabel = styled.button`
  background: #d1b656;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  font-family: inherit;
  font-size: inherit;
  white-space: nowrap;
  &:hover {
    background: #b5a25f;
    color: white;
  }
`;

export default function ControlsPanel({
  findInVizOptions,
  highlightedItem,
  setHighlightedItem,
}) {
  const { setIsTutorialOpen } = useTutorial();
  let [isSettingsOpen, setIsSettingsOpen] = useState<boolean>(false);
  const [
    {
      importer: currentImporter,
      exporter: currentExporter,
      view: currentUIView,
    },
    setQuery,
  ] = usePageQueryParams();
  const location = useLocation();

  const currentVizType = location.pathname.split("/")[2] as any;

  const handleExporterImporterSwap = useHandleExporterImporterSwap();

  const { exporterIsWorld } = getLocationQualifiers({
    exporter: currentExporter,
    importer: currentImporter,
  });

  let mainDropdown: ReactElement | null = null;

  let yearInputs: ReactElement;
  if (currentVizType === VizType.OverTime) {
    yearInputs = (
      <>
        <SegmentContainer className="withMargin">
          <LabelContainer>Start Year</LabelContainer>
          <YearInputContainer>
            <YearDropdown yearSelectionRole={YearSelectionRole.StartYear} />
          </YearInputContainer>
        </SegmentContainer>
        <SegmentContainer className="withMargin">
          <LabelContainer>End Year</LabelContainer>
          <YearInputContainer>
            <YearDropdown yearSelectionRole={YearSelectionRole.EndYear} />
          </YearInputContainer>
        </SegmentContainer>
      </>
    );
  } else {
    yearInputs = (
      <SegmentContainer className="withMargin">
        <LabelContainer>Year</LabelContainer>
        <YearInputContainer>
          <YearDropdown yearSelectionRole={YearSelectionRole.FixedYear} />
        </YearInputContainer>
      </SegmentContainer>
    );
  }

  if (currentUIView === UIView.Markets) {
    let locationSelectionRoleToUse: DropdownRole | undefined;
    if (
      (currentExporter === "" && currentImporter === worldGroupDatum.groupId) ||
      (currentExporter === worldGroupDatum.groupId && currentImporter !== "")
    ) {
      locationSelectionRoleToUse = DropdownRole.LocationImporter;
    } else {
      locationSelectionRoleToUse = DropdownRole.LocationExporter;
    }

    mainDropdown = (
      <>
        <SegmentContainer>
          <LabelContainer>
            <TradeDirectionList>
              <TradeDirectionSelectorButton
                targetOption={TradeDirection.Exports}
                selected={
                  locationSelectionRoleToUse === DropdownRole.LocationExporter
                    ? true
                    : false
                }
              />
              <InlineIconContainer
                onClick={() => {
                  const { importerIsWorld } = getLocationQualifiers({
                    exporter: currentExporter,
                    importer: currentImporter,
                  });
                  googleAnalyticsCustomEvent(
                    "btn",
                    "click",
                    "swaptrade",
                    "explore",
                  );

                  if (importerIsWorld) {
                    setQuery({
                      importer: currentExporter,
                      exporter: worldGroupDatum.groupId,
                    });
                  } else {
                    setQuery({
                      importer: worldGroupDatum.groupId,
                      exporter: currentImporter,
                    });
                  }
                }}
              >
                <img src={SwapIcon} alt="Swap Importer and Exporter" />
              </InlineIconContainer>
              <TradeDirectionSelectorButton
                targetOption={TradeDirection.Imports}
                selected={
                  locationSelectionRoleToUse === DropdownRole.LocationImporter
                    ? true
                    : false
                }
              />
            </TradeDirectionList>
          </LabelContainer>
          <DropdownInputContainer>
            <LocationDropdown
              locationSelectionRole={locationSelectionRoleToUse}
            />
          </DropdownInputContainer>
        </SegmentContainer>
        <SegmentContainer className="withMargin">
          <LabelContainer>Products</LabelContainer>
          <DropdownInputContainer>
            <ProductDropdown />
          </DropdownInputContainer>
        </SegmentContainer>
      </>
    );
  } else if (currentUIView === UIView.Products) {
    mainDropdown = (
      <div
        className="customizeVisualization"
        style={{ display: "flex", flexWrap: "wrap" }}
      >
        <SegmentContainer>
          <LabelContainer>Exporter</LabelContainer>
          <DropdownInputContainer>
            <LocationDropdown
              locationSelectionRole={DropdownRole.LocationExporter}
            />
          </DropdownInputContainer>
        </SegmentContainer>
        <SegmentContainer>
          <SpacerElement />
          <IconContainer onClick={() => handleExporterImporterSwap()}>
            <SwapExporterImporter>
              <img
                style={{ height: "25px", width: "25px" }}
                src={SwapIcon}
                alt="Swap Importer and Exporter"
              />
            </SwapExporterImporter>
          </IconContainer>
        </SegmentContainer>
        <SegmentContainer>
          <LabelContainer>Importer</LabelContainer>
          <DropdownInputContainer>
            <LocationDropdown
              locationSelectionRole={DropdownRole.LocationImporter}
            />
          </DropdownInputContainer>
        </SegmentContainer>
      </div>
    );
  }

  const toggleSettingsPanel = () => {
    const newIsSettingsOpen = !isSettingsOpen;
    setIsSettingsOpen(newIsSettingsOpen);
    if (!isSettingsOpen) {
      googleAnalyticsCustomEvent("menu", "open", "settings", "explore");
    }
  };
  if (
    currentVizType === VizType.MarketShare ||
    currentVizType === VizType.Feasibility ||
    currentVizType === VizType.ProductSpace
  ) {
    mainDropdown = (
      <>
        <SegmentContainer>
          <LabelContainer>Exporter</LabelContainer>
          <DropdownInputContainer>
            <LocationDropdown
              locationSelectionRole={DropdownRole.LocationExporter}
            />
          </DropdownInputContainer>
        </SegmentContainer>
      </>
    );
    const feasibilityControls = <>{yearInputs}</>;
    return (
      <>
        <div
          className="customizeVisualization"
          style={{ display: "flex", flexWrap: "wrap", width: "100%" }}
        >
          {mainDropdown}
          {(currentVizType === VizType.Feasibility ||
            currentVizType === VizType.ProductSpace) &&
            feasibilityControls}
          {currentVizType === VizType.Feasibility && (
            <SegmentContainer>
              <SpacerElement />
              <ShowTable />
            </SegmentContainer>
          )}
          <GroupContainer>
            <SegmentContainer>
              <SpacerElement />
              <IconContainer>
                <SettingsLabelContainer onClick={toggleSettingsPanel}>
                  <img src={SettingsIcon} /> Settings
                </SettingsLabelContainer>
              </IconContainer>
            </SegmentContainer>
          </GroupContainer>
          {currentVizType === VizType.ProductSpace && (
            <TutorialSegment>
              <SpacerElement />
              <TutorialLabel
                onClick={() => {
                  googleAnalyticsCustomEvent(
                    "btn",
                    "click",
                    "pslearn",
                    "explore",
                  );
                  setIsTutorialOpen(true);
                }}
              >
                <LightbulbIcon>
                  <LearnIcon />
                </LightbulbIcon>
                Learn How to Use
              </TutorialLabel>
            </TutorialSegment>
          )}
        </div>
        {isSettingsOpen ? (
          <SettingsPanel
            vizType={currentVizType}
            toggleSettingsPanel={toggleSettingsPanel}
          />
        ) : null}
      </>
    );
  } else {
    return (
      <>
        <SegmentContainer className="viewSelector">
          <LabelContainer style={{ textAlign: "center" }}>
            Select what to visualize
          </LabelContainer>
          <InputContainer>
            <UIViewList>
              <UIViewSelectorButton
                uiViewOption={UIView.Products}
                selected={currentUIView === UIView.Products ? true : false}
                vizType={currentVizType}
              />
              <UIViewSelectorButton
                uiViewOption={UIView.Markets}
                selected={currentUIView === UIView.Markets ? true : false}
                vizType={currentVizType}
              />
            </UIViewList>
          </InputContainer>
        </SegmentContainer>
        <SegmentContainer>
          <SpacerElement />
          <IconContainer>
            <UIViewArrow>
              <img src={ControlPanelArrow} />
            </UIViewArrow>
          </IconContainer>
        </SegmentContainer>
        <div
          className="customizeVisualization"
          style={{ display: "flex", flexWrap: "wrap" }}
        >
          {mainDropdown}
          {yearInputs}

          <GroupContainer>
            <SegmentContainer>
              <SpacerElement />
              <IconContainer>
                <SettingsLabelContainer onClick={toggleSettingsPanel}>
                  <img src={SettingsIcon} /> Settings
                </SettingsLabelContainer>
              </IconContainer>
            </SegmentContainer>
          </GroupContainer>
        </div>

        {isSettingsOpen ? (
          <SettingsPanel
            vizType={currentVizType}
            toggleSettingsPanel={toggleSettingsPanel}
          />
        ) : null}
      </>
    );
  }
}
