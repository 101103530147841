import React, { useState } from "react";
import styled from "@emotion/styled";
import {
  demiFontWeight,
  semiBoldFontWeight,
  normalFontWeight,
} from "../../components/styling/cssVariables";
import { RouteID } from "../../routing/routes";

const CardContainer = styled.div`
  box-shadow: 0 0 9px 1px rgba(0, 0, 0, 0.28);
  background-color: #fff;
  min-width: 100%;
  display: grid;
  grid-template-rows: 300px auto;
  cursor: pointer;
`;

const ThumbnailContainer = styled.div`
  background-size: cover;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  position: relative;
`;

const Image = styled.img`
  object-fit: cover;
  position: relative;
  height: 100%;
  width: 100%;
`;

const HoveredImage = styled(Image)`
  display: none;
`;

const VideoContainer = styled.video`
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  pointer-events: none;
  user-select: none;
`;

const titleColor = "#415579";
const titleBorderColor = "#bbb04a";

const TextContainer = styled.div`
  margin: 1rem;
`;

const Title = styled.h2`
  padding-bottom: 10px;
  border-bottom: 3px solid ${titleBorderColor};
  display: inline-block;
  font-size: 28px;
  font-weight: ${normalFontWeight};
  color: ${titleColor};
  text-transform: uppercase;
`;

const Body = styled.p`
  margin-top: 0.5rem;
  font-size: 22px;
  font-weight: ${demiFontWeight};
`;

const buttonColor = "#bab04a";
const CTALink = styled.a`
  display: block;
  border: 1px solid ${buttonColor};
  padding: 0.5rem;
  color: ${buttonColor};
  float: right;
  border-radius: 30px;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: ${semiBoldFontWeight};

  &:hover {
    color: white;
    background-color: ${buttonColor};
  }
`;

const AwardLink = styled.a`
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(-100%);
`;

const Award = styled.img`
  width: 100px;
`;

interface Props {
  title: string;
  body: string;
  ctaText: string;
  ctaDestination: RouteID;
  placeholderImageUrl: string;
  videoUrl: string | undefined;
  showAward: boolean;
  href: string;
  analyticsEvent: () => void;
}

const Card = (props: Props) => {
  const {
    title,
    body,
    ctaText,
    placeholderImageUrl,
    videoUrl,
    showAward,
    href,
    analyticsEvent,
  } = props;

  const [isHovered, setIsHovered] = useState<boolean>(false);

  const onMouseEnter = () => {
    setIsHovered(true);
  };
  const onMouseLeave = () => {
    setIsHovered(false);
  };

  const awardIcon =
    showAward === false ? null : (
      <AwardLink
        href="https://www.informationisbeautifulawards.com/"
        target="_blank"
      >
        <Award src={require("./img/iib-badge-2019.png")} />
      </AwardLink>
    );

  let thumbnail: React.ReactElement<any> | null;
  if (videoUrl === undefined) {
    thumbnail = (
      <ThumbnailContainer>
        <Image src={placeholderImageUrl} />
        {awardIcon}
      </ThumbnailContainer>
    );
  } else {
    const ImageContainer = isHovered === true ? HoveredImage : Image;
    thumbnail = (
      <ThumbnailContainer>
        <VideoContainer loop muted autoPlay playsInline>
          <source src={videoUrl} />
        </VideoContainer>
        <ImageContainer src={placeholderImageUrl} />
        {awardIcon}
      </ThumbnailContainer>
    );
  }

  const onClick = (e: any) => {
    window.location.href = href;
  };
  const onCTAClick = (e: React.MouseEvent) => {
    onClick(e);
    analyticsEvent();
  };
  return (
    <CardContainer
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
    >
      {thumbnail}
      <TextContainer>
        <a href={href} onClick={onClick}>
          <Title>{title}</Title>
        </a>
        <Body>{body}</Body>
        <CTALink href={href} onClick={onCTAClick}>
          {ctaText}
        </CTALink>
      </TextContainer>
    </CardContainer>
  );
};

export default Card;
