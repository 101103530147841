import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import { menuBaseColor } from "../styling/cssVariables";

export const HeaderAtlasLogo = styled.div`
  display: block;
  margin-left: auto;

  & img {
    height: 40px;
    min-height: 45px;
  }
`;

export const HeaderNavigationList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  color: rgb(51, 51, 52);
  padding: 0;
`;

export const HeaderNavigationListItem = styled.li`
  height: 100%;
  display: flex;
  align-items: center;
  cursor: default;
  position: relative;
  background-color: transparent;
  color: inherit;

  & a,
  & span {
    height: 100%;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    text-transform: uppercase;
    letter-spacing: 0.08rem;
    font-size: clamp(8px, 2vw, 0.9375rem);
    text-decoration: none;
    color: inherit;
  }

  & {
    :hover,
    .highlight {
      background-color: ${menuBaseColor};
      color: white;
    }
  }
`;

export enum Side {
  Left = "left",
  Right = "right",
}

const delayVisibility = keyframes`
    from {
        max-height: 0;
        opacity: 0;
    }
    to {
        max-height: 9999px;
        opacity: 1;
    }
`;

export const InnerUl = styled.ul<{ side: Side }>`
  position: relative;
  top: 3.75rem;
  position: absolute;
  z-index: 100;
  right: ${({ side }) => (side === Side.Left ? "" : 0)};
  left: ${({ side }) => (side === Side.Left ? 0 : "")};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
  background-color: ${menuBaseColor};
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  animation-name: ${delayVisibility};
  animation-duration: 0.1s;
  animation-timing-function: linear;
  animation-delay: 0.4s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  list-style-type: none;
`;

export const InnerLi = styled.li`
  white-space: nowrap;
  line-height: 1.875;
  color: white;
  text-align: left;
  color: #ffffff;

  & a {
    text-transform: none;
    letter-spacing: 0px;
  }

  & :hover {
    text-decoration: underline;
  }
`;

export const InnerLiAnchor = styled.a`
  display: block;

  span {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: transparent;
  }

  &:hover span {
    border-bottom-color: white;
  }
`;
