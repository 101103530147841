import styled from "@emotion/styled";
import { usePageQueryParams } from "../../visualization/defaultSettings";
import { googleAnalyticsCustomEvent } from "../../sharedUtilities/googleAnalyticsEvent";

const Slider = styled.input`
  appearance: none;
  width: 100%;
  height: 6px;
  border-radius: 3px;
  padding: 4px;
  ::-webkit-slider-thumb {
    appearance: none;
    width: 16px;
    height: 16px;
    background-color: #334c60;
    cursor: pointer;
    border-radius: 50%;
    border: 1px solid white;
    margin-top: -3px;
  }

  /* Firefox */
  ::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background-color: #334c60;
    cursor: pointer;
    border: 1px solid white;
    margin-top: -3px;
  }

  /* Update the background on input change */
  ::-webkit-slider-runnable-track {
    border: 1px solid white;
    background: linear-gradient(
      to right,
      #334c60 0%,
      #334c60 var(--value),
      #ffff var(--value),
      #ffff 100%
    );
    height: 12px;
    border-radius: 6px;
    border: 1px solid #5d5d5d;
  }

  ::-moz-range-track {
    border: 1px solid white;
    background: linear-gradient(
      to right,
      #334c60 0%,
      #334c60 var(--value),
      #ffff var(--value),
      #ffff 100%
    );
    height: 12px;
    border-radius: 6px;
    border: 1px solid #5d5d5d;
  }
`;

const RcaSlider = () => {
  const [{ rcaThreshold }, setQuery] = usePageQueryParams();

  const handleSliderChange = (e) => {
    const value = parseFloat(e.target.value);
    setQuery({ rcaThreshold: value }, "replaceIn");
    e.target.style.setProperty("--value", `${(value / 5) * 100}%`);
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Slider
        min={0}
        max={5}
        step={0.1}
        value={rcaThreshold}
        onChange={handleSliderChange}
        onMouseUp={() => {
          googleAnalyticsCustomEvent("slider", "change", "rca", "explore");
        }}
        type="range"
        style={
          { "--value": `${(rcaThreshold / 5) * 100}%` } as React.CSSProperties
        }
      />
      <div style={{ display: "flex" }}>
        <div>RCA=</div>
        <input
          style={{ width: "35px" }}
          type="number"
          value={rcaThreshold}
          onChange={(e) => {
            setQuery({ rcaThreshold: parseFloat(e.target.value) });
            googleAnalyticsCustomEvent("slider", "change", "rca", "explore");
          }}
          step={0.1}
          min={0}
          max={5}
        />
      </div>
    </div>
  );
};
export default RcaSlider;
