import { memo } from "react";
import { usePageQueryParams } from "../../defaultSettings";
import { OpportunitySizeOption } from "../growth/utils";
import NodeColorLegend from "./NodeColorLegend";

const ChartLegend = ({ rScale }) => {
  const [{ sizing }] = usePageQueryParams();

  const getSizingLegendText = () => {
    switch (sizing) {
      case OpportunitySizeOption.CountryTrade:
        return " based on Country Exports";
      case OpportunitySizeOption.WorldTrade:
        return " based on Global Exports";
      default:
        return "";
    }
  };

  return (
    <div
      style={{
        position: "absolute",
        right: 4,
        top: 100,
        background: "rgba(255,255,255,.85)",
        borderRadius: 4,
        padding: "8px 12px",
        maxWidth: 150,
        zIndex: 1,
      }}
    >
      <div>
        <div
          style={{
            fontWeight: "bold",
            fontSize: 11,
            marginBottom: 12,
            color: "#D1B656",
          }}
        >
          How to Read
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: 12,
          }}
        >
          <svg width={126} height={95}>
            <text x="63" y="75" textAnchor="middle" fontSize="11" fill="black">
              Distinct
            </text>
            <text x="63" y="87" textAnchor="middle" fontSize="11" fill="black">
              Products
            </text>
            <g transform="translate(13, 0)">
              <circle
                cx="10"
                cy="40"
                r="7"
                fill="#E9E9E9"
                stroke="black"
                strokeWidth="1"
              />
              <circle
                cx="30"
                cy="40"
                r="7"
                fill="#E9E9E9"
                stroke="black"
                strokeWidth="1"
              />
              <circle
                cx="90"
                cy="40"
                r="7"
                fill="#E9E9E9"
                stroke="black"
                strokeWidth="1"
              />
              <path
                d="M 10,31 Q 20,25 30,31"
                fill="none"
                stroke="black"
                strokeWidth="1"
                markerStart="url(#arrowhead-start)"
                markerEnd="url(#arrowhead)"
              />
              <text
                x="20"
                y="10"
                textAnchor="middle"
                fontSize="11"
                fill="black"
              >
                Similar
              </text>
              <text
                x="20"
                y="22"
                textAnchor="middle"
                fontSize="11"
                fill="black"
              >
                Products
              </text>
              <path
                d="M 10,49 Q 50,75 90,49"
                fill="none"
                stroke="black"
                strokeWidth="1"
                markerStart="url(#arrowhead-start)"
                markerEnd="url(#arrowhead)"
              />
            </g>
            <defs>
              <marker
                id="arrowhead"
                markerWidth="5"
                markerHeight="4"
                refX="4"
                refY="2"
                orient="auto"
              >
                <polygon points="0 0, 5 2, 0 4" fill="black" />
              </marker>
              <marker
                id="arrowhead-start"
                markerWidth="5"
                markerHeight="4"
                refX="1"
                refY="2"
                orient="auto"
              >
                <polygon points="5 0, 5 4, 0 2" fill="black" />
              </marker>
            </defs>
          </svg>
        </div>
        <div
          style={{
            fontSize: 11,
            color: "black",
            marginBottom: 12,
          }}
        >
          Products that are <strong>closer</strong> to each other require{" "}
          <strong>similar capabilities</strong>
        </div>
        <div
          style={{
            fontSize: 11,
            color: "black",
            marginBottom: 16,
          }}
        >
          Products that are <strong>distant</strong> require{" "}
          <strong>distinct capabilities</strong>
        </div>
      </div>
      <NodeColorLegend />
      {sizing !== OpportunitySizeOption.None && (
        <div>
          <div
            style={{
              fontSize: 11,
              marginTop: 16,
              marginBottom: 8,
            }}
          >
            <span style={{ fontWeight: "bold" }}>Node Sizing </span>{" "}
            {getSizingLegendText()}
          </div>
        </div>
      )}
    </div>
  );
};
export default memo(ChartLegend);
