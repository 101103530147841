import { memo, useEffect, useState } from "react";
import { SettingsLabelContainer } from "..";
import { IconContainer, SegmentContainer } from "../ControlsGrid";
import MagnifyingGlassIcon from "../assets/find-in-viz.svg";
import FindInVizDropdown from "./FindInVizDropdown";
import ClickAwayListener from "react-click-away-listener";
import { useLocation } from "react-router-dom";
import { VizType } from "../../../visualization/Utils";
import { googleAnalyticsCustomEvent } from "../../../sharedUtilities/googleAnalyticsEvent";

const FindInViz = ({
  findInVizOptions,
  highlightedItem,
  setHighlightedItem,
}) => {
  const location = useLocation();
  const currentVizType = location.pathname.split("/")[2] as any;
  const isTable = location.pathname.split("/")[3] === "table";
  const [hovered, setHovered] = useState(false);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (open) {
      googleAnalyticsCustomEvent("menu", "open", "findinviz", "explore");
    }
  }, [open]);
  if (
    currentVizType === VizType.Geo ||
    currentVizType === VizType.MarketShare ||
    isTable
  ) {
    return <div style={{ width: "100px" }} />;
  }
  return (
    <SegmentContainer style={{ width: "220px" }}>
      <ClickAwayListener
        onClickAway={() => {
          setHovered(false);
          setOpen(false);
        }}
      >
        <div onMouseOut={() => !open && setHovered(false)}>
          {!hovered && !open && (
            <IconContainer
              onClick={() => !open && setOpen(true)}
              onMouseEnter={() => {
                if (!hovered) {
                  setHovered(true);
                }
              }}
            >
              <SettingsLabelContainer>
                <img src={MagnifyingGlassIcon} /> Find in Viz
              </SettingsLabelContainer>
            </IconContainer>
          )}
          {(hovered || open) && (
            <div onClick={() => !open && setOpen(true)}>
              <FindInVizDropdown
                findInVizOptions={findInVizOptions}
                currentHighlightedItem={highlightedItem}
                setHighlightedItem={setHighlightedItem}
                setHovered={setHovered}
                setOpen={setOpen}
              />
            </div>
          )}
        </div>
      </ClickAwayListener>
    </SegmentContainer>
  );
};
export default memo(FindInViz);
