import React from "react";
import { Chip } from "@mui/material";

export interface DataChipStyles {
  color: string;
  backgroundColor: string;
  stroke?: string;
}

export interface DataChipProps {
  label: string;
  chipType: "dataType" | "classification";
  size?: "small" | "medium";
  onClick?: () => void;
}

const defaultStyle: DataChipStyles = {
  color: "#666666",
  backgroundColor: "#F5F5F5",
};

export const formatDataTypeLabel = (type: string): string => {
  const specialCases: Record<string, string> = {
    product_space: "Product Space",
    bilateral: "Bilateral Trade",
    unilateral: "Unilateral Trade",
    product: "Product Trade",
    rankings: "Rankings",
    classification: "Classifications",
  };

  if (specialCases[type.toLowerCase()]) {
    return specialCases[type.toLowerCase()];
  }

  return type
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const getDataTypeStyle = (type: string): DataChipStyles => {
  const normalizedType = type.toLowerCase();

  const styles: Record<string, DataChipStyles> = {
    rankings: {
      color: "#366104",
      backgroundColor: "#ECF8D9",
    },
    bilateral: {
      color: "#891D49",
      backgroundColor: "#FFE5EC",
    },
    unilateral: {
      color: "#095582",
      backgroundColor: "#EBF9FE",
    },
    product: {
      color: "#6212A5",
      backgroundColor: "#F2E8FF",
    },
    classification: {
      color: "#796138",
      backgroundColor: "#F2EEC2",
    },
    product_space: {
      color: "#387879",
      backgroundColor: "#C2F2EF",
    },
    "product repo": {
      color: "#796138",
      backgroundColor: "#F2E8C2",
    },
  };

  return styles[normalizedType] || defaultStyle;
};

export const getClassificationStyle = (type: string): DataChipStyles => {
  const styles: Record<string, DataChipStyles> = {
    HS92: {
      color: "#F1C59F",
      backgroundColor: "#FFF3E0",
      stroke: "#F1C59F",
    },
    HS12: {
      color: "#F1A29F",
      backgroundColor: "#FFEBEE",
      stroke: "#F1A29F",
    },
    SITC: {
      color: "#7DDDE4",
      backgroundColor: "#E0F7FA",
      stroke: "#7DDDE4",
    },
    "Unilateral Services": {
      color: "#8FD096",
      backgroundColor: "#E8F5E9",
      stroke: "#8FD096",
    },
    "Services Unilateral": {
      color: "#8FD096",
      backgroundColor: "#E8F5E9",
      stroke: "#8FD096",
    },
    Services: {
      color: "#8FD096",
      backgroundColor: "#E8F5E9",
      stroke: "#8FD096",
    },
  };
  return styles[type] || defaultStyle;
};

const DataChip: React.FC<DataChipProps> = ({
  label,
  chipType,
  size = "small",
  onClick,
}) => {
  const getStyle = () => {
    if (chipType === "dataType") {
      return getDataTypeStyle(label);
    } else {
      return getClassificationStyle(label);
    }
  };

  const style = getStyle();

  // Format the display label for data types
  const displayLabel =
    chipType === "dataType" ? formatDataTypeLabel(label) : label;

  if (chipType === "classification") {
    const Label = (
      <span>
        <svg height="10" width="10" style={{ paddingRight: "4px" }}>
          <rect
            height="100%"
            width="100%"
            fill={style.stroke || "#A7A7A7"}
            rx="2"
          />
        </svg>
        {displayLabel}
      </span>
    );

    return (
      <Chip
        label={Label}
        size={size}
        variant="outlined"
        style={{
          borderRadius: "4px",
          backgroundColor: "transparent",
          border: `1px solid "#A7A7A7"`,
          fontWeight: 600,
        }}
        onClick={onClick}
      />
    );
  }

  return (
    <Chip
      label={displayLabel}
      size={size}
      style={{
        padding: "4px 8px",
        borderRadius: "4px",
        fontWeight: 600,
        fontSize: "14px",
        ...style,
      }}
      onClick={onClick}
    />
  );
};

export default DataChip;
