import {
  Details as DetailsBase,
  DetailsSummary as QuestionHeading,
  DetailsContent as AnswerToQuestion,
  ToggleIcon,
} from "../sharedComponents/staticPageComponents";
import { ReactNode, useState, useRef, cloneElement } from "react";

interface DetailsProps {
  children: ReactNode[];
}

const Details = ({ children }: DetailsProps) => {
  let [isOpen, setIsOpen] = useState<boolean>(false);
  const detailsElement = useRef<HTMLDetailsElement | null>(null);

  const handleToggle = (e: any) => {
    if (detailsElement && detailsElement.current) {
      const detailsElementOpen = detailsElement.current.open;
      setIsOpen(detailsElementOpen);
    }
  };

  let ChildrenWithToggleIcon: any | null;
  if (children) {
    ChildrenWithToggleIcon = children.map((child: any) => {
      if (child.type === QuestionHeading) {
        return cloneElement(
          child,
          {},
          child.props.children,
          <ToggleIcon isOpen={isOpen} />,
        );
      } else {
        return child;
      }
    });
  }

  return (
    <DetailsBase onToggle={handleToggle} ref={detailsElement}>
      {ChildrenWithToggleIcon}
    </DetailsBase>
  );
};

const FAQContent = () => {
  return (
    <>
      <Details>
        <QuestionHeading>
          What is The Atlas of Economic Complexity?
        </QuestionHeading>
        <AnswerToQuestion>
          <p>
            The Atlas is an interactive research tool that allows people to
            learn more about the economic structure of countries and regions. It
            visualizes what countries produce and export, revealing their
            economic strengths and potential opportunities for growth.
          </p>
          <p>
            The Atlas puts the capabilities and know-how of a country at the
            heart of its growth prospects, where the diversity and complexity of
            existing capabilities heavily influence how growth happens.
          </p>
        </AnswerToQuestion>
      </Details>
      <Details>
        <QuestionHeading>
          Where can I learn more about the ideas and methodologies that power
          the Atlas?
        </QuestionHeading>
        <AnswerToQuestion>
          <p>
            More detail on our ideas and methodology can be found in the
            companion book,{" "}
            <a
              href="https://growthlab.cid.harvard.edu/publications/atlas-economic-complexity-mapping-paths-prosperity-0"
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong>
                The Atlas of Economic Complexity: Mapping Paths to Prosperity
              </strong>
            </a>
            . The first part contains context, motivating questions and a
            detailed description of the methodology.
          </p>
          <p>
            You can also refer to our research publications on the product space
            and our theory of economic growth based on these ideas:
          </p>
          <ul style={{ color: "#666666" }}>
            <li>
              <em>
                <a
                  href="http://www.pnas.org/content/106/26/10570.full"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  The Building Blocks of Economic Complexity
                </a>
              </em>
            </li>
            <li>
              <em>
                <a
                  href="http://science.sciencemag.org/content/317/5837/482"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  The Product Space Conditions the Development of Nations
                </a>
              </em>
            </li>
            <li>
              <em>
                <a
                  href="https://link.springer.com/article/10.1007/s10887-011-9071-4"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  The Network Structure of Economic Output
                </a>
              </em>
            </li>
          </ul>
          <p>
            The{" "}
            <strong>
              <a href="/glossary/" target="_blank" rel="noopener noreferrer">
                Atlas Glossary
              </a>
            </strong>{" "}
            also provides detailed definitions of many terms found in the Atlas.
          </p>
        </AnswerToQuestion>
      </Details>
      <Details>
        <QuestionHeading>What are the Atlas data sources?</QuestionHeading>
        <AnswerToQuestion>
          <p>
            Atlas is compiled mainly from third-party international sources:
          </p>
          <ul style={{ color: "#666666" }}>
            <li>
              <b>Goods Trade:</b> United Nations Statistical Division (UN
              Comtrade)
            </li>
            <li>
              <b>Services Trade:</b> International Monetary Fund (IMF) Direction
              of Trade Statistics
            </li>
            <li>
              <b>Economic Indicators:</b> International Monetary Fund (IMF)
            </li>
            <li>
              <b>Price Adjustments:</b> Federal Reserve Economic Data (FRED)
              Producer Price Index for Industrial Commodities
            </li>
          </ul>
          <p>
            All trade values are reported in US dollars (USD) and adjusted using
            the FRED Producer Price Index for Industrial Commodities. The base
            year matches the current Atlas data year, allowing users to
            understand values in current purchasing power.
          </p>
        </AnswerToQuestion>
      </Details>
      <Details>
        <QuestionHeading>
          Why doesn't the Atlas data match Comtrade exactly?
        </QuestionHeading>
        <AnswerToQuestion>
          <p>
            International trade flows are theoretically recorded twice: as
            exports from the origin country and as imports by the destination
            country. However, in practice, trade values are recorded
            imperfectly, reported inconsistently, or sometimes not reported at
            all. To address these challenges, we developed a three-step approach
            known as the Bustos-Yildirim Method:
          </p>
          <ol style={{ color: "#666666" }}>
            <li>
              We first correct import values, which are reported including the
              costs of freight and insurance (CIF), to enable comparison with
              exporter-reported values that are free on board (FOB).
            </li>
            <li>
              We estimate country reliability indices based on the consistency
              of reported trade totals across all exporter and importer
              combinations over time.
            </li>
            <li>
              Using data from both exporters and importers, we generate our
              final trade value estimates by weighting each country's reported
              data according to their reliability scores.
            </li>
          </ol>
        </AnswerToQuestion>
      </Details>
      <Details>
        <QuestionHeading>
          What is the difference between the HS and SITC classifications?
        </QuestionHeading>
        <AnswerToQuestion>
          <p>
            The difference is a tradeoff between time and detail. The SITC data
            goes back further in time, from 1962 onward, but does not reflect
            new products that did not exist in 1962 (e.g., cell phones). The HS
            data is only available back to 1995 but provides a more product set.
            This is especially important for products introduced in the last two
            decades, such as high-tech electronic goods.
          </p>
        </AnswerToQuestion>
      </Details>
      <Details>
        <QuestionHeading>How often is the Atlas data updated?</QuestionHeading>
        <AnswerToQuestion>
          <p>
            We update approximately 95% of Atlas data once per year, typically
            between April and June. This timing is driven by country reporting
            patterns to UN Comtrade, which can take 12-18 months to reach
            sufficient coverage for a comprehensive update. Throughout the year,
            we release:
          </p>
          <ul style={{ color: "#666666" }}>
            <li>
              Quarterly revisions as countries submit additional trade data
            </li>
            <li>
              Occasional updates to reflect improvements in our data cleaning
              processes
            </li>
            <li>Minor corrections when necessary</li>
          </ul>
          <p>
            To stay informed about real-time data availability by country,
            please visit the{" "}
            <strong>
              <a
                href="https://comtrade.un.org/data/da"
                target="_blank"
                rel="noopener noreferrer"
              >
                UN Comtrade Data Availability Dashboard
              </a>
            </strong>
            .
          </p>
        </AnswerToQuestion>
      </Details>
      <Details>
        <QuestionHeading>
          Why are certain countries named and classified the way they are?
        </QuestionHeading>
        <AnswerToQuestion>
          <p>
            We use the official country names and data provided to UN Comtrade.
          </p>
        </AnswerToQuestion>
      </Details>
      <Details>
        <QuestionHeading>
          Why are some countries missing from country profiles and the
          complexity ranking?
        </QuestionHeading>
        <AnswerToQuestion>
          <p>
            Our coverage for country profiles and complexity rankings require
            that countries:
          </p>
          <ul style={{ color: "#666666" }}>
            <li>Have a minimum population of 1 million</li>
            <li>Maintain average annual trade volume exceeding $1 billion</li>
            <li>Provide reliable GDP and export data</li>
            <li>
              Demonstrate consistent reporting of reliable trade statistics
            </li>
          </ul>
        </AnswerToQuestion>
      </Details>
      <Details>
        <QuestionHeading>
          Can I reuse or reprint Atlas data and visualizations?
        </QuestionHeading>
        <AnswerToQuestion>
          <p>
            The Atlas of Economic Complexity is a freely available public
            resource designed to advance the understanding of inclusive economic
            growth. We actively encourage the use of Atlas data, visualizations,
            and concepts for research, analysis, and collaboration while
            emphasizing the importance of proper attribution.
          </p>
          <p>
            Please refer to our{" "}
            <strong>
              <a
                href="/data-downloads"
                target="_blank"
                rel="noopener noreferrer"
              >
                Usage and Citation Guidelines
              </a>
            </strong>{" "}
            for specific citation requirements for the platform, datasets, and
            related publications.
          </p>
        </AnswerToQuestion>
      </Details>
      <Details>
        <QuestionHeading>
          I'm a journalist/media representative interested in learning more
          about the Atlas. Who should I contact?
        </QuestionHeading>
        <AnswerToQuestion>
          <p>
            For media inquiries, interviews, or assistance with Atlas resources
            for press coverage, please contact the Growth Lab team at{" "}
            <a
              href="mailto:growthlab@hks.harvard.edu"
              target="_blank"
              rel="noopener noreferrer"
            >
              growthlab@hks.harvard.edu
            </a>
            .
          </p>
        </AnswerToQuestion>
      </Details>
    </>
  );
};

export default FAQContent;
