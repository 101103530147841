import { gql } from "@apollo/client";

const query = gql`
  query GetDownloadsData {
    downloadsTable {
      tableId
      tableName
      tableDataType
      repo
      complexityData
      productLevel
      facet
      yearMin
      yearMax
      displayName
      productClassificationHs92
      productClassificationHs12
      productClassificationSitc
      productClassificationServicesUnilateral
      dvFileId
      dvFileName
      dvFileSize
      dvPublicationDate
      doi
      columns {
        columnId
        columnName
        complexity
        description
        decimalPlaces
        dataType
        notes
      }
    }
  }
`;

export default query;
