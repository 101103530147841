import {
  useRef,
  useState,
  useEffect,
  useLayoutEffect,
  memo,
  useCallback,
  useMemo,
} from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import WorldGeojsonRaw from "./world_map_naturalearth_ashkyd_clean_geo.json";
import { FeatureCollection } from "geojson";
import styled from "@emotion/styled";
import useFetchMetadata, {
  MetadataFetchType,
  MetadataFetchStatus,
} from "../../../sharedUtilities/useFetchMetadata";
import {
  UIView,
  TradeDirection,
  computeTotalSumByTradeFlow,
  getTradeDirectionSelector,
} from "../../Utils";
import transformLocations from "./transformLocations";
import { primaryCountryColor } from "./Utils";
import { getReferenceLocation } from "./Utils";
import ZoomControls from "../growth/ZoomControls";
import { useDownload } from "../../../visualization/DownloadContext";

const MapContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const SelectedLocationContainer = styled.div`
  position: absolute;
  bottom: 25px;
  right: 0px;
  padding: 0px 5px;
  margin-right: 5px;
  font-size: 0.9rem;
  background-color: rgba(255, 255, 255, 0.5);
  &::before {
    display: inline-block;
    margin-right: 5px;
    width: 10px;
    height: 10px;
    border: 1px solid #ffffff;
    background-color: ${primaryCountryColor};
    content: "";
  }
`;

const Root = styled.div`
  width: 100%;
  height: 100%;
`;

const determineIfTradeValueIsNonZero = ({
  tradeDirection,
  exportValue,
  importValue,
}: {
  tradeDirection: TradeDirection;
  exportValue: number;
  importValue: number;
}) => {
  let valueToDisplayIsNonZero;
  if (tradeDirection === TradeDirection.Exports && exportValue > 0) {
    valueToDisplayIsNonZero = true;
  } else if (tradeDirection === TradeDirection.Imports && importValue > 0) {
    valueToDisplayIsNonZero = true;
  } else {
    valueToDisplayIsNonZero = false;
  }

  return valueToDisplayIsNonZero;
};

const Chart = ({
  inputData,
  queryVariables,
  handleTooltip,
  setTotalValue,
  tradeDirection,
  tradeFlow,
}: any) => {
  const { exporter, importer, productId, year, view } = queryVariables;
  const { canvasRef, dataRef } = useDownload();

  const mapContainer = useRef<HTMLDivElement | null>(null);
  const mapInstance = useRef<any>(null);
  const [mapData, setMapData] = useState<any>(null);

  const [referenceLocation, setReferenceLocation] = useState<string>(
    getReferenceLocation({ exporter, importer }),
  );

  useEffect(() => {
    let newReferenceLocation = getReferenceLocation({ exporter, importer });

    if (newReferenceLocation !== referenceLocation) {
      setReferenceLocation(newReferenceLocation);
    }
  }, [exporter, importer, referenceLocation]);

  const worldGeojson = WorldGeojsonRaw as FeatureCollection;

  let tradeDirectionSelector: string = getTradeDirectionSelector({
    tradeDirection,
  });

  const {
    metadataStatus: locationMetadataStatus,
    metadata: locationMetadata,
    error: locationError,
  } = useFetchMetadata({ metadataFetchType: MetadataFetchType.Location });

  useEffect(() => {
    let totalSum: number | undefined = undefined;

    if (view === UIView.Markets) {
      let locations: any | undefined;
      if (locationMetadataStatus === MetadataFetchStatus.Success) {
        locations = locationMetadata;
      } else {
        locations = undefined;
      }

      if (locations) {
        const { regions, subregions, countries } = locations;

        totalSum = computeTotalSumByTradeFlow({
          data: inputData,
          tradeFlow,
          tradeDirection,
        });
        const dataWithParents: any = transformLocations({
          geojson: worldGeojson,
          tradeDirection,
          tradeFlow,
          view,
          data: inputData,
          year,
          location: referenceLocation,
          regions,
          subregions,
          countries,
        });
        setMapData(dataWithParents);
      } else {
        setMapData({
          type: "FeatureCollection",
          features: [],
        });
      }
    }
    // Update the total value for display
    if (totalSum) {
      setTotalValue(totalSum);
    } else {
      setTotalValue(null);
    }
  }, [
    referenceLocation,
    productId,
    locationMetadataStatus,
    tradeFlow,
    view,
    setTotalValue,
    locationMetadata,
    inputData,
    tradeDirection,
    worldGeojson,
    year,
  ]);

  // TODO: use environment variable
  mapboxgl.accessToken =
    "pk.eyJ1IjoiaGFydmFyZGdyb3d0aGxhYiIsImEiOiJja2JpMWpoN2wwYXc3MnRudm02ZnZwbWJnIn0.QnDwcoWQV-zC8CfXJz_lMw";
  const totalSum = useMemo(
    () =>
      computeTotalSumByTradeFlow({
        data: inputData,
        tradeFlow,
        tradeDirection,
      }),
    [inputData, tradeFlow, tradeDirection],
  );
  useEffect(() => {
    if (totalSum) setTotalValue(totalSum);
  }, [
    inputData,
    setTotalValue,
    totalSum,
    tradeDirection,
    tradeDirectionSelector,
    tradeFlow,
  ]);

  const generateMouseMoveFunction = useCallback(
    ({ tradeDirection, map }: { tradeDirection: TradeDirection; map: any }) => {
      return (e: any) => {
        let feature = map.queryRenderedFeatures(e.point)[0];
        if (feature && feature.source === "countries") {
          const { countryId } = feature.properties as any;

          handleTooltip(feature);
          if (countryId) {
            hoveredPolygonId = countryId;
          } else {
            hoveredPolygonId = "";
          }
        } else {
          handleTooltip(undefined);

          hoveredPolygonId = "";
        }

        map.setFilter("hoveredCountry", [
          "==",
          ["get", "countryId"],
          hoveredPolygonId,
        ]);
      };
    },
    [handleTooltip],
  );

  let hoveredPolygonId: string = "";

  useLayoutEffect(() => {
    if (mapContainer.current && mapData !== null) {
      if (!mapInstance.current) {
        const map = new mapboxgl.Map({
          container: mapContainer.current,
          style: "mapbox://styles/harvardgrowthlab/ckbihvcbv1jg51hmulv16nf5r",
          renderWorldCopies: false,
          center: [0, 20], // Centered slightly north of the equator
          zoom: 0, // A more zoomed-out view to show most of the world
          maxBounds: [
            [-180, -85], // Southwest coordinates
            [180, 85], // Northeast coordinates
          ],
          pitchWithRotate: false,
          dragRotate: false,
          preserveDrawingBuffer: true,
        });

        map.on("load", () => {
          map.addSource("countries", {
            type: "geojson",
            data: mapData,
          });

          map.addLayer({
            id: "countries",
            type: "fill",
            source: "countries", // reference the data source
            paint: {
              "fill-outline-color": ["get", "strokeColor"],
            },
          });

          // This line layer is being added to control
          // the outline stroke effect for countries on hover,
          // since Mapbox does not allow control of outline stroke
          // for fill features
          map.addLayer({
            id: "hoveredCountry",
            type: "line",
            source: "countries", // reference the data source
            paint: {
              "line-color": "#ffffff",
              "line-width": 1,
            },
            filter: ["==", ["get", "countryId"], hoveredPolygonId],
          });

          if (tradeDirection === TradeDirection.Exports) {
            map.setPaintProperty("countries", "fill-color", [
              "get",
              "exportsColor",
            ]);
          } else if (tradeDirection === TradeDirection.Imports) {
            map.setPaintProperty("countries", "fill-color", [
              "get",
              "importsColor",
            ]);
          }

          // See example on how setFeatureState is being used on hover: https://docs.mapbox.com/mapbox-gl-js/example/hover-styles/
          let handleMouseMove = generateMouseMoveFunction({
            tradeDirection,
            map,
          });
          map.on("mousemove", handleMouseMove);

          mapInstance.current = map;
          canvasRef.current = map.getCanvas();
        });
      } else {
        // Update the map data if it already exists
        const map = mapInstance.current;
        const source = map.getSource("countries") as mapboxgl.GeoJSONSource;
        if (source) {
          source.setData(mapData);
        }
      }
    }
  }, [
    mapData,
    mapContainer,
    hoveredPolygonId,
    tradeDirection,
    generateMouseMoveFunction,
    canvasRef,
  ]);

  useEffect(() => {
    dataRef.current = mapData?.features
      .filter((d) => d.properties.exportValue || d.properties.importValue)
      .map((d: any) => {
        const value =
          d.properties[
            tradeDirection === TradeDirection.Exports
              ? "exportValue"
              : "importValue"
          ];
        return {
          Name: d.properties.name,
          Year: year,
          [tradeDirection === TradeDirection.Exports ? "Exports" : "Imports"]:
            value,
          Share: (value / totalSum) * 100,
        };
      });
  }, [dataRef, mapData, tradeDirection, year, totalSum]);

  //clean up map
  useEffect(() => {
    return () => {
      if (mapInstance.current) {
        mapInstance.current.remove();
      }
    };
  }, []);

  useEffect(() => {
    if (mapInstance.current) {
      const map = mapInstance.current;
      if (tradeDirection === TradeDirection.Exports) {
        map.setPaintProperty("countries", "fill-color", [
          "get",
          "exportsColor",
        ]);
      } else if (tradeDirection === TradeDirection.Imports) {
        map.setPaintProperty("countries", "fill-color", [
          "get",
          "importsColor",
        ]);
      }

      // Update map mousemove function with new trade direction
      let handleMouseMove = generateMouseMoveFunction({ tradeDirection, map });
      map.on("mousemove", handleMouseMove);
    }
  }, [generateMouseMoveFunction, tradeDirection, tradeFlow]);

  const handleZoomIn = () => {
    mapInstance.current.zoomIn();
  };

  const handleZoomOut = () => {
    mapInstance.current.zoomOut();
  };

  const handleResetZoom = () => {
    mapInstance.current.setZoom(0); // or any default zoom level
  };

  return (
    <MapContainer>
      <ZoomControls
        zoom={{
          scale: ({ scaleX, scaleY }) => {
            if (scaleX > 1) {
              handleZoomIn();
            } else {
              handleZoomOut();
            }
          },
          reset: handleResetZoom,
        }}
      />
      <Root ref={mapContainer} />
      <SelectedLocationContainer>Selected Location</SelectedLocationContainer>
    </MapContainer>
  );
};

const arePropsEqual = (_, { isLoading }) => {
  return isLoading;
};

export default memo(Chart, arePropsEqual);
