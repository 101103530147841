import { gql, useQuery } from "@apollo/client";
import { index, stratify } from "d3";
import { getCategoriesForChartLegend, getColorMap } from "../../Utils";
import { ProductMetadataFragment } from "../../../graphql/queries/getHs12ProductsMetadata";
import { useMemo, useState } from "react";
import {
  VisualizationBottomRowContainer,
  VisualizationContentContainer,
  VisualizationHandlesContainer,
} from "../../VizGrid";
import useFetchMetadata, {
  MetadataFetchType,
} from "../../../sharedUtilities/useFetchMetadata";
import Scatterplot from "./Scatterplot";
import ProductsTable from "./ProductsTable";
import CategoryLabels from "../../components/CategoryLabels";
import { CategoryDatum } from "../../components/LegendLabel";
import { useLocation } from "react-router-dom";
import { usePageQueryParams } from "../../defaultSettings";

export const query = gql`
  query CPY_growth(
    $productClass: ProductClass!
    $productLevel: Int!
    $countryId: Int
    $productId: Int
    $yearMin: Int
    $yearMax: Int
    $hs12: Boolean!
    $hs92: Boolean!
    $sitc: Boolean!
  ) {
    countryProductYear(
      productClass: $productClass
      productLevel: $productLevel
      countryId: $countryId
      productId: $productId
      yearMin: $yearMin
      yearMax: $yearMax
    ) {
      countryId
      locationLevel
      productId
      productLevel
      year
      exportValue
      importValue
      exportRca
      isNew
      productStatus
      cog
      distance
      normalizedPci
      normalizedCog
      normalizedDistance
    }
    productYear(
      productClass: $productClass
      productLevel: $productLevel
      yearMin: $yearMin
      yearMax: $yearMax
    ) {
      productId
      year
      pci
      exportValue
      importValue
      exportValueGrowth5: exportValueConstGrowth5
    }
    metadata_12: productHs12 @include(if: $hs12) {
      ...ProductMetadata
    }
    metadata_92: productHs92 @include(if: $hs92) {
      ...ProductMetadata
    }
    metadata_sitc: productSitc @include(if: $sitc) {
      ...ProductMetadata
    }
    countryYear: countryYear(
      countryId: $countryId
      yearMin: $yearMin
      yearMax: $yearMax
    ) {
      countryId
      exportValue
      eci
    }
    countryYearThresholds(
      countryId: $countryId
      yearMin: $yearMin
      yearMax: $yearMax
      productClass: $productClass
    ) {
      variable
      min
      percentile10
      percentile20
      percentile30
      percentile40
      percentile50
      percentile60
      percentile70
      percentile80
      percentile90
      max
    }
  }
  ${ProductMetadataFragment}
`;

const GrowthPotential = () => {
  const location = useLocation();
  const [{ productClass, productLevel, view, exporter, year }] =
    usePageQueryParams();
  const showTable = location.pathname.split("/").slice(-1)[0] === "table";

  let categoriesForLegend: CategoryDatum[] = useMemo(
    () =>
      getCategoriesForChartLegend({
        view,
        productClass,
      }).filter((d) => d.id.split("-")[2] !== "14"),
    [view, productClass],
  );
  const [hiddenCategories, setHiddenCategories] = useState<string[]>([]);
  const { loading, error, previousData, data } = useQuery(query, {
    variables: {
      productClass,
      countryId: exporter?.split("-")[1],
      productLevel,
      yearMin: year,
      yearMax: year,
      hs12: productClass === "HS12",
      hs92: productClass === "HS92",
      sitc: productClass === "SITC",
    },
  });

  const displayData = data ? data : previousData;
  const {
    countryProductYear = [],
    productYear = [],
    countryYear: [countryData] = [],
    countryYearThresholds = [],
  } = displayData || {};

  const {
    metadataStatus: countryMetadataStatus,
    metadata: countryMetadata,
    error: countryMetadataError,
  } = useFetchMetadata({ metadataFetchType: MetadataFetchType.Country });
  const countryLookup = useMemo(() => {
    if (countryMetadata) {
      return index(countryMetadata, (d: any) => d.countryId);
    } else {
      return new Map();
    }
  }, [countryMetadata]) as any;
  const metaDataTree = useMemo(() => {
    if (!displayData) {
      return stratify()([{ id: "root" }]);
    }
    const metaData =
      Object.entries(displayData).find(([k, v]) =>
        k.includes("metadata"),
      )?.[1] || [];

    const tree = stratify()
      .id((d: any) => d.productId)
      .parentId((d: any) => {
        if (d.productLevel === 1) {
          return "root";
        } else if (d?.parent) {
          return d?.parent?.productId;
        } else {
          return d?.topParent?.productId;
        }
      })([{ productId: "root", parent: null }, ...(metaData as Array<any>)]);

    return tree;
  }, [displayData]);
  const metaDataLookup = useMemo(
    () => index(metaDataTree.descendants(), (d) => d.id),
    [metaDataTree],
  ) as any;

  let colorMap = useMemo(
    () => getColorMap({ view, productClass }),
    [view, productClass],
  );
  return (
    <>
      <VisualizationContentContainer>
        {!showTable && (
          <Scatterplot
            displayData={displayData}
            countryProductYear={countryProductYear}
            productYear={productYear}
            loading={loading}
            countryData={countryData}
            metaDataLookup={metaDataLookup}
            metaDataTree={metaDataTree}
            colorMap={colorMap}
            hiddenCategories={hiddenCategories}
          />
        )}

        {showTable && (
          <ProductsTable
            displayData={displayData}
            countryProductYear={countryProductYear}
            productYear={productYear}
            loading={loading}
            countryData={countryData}
            metaDataLookup={metaDataLookup}
            metaDataTree={metaDataTree}
            colorMap={colorMap}
            countryLookup={countryLookup}
            hiddenCategories={hiddenCategories}
            countryYearThresholds={countryYearThresholds}
          />
        )}
      </VisualizationContentContainer>
      <VisualizationBottomRowContainer>
        <VisualizationHandlesContainer>
          <CategoryLabels
            categories={categoriesForLegend}
            allowToggle
            hiddenCategories={hiddenCategories}
            setHiddenCategories={setHiddenCategories}
            resetText="Show All Sectors"
            fullWidth={true}
          />
        </VisualizationHandlesContainer>
      </VisualizationBottomRowContainer>
    </>
  );
};
export default GrowthPotential;
