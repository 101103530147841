import { useState, useMemo, useCallback, useEffect } from "react";
import { Box, Container, CircularProgress, Alert } from "@mui/material";
import { useQuery } from "@apollo/client";
import DownloadsTable from "../../components/data/DownloadsTable";
import DatasetModal from "../../components/data/DatasetModal";
import downloadsQuery from "../../graphql/queries/getDownloadsData";
import DataSourcesSection from "./DataSourcesSection";
import { H1, Paragraph } from "../../components/styling/TextStyling";

const DataPage = () => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedDataset, setSelectedDataset] = useState<any>(null);
  const [filters, setFilters] = useState<any[]>([]);
  const { loading, error, data: downloadsData } = useQuery(downloadsQuery);

  const data = useMemo(() => {
    if (!downloadsData?.downloadsTable) return [];

    const apiData = downloadsData.downloadsTable;

    return apiData.map((item: any) => {
      // Extract product classifications
      const classifications = [
        item.productClassificationHs92 && "HS92",
        item.productClassificationHs12 && "HS12",
        item.productClassificationSitc && "SITC",
        item.productClassificationServicesUnilateral && "Services Unilateral",
      ].filter(Boolean);

      const hasComplexityData = item.complexityData === true;
      let dataType = item.tableDataType;
      if (!dataType && item.repo) {
        const repoToDataType: Record<string, string> = {
          bilateral: "bilateral",
          unilateral: "unilateral",
          product: "product",
          product_space: "product_space",
          rankings: "rankings",
          classification: "classification",
        };

        dataType =
          repoToDataType[item.repo.toLowerCase()] || item.repo.toLowerCase();
      }

      dataType = dataType || "Other";

      const productLevel = item.productLevel || "N/A";

      return {
        originalData: item,

        "Display Name": item.displayName,
        "Data Type (tag)": dataType,
        "Classification (tag)": classifications.join("/"),
        "Complexity? (tag)": hasComplexityData ? "Yes" : "No",
        "Product Level": productLevel,
        "Year Min": item.yearMin,
        "Year Max": item.yearMax,
        "DV File Name": item.dvFileName,
        "DV File Size": item.dvFileSize,
        "DV Publication Date": item.dvPublicationDate,
        DOI: item.doi,
      };
    });
  }, [downloadsData]);

  const filterOptions = useMemo(() => {
    if (data.length === 0) {
      return {
        dataTypes: [] as string[],
        classifications: [] as string[],
        productLevels: [] as string[],
      };
    }

    return {
      dataTypes: Array.from(
        new Set(data.map((row: any) => row["Data Type (tag)"])),
      )
        .filter(Boolean)
        .sort() as string[],
      classifications: Array.from(
        new Set(
          data.flatMap((row: any) =>
            (row["Classification (tag)"] || "").split("/"),
          ),
        ),
      )
        .filter(Boolean)
        .sort() as string[],
      productLevels: Array.from(
        new Set(data.map((row: any) => row["Product Level"])),
      )
        .filter(Boolean)
        .filter((d: any) => d !== "N/A")
        .sort() as string[],
    };
  }, [data]);

  const handleColumnFiltersChange = useCallback((newFilters: any[]) => {
    setFilters(newFilters);
  }, []);

  const handleOpenModal = (row: any) => {
    setSelectedDataset(row);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: 0,
        }}
      >
        <Container
          sx={{
            mt: 0,
            pt: 0,
            display: "flex",
            flexDirection: "column",
            height: "100%",
            flexGrow: 1,
          }}
        >
          <H1 style={{ marginTop: "6px" }}>Atlas Data Downloads</H1>
          <Paragraph>
            The Atlas provides comprehensive international trade data covering
            over 6,000 products across 250 countries and territories. Below,
            explore and download curated datasets from the Atlas of Economic
            Complexity. Use the column filters to discover specific datasets.
          </Paragraph>
        </Container>

        {loading && (
          <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
            <CircularProgress />
          </Box>
        )}

        {error && (
          <Alert severity="error" sx={{ mb: 4 }}>
            Error loading data: {error.message}
          </Alert>
        )}

        {!loading && !error && data.length > 0 && (
          <DownloadsTable
            data={data}
            filterOptions={filterOptions}
            columnFilters={filters}
            onColumnFiltersChange={handleColumnFiltersChange}
            onOpenModal={handleOpenModal}
          />
        )}

        <DatasetModal
          open={openModal}
          onClose={handleCloseModal}
          dataset={selectedDataset}
        />
        <Container
          sx={{
            mt: 0,
            pt: 0,
            display: "flex",
            flexDirection: "column",
            height: "100%",
            flexGrow: 1,
          }}
        >
          <DataSourcesSection />
        </Container>
      </Box>
    </>
  );
};

export default DataPage;
