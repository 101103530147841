import styled from "@emotion/styled";
import { useLocation, useNavigate } from "react-router-dom";
import { ProductLevel } from "../../visualization/Utils";

const OptionButton = styled.button<any>`
  border: 1px solid black;
  margin: 0px;
  padding-left: 12px;
  padding-right: 12px;
  height: 34px;
  cursor: pointer;
  background-color: ${(props) => (props.active ? "#334C60" : "white")};
  color: ${(props) => (props.active ? "white" : "black")};
  &:hover {
    background-color: #2d2f48;
    color: white;
  }
  letter-spacing: 0.2px;
`;
const LeftButton = styled(OptionButton)`
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-right: 0px;
  margin-left: 20px;
`;

const RightButton = styled(OptionButton)`
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  border-left: 0px;
`;

const ShowTable = () => {
  const location = useLocation();
  const showTable = location.pathname.split("/").slice(-1)[0] === "table";
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);

  return (
    <div>
      <LeftButton
        onClick={() => navigate(`feasibility?${searchParams.toString()}`)}
        active={!showTable}
      >
        Graph View
      </LeftButton>
      <RightButton
        onClick={() => {
          searchParams.set("productLevel", `${ProductLevel.Product4digit}`);
          navigate(`feasibility/table?${searchParams.toString()}`);
        }}
        active={showTable}
      >
        Table View
      </RightButton>
    </div>
  );
};
export default ShowTable;
