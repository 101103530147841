import { Route, RouteWithChildren } from "./getRoutingHooks";
import { RouteID, AboutRouteID } from "./routes";
import { createBrowserRouter, Navigate, useLocation } from "react-router-dom";
import Landing from "../pages/landing/index";
import StaticPage from "../pages/index";
import VisApp from "../visualization/index";
import * as Sentry from "@sentry/react";
import ErrorPage from "../pages/ErrorPage";

import Placeholder from "../pages/Placeholder";
import About from "../pages/about/index";
import FAQ from "../pages/faq/index";
import Glossary from "../pages/glossary";
import Treemap from "../visualization/charts/treemap";
import Geomap from "../visualization/charts/geomap";
import OverTime from "../visualization/charts/overtime";
import MarketShare from "../visualization/charts/marketshare";
import GrowthPotential from "../visualization/charts/growth";
import ProductSpace from "../visualization/charts/productspace/index";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

import { Helmet } from "react-helmet-async";
import DataPage from "../pages/data";

const RedirectToTreemap = () => {
  const location = useLocation();
  return <Navigate to={`/explore/treemap${location.search}`} replace />;
};

export const routes: Array<Route | RouteWithChildren> = [
  {
    id: RouteID.Landing,
    path: "/",
    element: (
      <QueryParamProvider
        options={{ removeDefaultsFromUrl: true }}
        adapter={ReactRouter6Adapter}
      >
        <Landing />
      </QueryParamProvider>
    ),
    errorElement: <ErrorPage />,
  },
  {
    id: RouteID.Explore,
    path: "/explore",
    element: (
      <QueryParamProvider
        options={{ removeDefaultsFromUrl: true }}
        adapter={ReactRouter6Adapter}
      >
        <VisApp />
      </QueryParamProvider>
    ),
    children: [
      {
        index: true,
        element: <RedirectToTreemap />,
      },
      {
        id: RouteID.GeoMap,
        path: "/explore/geomap",
        element: (
          <>
            <Helmet>
              <meta property="og:image" content="/og/share_geo.png" />
            </Helmet>
            <Geomap />
          </>
        ),
      },
      {
        id: RouteID.OverTime,
        path: "/explore/overtime",
        element: (
          <>
            <Helmet>
              <meta property="og:image" content="/og/share_stack.png" />
            </Helmet>
            <OverTime />
          </>
        ),
      },
      {
        id: RouteID.MarketShare,
        path: "/explore/marketshare",
        element: (
          <>
            <Helmet>
              <meta property="og:image" content="/og/share_market.png" />
            </Helmet>
            <MarketShare />
          </>
        ),
      },
      {
        id: RouteID.ProductSpace,
        path: "/explore/productspace",
        element: (
          <>
            <Helmet>
              <meta property="og:image" content="/og/share_ps.png" />
            </Helmet>
            <ProductSpace />
          </>
        ),
      },
      {
        id: RouteID.Feasibility,
        path: "/explore/feasibility/*",
        element: (
          <>
            <Helmet>
              <meta property="og:image" content="/og/share_feasibility.png" />
            </Helmet>
            <GrowthPotential />
          </>
        ),
      },
      {
        id: RouteID.TreeMap,
        path: "/explore/treemap",
        element: (
          <>
            <Helmet>
              <meta property="og:image" content="/og/share_tree.png" />
            </Helmet>
            <Treemap />
          </>
        ),
      },
    ],
  },
  {
    id: RouteID.StaticPageEntry,
    path: "",
    element: (
      <QueryParamProvider
        options={{ removeDefaultsFromUrl: true }}
        adapter={ReactRouter6Adapter}
      >
        <StaticPage />
      </QueryParamProvider>
    ),
    children: [
      {
        id: RouteID.Learn,
        path: "/learn",
        element: <Placeholder />,
      },
      {
        id: RouteID.Glossary,
        path: "/glossary",
        element: <Glossary />,
      },
      {
        id: RouteID.FAQ,
        path: "/faq",
        element: <FAQ />,
      },
      {
        id: RouteID.DataDownloads,
        path: "/data-downloads",
        element: <DataPage />,
      },

      {
        id: RouteID.About,
        path: "/about",
        element: <About />,
      },
      {
        id: AboutRouteID.WhatIsTheAtlas,
        path: "/about#what-is-the-atlas",
        element: <About />,
      },
      {
        id: AboutRouteID.MediaOutreach,
        path: "/about#media-outreach",
        element: <About />,
      },
      {
        id: AboutRouteID.OurTeam,
        path: "/about#our-team",
        element: <About />,
      },
      {
        id: AboutRouteID.GrowthLab,
        path: "/about#growth-lab",
        element: <About />,
      },
      {
        id: AboutRouteID.Contributors,
        path: "/about#contributors",
        element: <About />,
      },
      {
        id: AboutRouteID.SupportMission,
        path: "/about#support-mission",
        element: <About />,
      },
      {
        id: AboutRouteID.ContactUs,
        path: "/about#contact-us",
        element: <About />,
      },
    ],
  },
];

const recurseRouteChildren = (route: Route | RouteWithChildren) => {
  routesAsSet.add(route);

  if ("children" in route) {
    let children = route.children;
    children?.forEach((child) => recurseRouteChildren(child));
  }
};
export const routesAsSet: Set<Route | RouteWithChildren> = new Set();
routes.forEach((route) => recurseRouteChildren(route));

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter(routes);
