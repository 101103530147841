const NodeColorLegend = () => {
  return (
    <div>
      <div
        style={{
          fontWeight: "bold",
          fontSize: 11,
          marginBottom: 8,
          color: "#D1B656",
        }}
      >
        Node Color
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginBottom: 4,
        }}
      >
        <svg width={11} height={11} style={{ marginRight: "8px" }}>
          <path d="M 5.5,5.5 L 5.5,0.5 A 5,5 0 0,1 10.5,5.5 Z" fill="yellow" />
          <path d="M 5.5,5.5 L 10.5,5.5 A 5,5 0 0,1 5.5,10.5 Z" fill="green" />
          <path d="M 5.5,5.5 L 5.5,10.5 A 5,5 0 0,1 0.5,5.5 Z" fill="blue" />
          <path d="M 5.5,5.5 L 0.5,5.5 A 5,5 0 0,1 5.5,0.5 Z" fill="red" />
          <circle
            r={5}
            cx={5.5}
            cy={5.5}
            stroke="black"
            strokeWidth="0.5"
            fill="none"
          />
        </svg>
        <div style={{ fontSize: 11, color: "black" }}>
          Colored Nodes: High export
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <svg width={11} height={11} style={{ marginRight: "8px" }}>
          <circle
            fill="#E9E9E9"
            r={5}
            stroke="black"
            cx={5.5}
            cy={5.5}
            strokeWidth="0.5"
          />
        </svg>
        <div style={{ fontSize: 11, color: "black" }}>
          Grey Nodes: Low or no export
        </div>
      </div>
    </div>
  );
};

export default NodeColorLegend;
