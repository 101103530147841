import { ProductLevel, UIView } from "../visualization/Utils";
import { getNumericIdFromStringLocationId } from "../sharedUtilities/Utils";
import { gql } from "@apollo/client";
import { DocumentNode } from "@apollo/client";
import { worldGroupDatum } from "./queries/getLocationsMetadata";

interface EndpointFacetInputVariableProperties {
  countryId: string | undefined;
  partnerCountryId: string | undefined;
  groupId: string | undefined;
  partnerGroupId: string | undefined;
  productId: string | undefined;
  productLevel: ProductLevel | undefined;
}

interface QueryParamsInput {
  view: UIView;
  variables: EndpointFacetInputVariableProperties;
}

// This placeholder query will never execute;
// it is used to determine if an appropriate matching endpoint
// and query could not be determined from supplied query arguments
const placeholderQuery = gql`
  query PlaceHolder {
    locationCountry {
      countryId
    }
  }
`;

const numericWorldGroupId = getNumericIdFromStringLocationId(
  worldGroupDatum.groupId,
);

const determineEndpointFacet = ({ view, variables }: QueryParamsInput) => {
  let {
    countryId,
    partnerCountryId,
    groupId,
    partnerGroupId,
    productId,
    productLevel,
  } = variables;

  let queryToUse: DocumentNode = placeholderQuery;
  let skipQuery: boolean = false;

  if (view === UIView.Products) {
    if (
      countryId &&
      partnerCountryId === undefined &&
      groupId === undefined &&
      partnerGroupId === undefined &&
      productId === undefined
    ) {
      queryToUse = gql`
        query CPY(
          $productClass: ProductClass!
          $servicesClass: ServicesClass
          $productLevel: Int!
          $countryId: Int
          $productId: Int
          $yearMin: Int
          $yearMax: Int
        ) {
          data: countryProductYear(
            productClass: $productClass
            servicesClass: $servicesClass
            productLevel: $productLevel
            countryId: $countryId
            productId: $productId
            yearMin: $yearMin
            yearMax: $yearMax
          ) {
            countryId
            locationLevel
            productId
            productLevel
            year
            exportValue
            importValue
            exportRca
            isNew
            productStatus
            cog
            distance
          }
        }
      `;
    } else if (
      countryId &&
      partnerCountryId &&
      groupId === undefined &&
      partnerGroupId === undefined &&
      productId === undefined
    ) {
      // CCPY, what did `location1` export in `year`?
      queryToUse = gql`
        query CCPY(
          $productClass: ProductClass!
          $servicesClass: ServicesClass
          $productLevel: Int
          $countryId: Int
          $partnerCountryId: Int
          $productId: Int
          $yearMin: Int
          $yearMax: Int
        ) {
          data: countryCountryProductYear(
            productClass: $productClass
            servicesClass: $servicesClass
            productLevel: $productLevel
            countryId: $countryId
            partnerCountryId: $partnerCountryId
            productId: $productId
            yearMin: $yearMin
            yearMax: $yearMax
          ) {
            countryId
            locationLevel
            partnerCountryId
            partnerLevel
            productId
            productLevel
            year
            exportValue
            importValue
          }
        }
      `;
    } else if (
      countryId === undefined &&
      partnerCountryId === undefined &&
      groupId &&
      partnerGroupId &&
      (productId || productId === undefined)
    ) {
      // GGPY, what did `group1` export to `group2` in `year`?
      queryToUse = gql`
        query GGPY(
          $productClass: ProductClass!
          $servicesClass: ServicesClass
          $productLevel: Int
          $groupId: Int
          $partnerGroupId: Int
          $productId: Int
          $yearMin: Int
          $yearMax: Int
        ) {
          data: groupGroupProductYear(
            productClass: $productClass
            servicesClass: $servicesClass
            productLevel: $productLevel
            groupId: $groupId
            partnerGroupId: $partnerGroupId
            productId: $productId
            yearMin: $yearMin
            yearMax: $yearMax
          ) {
            groupId
            locationLevel
            partnerGroupId
            partnerType
            productId
            productLevel
            year
            exportValue
            importValue
          }
        }
      `;
    } else if (
      countryId &&
      partnerCountryId === undefined &&
      groupId === undefined &&
      partnerGroupId &&
      productId === undefined
    ) {
      queryToUse = gql`
        query CGPY(
          $countryId: Int
          $productClass: ProductClass!
          $servicesClass: ServicesClass
          $productLevel: Int
          $partnerGroupId: Int!
          $productId: Int
          $yearMin: Int
          $yearMax: Int
        ) {
          data: countryGroupProductYear(
            countryId: $countryId
            productClass: $productClass
            servicesClass: $servicesClass
            productLevel: $productLevel
            partnerGroupId: $partnerGroupId
            productId: $productId
            yearMin: $yearMin
            yearMax: $yearMax
          ) {
            countryId
            locationLevel
            partnerGroupId
            partnerLevel
            productId
            productLevel
            year
            exportValue
            importValue
          }
        }
      `;
    } else if (
      countryId === undefined &&
      partnerCountryId &&
      groupId &&
      partnerGroupId === undefined &&
      productId === undefined
    ) {
      queryToUse = gql`
        query GCPY(
          $groupId: Int!
          $productClass: ProductClass!
          $servicesClass: ServicesClass
          $productLevel: Int
          $partnerCountryId: Int
          $productId: Int
          $yearMin: Int
          $yearMax: Int
        ) {
          data: groupCountryProductYear(
            groupId: $groupId
            productClass: $productClass
            servicesClass: $servicesClass
            productLevel: $productLevel
            partnerCountryId: $partnerCountryId
            productId: $productId
            yearMin: $yearMin
            yearMax: $yearMax
          ) {
            groupId
            locationLevel
            partnerCountryId
            partnerLevel
            productId
            productLevel
            year
            exportValue
            importValue
          }
        }
      `;
    } else {
      skipQuery = true;
    }
  } else if (view === UIView.Markets) {
    if (
      countryId &&
      partnerCountryId === undefined &&
      groupId === undefined &&
      partnerGroupId === undefined &&
      productId === undefined
    ) {
      // CCPY, where did `location1` export (product?) in `year`?
      queryToUse = gql`
        query CCY(
          $countryId: Int
          $yearMin: Int
          $yearMax: Int
          $servicesClass: ServicesClass
          $productClass: ProductClass
        ) {
          data: countryCountryYear(
            countryId: $countryId
            yearMin: $yearMin
            yearMax: $yearMax
            servicesClass: $servicesClass
            productClass: $productClass
          ) {
            countryId
            partnerCountryId
            year
            exportValue
            importValue
          }
        }
      `;
    } else if (
      countryId &&
      partnerCountryId === undefined &&
      groupId === undefined &&
      partnerGroupId === undefined &&
      (productId || productId === undefined)
    ) {
      // CCPY, where did `location1` export (product?) in `year`?
      queryToUse = gql`
        query CCPY2(
          $productClass: ProductClass!
          $servicesClass: ServicesClass
          $productLevel: Int
          $countryId: Int
          $productId: Int
          $yearMin: Int
          $yearMax: Int
        ) {
          data: countryCountryProductYear(
            productClass: $productClass
            servicesClass: $servicesClass
            productLevel: $productLevel
            countryId: $countryId
            productId: $productId
            yearMin: $yearMin
            yearMax: $yearMax
          ) {
            countryId
            locationLevel
            partnerCountryId
            partnerLevel
            productId
            productLevel
            year
            exportValue
            importValue
          }
        }
      `;
    } else if (
      countryId === undefined &&
      partnerCountryId === undefined &&
      groupId === undefined &&
      parseFloat(partnerGroupId) === numericWorldGroupId &&
      productId
    ) {
      /*
                WHAT THIS IS USED FOR:
                - 'product' view for geomap
                */
      queryToUse = gql`
        query CGPY2(
          $productClass: ProductClass!
          $servicesClass: ServicesClass
          $productLevel: Int
          $partnerGroupId: Int!
          $productId: Int
          $yearMin: Int
          $yearMax: Int
        ) {
          data: countryGroupProductYear(
            productClass: $productClass
            servicesClass: $servicesClass
            productLevel: $productLevel
            partnerGroupId: $partnerGroupId
            productId: $productId
            yearMin: $yearMin
            yearMax: $yearMax
          ) {
            countryId
            locationLevel
            partnerGroupId
            partnerLevel
            productId
            productLevel
            year
            exportValue
            importValue
          }
        }
      `;
    } else if (
      countryId === undefined &&
      partnerCountryId === undefined &&
      groupId &&
      partnerGroupId == undefined &&
      (productId || productId === undefined)
    ) {
      queryToUse = gql`
        query GCPY2(
          $productClass: ProductClass!
          $productLevel: Int
          $groupId: Int!
          $productId: Int
          $yearMin: Int
          $yearMax: Int
        ) {
          data: groupCountryProductYear(
            productClass: $productClass
            productLevel: $productLevel
            groupId: $groupId
            productId: $productId
            yearMin: $yearMin
            yearMax: $yearMax
          ) {
            groupId
            locationLevel
            partnerCountryId
            partnerLevel
            productId
            productLevel
            year
            exportValue
            importValue
          }
        }
      `;
    } else {
      skipQuery = true;
    }
  } else {
    skipQuery = true;
  }

  return { queryToUse, queryIsInvalid: skipQuery };
};

export default determineEndpointFacet;
