import React, { createContext, useContext, useState } from "react";

const TUTORIAL_VERSION = "0_1";
const TUTORIAL_SKIP_KEY = "productSpaceTutorialSkipCount_" + TUTORIAL_VERSION;
const TUTORIAL_COMPLETED_KEY =
  "productSpaceTutorialCompleted_" + TUTORIAL_VERSION;
const WALKTHROUGH_KEY = "localStorageFirstTimeGuideModalKey0_1";

interface TutorialContextType {
  isTutorialOpen: boolean;
  setIsTutorialOpen: (isOpen: boolean) => void;
  shouldShowTutorial: boolean;
  setShouldShowTutorial: (show: boolean) => void;
  markTutorialComplete: () => void;
  dismissTutorial: () => void;
}

const TutorialContext = createContext<TutorialContextType | undefined>(
  undefined,
);

const shouldShowInitially = () => {
  const isCompleted = localStorage.getItem(TUTORIAL_COMPLETED_KEY) === "true";
  const skipCount = parseInt(
    localStorage.getItem(TUTORIAL_SKIP_KEY) || "0",
    10,
  );
  const walkthroughCount = parseInt(
    localStorage.getItem(WALKTHROUGH_KEY) || "0",
    10,
  );
  return !isCompleted && skipCount < 3 && walkthroughCount === 5;
};

export function TutorialProvider({ children }: { children: React.ReactNode }) {
  const [isTutorialOpen, setIsTutorialOpen] = useState(shouldShowInitially());
  const [shouldShowTutorial, setShouldShowTutorial] = useState(
    shouldShowInitially(),
  );

  const markTutorialComplete = () => {
    localStorage.setItem(TUTORIAL_COMPLETED_KEY, "true");
    setShouldShowTutorial(false);
  };

  const dismissTutorial = () => {
    const currentCount = parseInt(
      localStorage.getItem(TUTORIAL_SKIP_KEY) || "0",
      10,
    );
    const newCount = currentCount + 1;
    localStorage.setItem(TUTORIAL_SKIP_KEY, newCount.toString());
    setShouldShowTutorial(newCount < 3);
    setIsTutorialOpen(false);
  };

  return (
    <TutorialContext.Provider
      value={{
        isTutorialOpen,
        setIsTutorialOpen,
        shouldShowTutorial,
        setShouldShowTutorial,
        markTutorialComplete,
        dismissTutorial,
      }}
    >
      {children}
    </TutorialContext.Provider>
  );
}

export function useTutorial() {
  const context = useContext(TutorialContext);
  if (context === undefined) {
    throw new Error("useTutorial must be used within a TutorialProvider");
  }
  return context;
}
