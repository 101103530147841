import {
  ProductCodeLabel,
  SectorLabelWithColorIcon,
  TooltipBody,
  TooltipBodyRow,
  TooltipBodyRowLabel,
  TooltipBodyRowValue,
  TooltipContentsContainer,
  TooltipHeader,
  TooltipTheme,
} from "../../components/Tooltip";
import styled from "@emotion/styled";
import { backgroundDark, lightBorderColor } from "../../components/styles";
import { moneyFormatter } from "../../Utils";
import { color } from "d3-color";

const TooltipBase = styled.div<{
  $theme?: TooltipTheme | undefined;
  $overrideStyles?: boolean | undefined;
}>`
  z-index: 3000;
  width: 250px;
  font-size: 0.7rem;
  line-height: 1.4;
  text-transform: none;

  transition: opacity 0.15s ease;
  color: ${backgroundDark};
  background-color: ${({ $theme }) =>
    $theme === TooltipTheme.Dark ? backgroundDark : "rgba(255,255,255)"};
  color: ${({ $theme }) =>
    $theme === TooltipTheme.Dark ? "#fff" : backgroundDark};
  border: 1px solid
    ${({ $theme }) =>
      $theme === TooltipTheme.Dark ? backgroundDark : lightBorderColor};
  border-radius: 4px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  pointer-events: all;
  box-sizing: border-box;
`;

const TooltipContent = ({
  nameShortEn,
  code,
  productClass,
  fillColor,
  sectorName,
  distance,
  exportValue,
  exportRca,
  pci,
  cog,
  ySelection,
}): any => {
  const hexColor = color(fillColor)?.formatHex() ?? "#000000";

  return (
    <TooltipBase>
      <TooltipContentsContainer>
        <TooltipHeader $backgroundColor={hexColor}>
          <div>
            {nameShortEn} |{" "}
            <ProductCodeLabel>
              {code} {productClass}
            </ProductCodeLabel>
          </div>
        </TooltipHeader>
        <TooltipBody>
          <TooltipBodyRow>
            <TooltipBodyRowLabel>Sector:</TooltipBodyRowLabel>
            <TooltipBodyRowValue>
              <SectorLabelWithColorIcon $fillColor={hexColor}>
                {sectorName}
              </SectorLabelWithColorIcon>
            </TooltipBodyRowValue>
          </TooltipBodyRow>
          <TooltipBodyRow>
            <TooltipBodyRowLabel>Distance:</TooltipBodyRowLabel>
            <TooltipBodyRowValue>{distance?.toFixed(3)}</TooltipBodyRowValue>
          </TooltipBodyRow>

          {ySelection === "pci" && (
            <TooltipBodyRow>
              <TooltipBodyRowLabel>
                Product Complexity Index (PCI):
              </TooltipBodyRowLabel>
              <TooltipBodyRowValue>{pci?.toFixed(3)}</TooltipBodyRowValue>
            </TooltipBodyRow>
          )}

          {ySelection === "cog" && (
            <TooltipBodyRow>
              <TooltipBodyRowLabel>Opportunity Gain:</TooltipBodyRowLabel>
              <TooltipBodyRowValue>{cog?.toFixed(3)}</TooltipBodyRowValue>
            </TooltipBodyRow>
          )}

          <TooltipBodyRow>
            <TooltipBodyRowLabel>
              Revealed Comparative Advantage (RCA):
            </TooltipBodyRowLabel>
            <TooltipBodyRowValue>{exportRca?.toFixed(3)}</TooltipBodyRowValue>
          </TooltipBodyRow>
          <TooltipBodyRow>
            <TooltipBodyRowLabel>World Trade:</TooltipBodyRowLabel>
            <TooltipBodyRowValue>
              {moneyFormatter.format(exportValue)}
            </TooltipBodyRowValue>
          </TooltipBodyRow>
          {/* <TooltipExploreMoreContainer>
            <h2>{exploreMoreLabel}</h2>
            <TooltipExploreMoreButton>Markets</TooltipExploreMoreButton>
            <TooltipExploreMoreButton>All Exporters</TooltipExploreMoreButton>
          </TooltipExploreMoreContainer> */}
        </TooltipBody>
      </TooltipContentsContainer>
    </TooltipBase>
  );
};
export default TooltipContent;
