import { useCallback, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import DownloadIcon from "@mui/icons-material/Download";
import {
  Chip,
  Button,
  Box,
  Popover,
  IconButton,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import DataChip from "../ui/DataChip";

interface DownloadsTableProps {
  data: any[];
  filterOptions: {
    dataTypes: string[];
    classifications: string[];
    productLevels: string[];
  };
  columnFilters: any[];
  onColumnFiltersChange: (filters: any[]) => void;
  onOpenModal: (row: any) => void;
}

const DownloadsTable = ({
  data,
  filterOptions,
  columnFilters,
  onColumnFiltersChange,
  onOpenModal,
}: DownloadsTableProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [openFilterColumn, setOpenFilterColumn] = useState<string | null>(null);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 7,
  });

  const handleFilterClick = useCallback(
    (event: React.MouseEvent<HTMLElement>, columnId: string) => {
      setAnchorEl(event.currentTarget);
      setOpenFilterColumn(columnId);
    },
    [],
  );

  const handleFilterClose = () => {
    setAnchorEl(null);
    setOpenFilterColumn(null);
  };

  const handleFilterChange = (columnId: string, value: string) => {
    const currentFilters = [...columnFilters];
    const filterIndex = currentFilters.findIndex(
      (filter) => filter.id === columnId,
    );

    if (filterIndex >= 0) {
      const currentValues = currentFilters[filterIndex].value as string[];
      const valueIndex = currentValues.indexOf(value);

      if (valueIndex >= 0) {
        const newValues = [...currentValues];
        newValues.splice(valueIndex, 1);

        if (newValues.length === 0) {
          currentFilters.splice(filterIndex, 1);
        } else {
          currentFilters[filterIndex] = {
            ...currentFilters[filterIndex],
            value: newValues,
          };
        }
      } else {
        currentFilters[filterIndex] = {
          ...currentFilters[filterIndex],
          value: [...currentValues, value],
        };
      }
    } else {
      currentFilters.push({
        id: columnId,
        value: [value],
      });
    }

    onColumnFiltersChange(currentFilters);
  };

  const isValueSelected = (columnId: string, value: string) => {
    const filter = columnFilters.find((filter) => filter.id === columnId);
    if (!filter) return false;
    return (filter.value as string[]).includes(value);
  };

  const getFilterOptions = (columnId: string) => {
    switch (columnId) {
      case "Data Type (tag)":
        return filterOptions.dataTypes;
      case "Classification (tag)":
        return filterOptions.classifications;
      case "Complexity? (tag)":
        return ["Yes", "No"];
      case "Product Level":
        return filterOptions.productLevels;
      default:
        return [];
    }
  };

  const renderFilterOptionLabel = (columnId: string, option: string) => {
    if (columnId === "Data Type (tag)") {
      return <DataChip label={option} chipType="dataType" size="small" />;
    } else if (columnId === "Classification (tag)") {
      return <DataChip label={option} chipType="classification" size="small" />;
    } else if (columnId === "Complexity? (tag)") {
      return option === "Yes" ? (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <CheckCircleIcon fontSize="small" sx={{ fill: "#5AC27D", mr: 0.5 }} />
          <span>Yes</span>
        </Box>
      ) : (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <CancelIcon fontSize="small" sx={{ color: "#bdbdbd", mr: 0.5 }} />
          <span>No</span>
        </Box>
      );
    } else if (columnId === "Product Level") {
      return `${option} digit`;
    }
    return option;
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "Display Name",
        header: "Name",
        enableColumnFilter: false,
        size: 170,
        Header: ({ column }) => (
          <Box
            sx={{
              fontWeight: 600,
              display: "flex",
              alignItems: "center",
            }}
          >
            Name
          </Box>
        ),
        Cell: ({ cell }) => {
          const value = cell.getValue();

          return (
            <div
              style={{
                width: "180px",
                whiteSpace: "normal", // Allows text to wrap
              }}
            >
              {value}
            </div>
          );
        },
        accessorFn: (row) => row["Display Name"] || row.displayName,
      },
      {
        accessorKey: "Data Type (tag)",
        header: "Data Type",
        filterVariant: "multi-select" as const,
        filterFn: "multiSelectFilter",
        filterSelectOptions: filterOptions.dataTypes,
        size: 140,
        Header: ({ column }) => {
          const isFiltered = columnFilters.some(
            (filter) => filter.id === "Data Type (tag)",
          );
          return (
            <Box
              sx={{
                fontWeight: 600,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {isFiltered ? (
                  <FilterAltIcon fontSize="small" sx={{ mr: 0.5 }} />
                ) : (
                  <FilterAltIcon
                    fontSize="small"
                    sx={{ mr: 0.5, visibility: "hidden" }}
                  />
                )}
                <span>Data Type</span>
              </Box>
              <IconButton
                size="small"
                onClick={(e) => handleFilterClick(e, "Data Type (tag)")}
                sx={{ p: 0 }}
              >
                <KeyboardArrowDownIcon fontSize="small" />
              </IconButton>
            </Box>
          );
        },
        Cell: ({ cell }) => {
          const value = cell.getValue();
          if (!value) return <span>-</span>;
          return <DataChip label={value as string} chipType="dataType" />;
        },
        accessorFn: (row) => {
          const dataType = row["Data Type (tag)"] || row.dataType;
          if (!dataType && row.originalData) {
            return row.originalData.repo
              ? row.originalData.repo.charAt(0).toUpperCase() +
                  row.originalData.repo.slice(1)
              : "Other";
          }
          return dataType;
        },
      },
      {
        accessorKey: "Classification (tag)",
        header: "Classification",
        filterVariant: "multi-select" as const,
        filterFn: "multiSelectFilter",
        filterSelectOptions: filterOptions.classifications,
        size: 190,
        Header: ({ column }) => {
          const isFiltered = columnFilters.some(
            (filter) => filter.id === "Classification (tag)",
          );
          return (
            <Box
              sx={{
                fontWeight: 600,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {isFiltered ? (
                  <FilterAltIcon fontSize="small" sx={{ mr: 0.5 }} />
                ) : (
                  <FilterAltIcon
                    fontSize="small"
                    sx={{ mr: 0.5, visibility: "hidden" }}
                  />
                )}
                <span>Classification</span>
              </Box>
              <IconButton
                onClick={(e) => handleFilterClick(e, "Classification (tag)")}
                sx={{ p: 0 }}
              >
                <KeyboardArrowDownIcon fontSize="medium" />
              </IconButton>
            </Box>
          );
        },
        Cell: ({ cell }) => {
          const value = cell.getValue() as string;
          if (!value) return <span>-</span>;
          return (
            <div style={{ display: "flex", gap: "4px" }}>
              {value.split("/").map((tag) => (
                <DataChip key={tag} label={tag} chipType="classification" />
              ))}
            </div>
          );
        },
      },
      {
        accessorKey: "Product Level",
        header: "Product Level",
        filterVariant: "multi-select" as const,
        filterFn: "multiSelectFilter",
        filterSelectOptions: filterOptions.productLevels,
        size: 135,
        Header: ({ column }) => {
          const isFiltered = columnFilters.some(
            (filter) => filter.id === "Product Level",
          );
          return (
            <Box
              sx={{
                fontWeight: 600,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {isFiltered ? (
                  <FilterAltIcon fontSize="small" sx={{ mr: 0.5 }} />
                ) : (
                  <FilterAltIcon
                    fontSize="small"
                    sx={{ mr: 0.5, visibility: "hidden" }}
                  />
                )}
                <span>Product Level</span>
              </Box>
              <IconButton
                size="small"
                onClick={(e) => handleFilterClick(e, "Product Level")}
                sx={{ p: 0 }}
              >
                <KeyboardArrowDownIcon fontSize="small" />
              </IconButton>
            </Box>
          );
        },
        Cell: ({ cell }) => {
          const value = cell.getValue();
          if (!value || value === "N/A") {
            return <Box sx={{ textAlign: "center" }}>N/A</Box>;
          }
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
                justifyContent: "center",
              }}
            >
              <Chip
                sx={{
                  backgroundColor: "#A8B8C6",
                  color: "black",
                }}
                label={value}
                size="small"
              />
              digit
            </Box>
          );
        },
        accessorFn: (row) => row["Product Level"] || row.productLevel || "N/A",
      },
      {
        accessorKey: "Years",
        header: "Years",
        enableColumnFilter: false,
        size: 80,
        Header: () => (
          <Box
            sx={{
              fontWeight: 600,
              display: "flex",
              alignItems: "center",
            }}
          >
            Years
          </Box>
        ),
        accessorFn: (row) => {
          const yearMin = row["Year Min"] || row.yearMin;
          const yearMax = row["Year Max"] || row.yearMax;
          if (yearMin && yearMax) {
            return `${yearMin}-${yearMax}`;
          }
          return "N/A";
        },
      },
      {
        accessorKey: "actions",
        header: "",
        enableColumnFilter: false,
        size: 120,
        Cell: ({ row }) => (
          <Button
            variant="outlined"
            startIcon={<DownloadIcon />}
            onClick={() => onOpenModal(row.original)}
            sx={{
              borderColor: "#37474f",
              color: "#37474f",
              textTransform: "none",
              "&:hover": {
                borderColor: "#263238",
                backgroundColor: "rgba(55, 71, 79, 0.04)",
              },
            }}
          >
            Download
          </Button>
        ),
      },
      {
        accessorKey: "Complexity? (tag)",
        header: "Complexity Data",
        filterVariant: "multi-select" as const,
        filterFn: "multiSelectFilter",
        filterSelectOptions: ["Yes", "No"],
        size: 150,
        Header: ({ column }) => {
          const isFiltered = columnFilters.some(
            (filter) => filter.id === "Complexity? (tag)",
          );
          return (
            <Box
              sx={{
                fontWeight: 600,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {isFiltered ? (
                  <FilterAltIcon fontSize="small" sx={{ mr: 0.5 }} />
                ) : (
                  <FilterAltIcon
                    fontSize="small"
                    sx={{ mr: 0.5, visibility: "hidden" }}
                  />
                )}
                <span>Complexity Data</span>
              </Box>
              <IconButton
                size="small"
                onClick={(e) => handleFilterClick(e, "Complexity? (tag)")}
                sx={{ p: 0 }}
              >
                <KeyboardArrowDownIcon fontSize="small" />
              </IconButton>
            </Box>
          );
        },
        Cell: ({ cell }) =>
          cell.getValue() === "Yes" ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CheckCircleIcon sx={{ fill: "#5AC27D" }} /> <span>Yes</span>
            </Box>
          ) : (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CancelIcon sx={{ color: "#bdbdbd" }} /> <span>No</span>
            </Box>
          ),
        accessorFn: (row) => {
          if (row.originalData?.complexityData !== undefined) {
            return row.originalData.complexityData ? "Yes" : "No";
          }
          return row["Complexity? (tag)"] || "No";
        },
      },
    ],
    [columnFilters, filterOptions, handleFilterClick, onOpenModal],
  );

  const table = useMaterialReactTable({
    columns: columns,
    data,
    enableGlobalFilter: false,
    enableColumnFilters: false,
    enableSorting: false,
    enableColumnOrdering: false,
    enableRowSelection: false,
    enableColumnActions: false,
    enableColumnFilterModes: false,
    enableFilters: true,
    enableHiding: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableTopToolbar: false,
    enableStickyHeader: true,
    enablePinning: true,
    paginationDisplayMode: "pages",

    muiTableContainerProps: {
      sx: {
        display: "flex",
        overflow: "none",
        border: "none",
        borderRadius: "0px",
      },
    },
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        width: "calc(100vw - 130px)",
        display: "flex",
        flexDirection: "column",
        borderRadius: "0px",
        border: "none",
        overflow: "none",
      },
    },
    filterFns: {
      multiSelectFilter: (row, columnId, filterValue: string[]) => {
        if (!filterValue?.length) return true;
        const value = row.getValue(columnId);
        const valueArray =
          typeof value === "string"
            ? value.split("/")
            : Array.isArray(value)
              ? value
              : [value];
        return filterValue.some((filter) =>
          valueArray.some((val) => val?.toString() === filter?.toString()),
        );
      },
    },
    muiFilterCheckboxProps: {
      sx: { padding: "4px" },
    },
    renderColumnFilterModeMenuItems: () => [],
    initialState: {
      showColumnFilters: true,
      pagination: { pageSize: 7, pageIndex: 0 },
      density: "compact",
      columnPinning: {
        right: ["actions"],
      },
    },
    state: {
      columnFilters,
      pagination,
    },
    onPaginationChange: setPagination,
    onColumnFiltersChange: (updatedFilters) => {
      setPagination((prev) => ({
        ...prev,
        pageIndex: 0,
      }));
      onColumnFiltersChange(updatedFilters as any[]);
    },
    muiTableHeadCellProps: ({ column }) => ({
      sx: {
        backgroundColor: "#CCD4DB",
        border: "1px solid #BFBFBF",
        "& .Mui-TableHeadCell-Content": {
          justifyContent: "space-between",
        },
        fontWeight: 600,
        ...(column.id === "actions" && {
          boxShadow: "-2px 0 5px -2px rgba(0,0,0,0.3)",
        }),
      },
    }),
    muiTableBodyCellProps: ({ column }) => ({
      sx: {
        border: ".8px solid #BFBFBF",
        ...(column.id === "actions" && {
          boxShadow: "-2px 0 5px -2px rgba(0,0,0,0.3)",
        }),
        maxWidth: "50px",
      },
    }),
    muiTableProps: {
      sx: {
        tableLayout: "fixed",
      },
    },
    muiPaginationProps: {
      showFirstButton: false,
      showLastButton: false,
      showRowsPerPage: false,
      SelectProps: {
        sx: { marginRight: "8px" },
      },
      sx: {
        "& .MuiPaginationItem-root": {
          borderRadius: 0,
          fontSize: "14px",
        },
        "& .MuiPaginationItem-page, & .MuiPaginationItem-previousNext": {
          border: "1px solid #BFBFBF",
        },
        "& .Mui-selected": {
          backgroundColor: "#CCD4DB",
          "&:hover": {
            backgroundColor: "#CCD4DB",
          },
        },
      },
    },
    positionToolbarAlertBanner: "none",
    renderDetailPanel: undefined,
    columnFilterDisplayMode: "custom",
    renderBottomToolbarCustomActions: (d) => {
      return (
        <span>
          Total: <b>{d.table.getFilteredRowModel().rows.length}</b>
        </span>
      );
    },
  });

  return (
    <>
      <MaterialReactTable table={table} />

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleFilterClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {openFilterColumn && (
          <Box sx={{ p: 2 }}>
            <FormGroup>
              {getFilterOptions(openFilterColumn).map((option: string) => {
                return (
                  <FormControlLabel
                    key={option}
                    control={
                      <Checkbox
                        checked={isValueSelected(openFilterColumn, option)}
                        onChange={() =>
                          handleFilterChange(openFilterColumn, option)
                        }
                        size="small"
                      />
                    }
                    label={renderFilterOptionLabel(openFilterColumn, option)}
                  />
                );
              })}
            </FormGroup>
          </Box>
        )}
      </Popover>
    </>
  );
};

export default DownloadsTable;
