import { useState } from "react";
import GuideIcon from "../../assets/images/ui/guide.svg";
import GuideLogo from "../../assets/images/ui/gl-widget.png";
import Modal from "@mui/material/Modal";
import {
  OptionIcon,
  OptionLabel,
  OptionsListItem,
} from "../navigationBar/Components";
import { Box, Button, Checkbox, FormControlLabel } from "@mui/material";
import Walkthrough from "../walkthrough/Walkthrough";
import { googleAnalyticsCustomEvent } from "../../sharedUtilities/googleAnalyticsEvent";

const modalVersion = "0_1";
const localStorageKey = "localStorageFirstTimeGuideModalKey" + modalVersion;

export const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const GuideModalButton = ({
  menuOptionClassName,
  isNavigationBarOpen,
}: any) => {
  const initialCheckedValue = parseInt(
    localStorage.getItem(localStorageKey) as any,
    10,
  );
  const [isGuideOpen, setIsGuideOpen] = useState<boolean>(
    isNaN(initialCheckedValue) || initialCheckedValue < 5,
  );
  const [runWalkthrough, setRunWalkthrough] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);

  const toggleGuide = () => {
    if (!isGuideOpen) {
      googleAnalyticsCustomEvent("leftnav", "click", "guide", "explore");
    }
    setIsGuideOpen(!isGuideOpen);
  };

  const closeModal = () => {
    setIsGuideOpen(false);
    if (checked) {
      localStorage.setItem(localStorageKey, "5");
    } else {
      const newCheckedCount = isNaN(initialCheckedValue)
        ? 0
        : initialCheckedValue + 1;
      localStorage.setItem(localStorageKey, newCheckedCount.toString());
    }
  };

  const onStart = () => {
    setIsGuideOpen(false);
    localStorage.setItem(localStorageKey, "5");
    setRunWalkthrough(true);
  };

  let guideModal = null;

  if (isGuideOpen) {
    guideModal = (
      <Modal open={isGuideOpen} onClose={closeModal}>
        <Box sx={modalStyle}>
          <img
            style={{ width: "400px" }}
            src={GuideLogo}
            alt={"Growth Lab Logo"}
          />
          <Button
            sx={{
              mt: 2,
              backgroundColor: "#D1B656",
              color: "white",
              "&:hover": {
                backgroundColor: "#E6C766",
              },
              "&:focus": {
                backgroundColor: "#E6C766",
              },
            }}
            onClick={onStart}
          >
            FOLLOW OUR STEP-BY-STEP GUIDE
          </Button>
          <Button
            sx={{
              color: "black",
              backgroundColor: "lightgray",
              mt: 2,
            }}
            onClick={closeModal}
          >
            NOT NOW
          </Button>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={(event) => setChecked(event.target.checked)}
                color="primary"
              />
            }
            label="Don't Show Again"
          />
        </Box>
      </Modal>
    );
  }

  return (
    <>
      {runWalkthrough && <Walkthrough setRunWalkthrough={setRunWalkthrough} />}
      <OptionsListItem className={menuOptionClassName} onClick={toggleGuide}>
        <OptionIcon>
          <img src={GuideIcon} alt={"Guide"} />
        </OptionIcon>
        {isNavigationBarOpen === true && <OptionLabel>Guide</OptionLabel>}
      </OptionsListItem>
      {guideModal}
    </>
  );
};

export default GuideModalButton;
