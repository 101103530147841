import styled from "@emotion/styled";
import { getLocationLevelFromStringLocationId } from "../../sharedUtilities/Utils";
import { LocationLevel } from "../../graphql/types";
import { usePageQueryParams } from "../../visualization/defaultSettings";
import { worldGroupDatum } from "../../graphql/queries/getLocationsMetadata";
import useFetchMetadata, {
  MetadataFetchType,
} from "../../sharedUtilities/useFetchMetadata";
import LinkOutArrowIcon from "../../assets/images/ui/external-link.svg";
import {
  countryProfilesUrlBase,
  delayVisibility,
  ExternalLinkImage,
  MenuRow,
  MenuRowIcon,
  MenuRowLabel,
} from "./Components";
import { gql, useQuery } from "@apollo/client";
import { googleAnalyticsCustomEvent } from "../../sharedUtilities/googleAnalyticsEvent";

const ExploreFurtherRow = styled.div`
  border-top: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
  margin-top: 4px;
  margin-bottom: 4px;
  padding-top: 4px;
  padding-bottom: 4px;
  opacity: 0;
  margin-right: 15px;

  animation-name: ${delayVisibility};
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
`;

const ExploreFurtherHeader = styled.h2`
  margin: 0px;
  padding: 0px;
  padding-left: 10px;
  font-size: 12px;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: normal;
`;

const ExploreFurtherCalloutLink = styled.a`
  color: white;
`;
const GET_DATA_FLAGS = gql`
  query GetDataFlags($countryId: Int!) {
    dataFlags(countryId: $countryId) {
      countryId
      inMv
      inCp
    }
  }
`;
const ExploreFurther = () => {
  const [{ exporter: currentExporter, importer: currentImporter }] =
    usePageQueryParams();

  const {
    metadataStatus,
    metadata: countryMetadata = [],
    error,
  } = useFetchMetadata({ metadataFetchType: MetadataFetchType.Country });

  let useExporter =
    currentExporter === worldGroupDatum.groupId ? undefined : currentExporter;
  let useImporter =
    currentImporter === worldGroupDatum.groupId ? undefined : currentImporter;

  let locationFocus: string | undefined;
  if (useExporter) {
    locationFocus = useExporter;
  } else if (useImporter) {
    locationFocus = useImporter;
  }
  let locationLevel = getLocationLevelFromStringLocationId(locationFocus);

  const numericalLocationId = parseInt(locationFocus?.split("-")?.[1], 10);
  const { data: dataFlagsData } = useQuery(GET_DATA_FLAGS, {
    variables: { countryId: numericalLocationId },
    skip: !locationFocus || locationLevel !== LocationLevel.Country,
  });

  if (locationLevel !== LocationLevel.Country) {
    return null;
  }
  const { inMv, inCp } = dataFlagsData?.dataFlags?.[0] || {};

  let locationMetadatum = countryMetadata.find(
    (country: any) => country.countryId === locationFocus,
  );

  const locationName = locationMetadatum.nameShortEn;

  let countryProfilesLink = `${countryProfilesUrlBase}/${numericalLocationId}`;
  let citiesLink = `https://metroverse.cid.harvard.edu/?country=${numericalLocationId}`;

  return (
    <>
      {(inCp || inMv) && (
        <ExploreFurtherRow>
          <ExploreFurtherHeader>Explore Further</ExploreFurtherHeader>
          {inCp && (
            <ExploreFurtherCalloutLink
              href={countryProfilesLink}
              target="_blank"
              onClick={() =>
                googleAnalyticsCustomEvent(
                  "leftnav",
                  "click",
                  "profile",
                  "explore",
                )
              }
            >
              <MenuRow>
                <MenuRowIcon>
                  <ExternalLinkImage src={LinkOutArrowIcon} />
                </MenuRowIcon>
                <MenuRowLabel>
                  {locationName}'s <b>country profile</b> in Atlas Country
                  Profiles
                </MenuRowLabel>
              </MenuRow>
            </ExploreFurtherCalloutLink>
          )}
          {inMv && (
            <ExploreFurtherCalloutLink
              onClick={() =>
                googleAnalyticsCustomEvent(
                  "leftnav",
                  "click",
                  "metroverse",
                  "explore",
                )
              }
              href={citiesLink}
              target="_blank"
            >
              <MenuRow>
                <MenuRowIcon>
                  <ExternalLinkImage src={LinkOutArrowIcon} />
                </MenuRowIcon>
                <MenuRowLabel>
                  {locationName}'s <b>cities</b> in Metroverse
                </MenuRowLabel>
              </MenuRow>
            </ExploreFurtherCalloutLink>
          )}
        </ExploreFurtherRow>
      )}
    </>
  );
};

export default ExploreFurther;
