import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { ReactComponent as LearnIcon } from "./img/learn.svg";
import DiagramImage from "./img/Diagram.png";
import AustriaPs from "./img/Austria_ps.png";
import AustraliaPs from "./img/Australia_ps.png";
import CloseIcon from "@mui/icons-material/Close";
import NodeColorLegend from "../../visualization/charts/productspace/NodeColorLegend";
import AustriaFlag from "./img/Austria.svg";
import AustraliaFlag from "./img/Australia.svg";
import AnimatedProductSpace from "./AnimatedProductSpace";
import { ParentSize } from "@visx/responsive";
import { useQuery } from "@apollo/client";
import { query } from "../../visualization/charts/growth";
import hoverLarge from "./img/hover_large.gif";
import clickLarge from "./img/click_large.gif";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTutorial } from "./TutorialContext";
import { latestProductYear } from "../../graphql/Utils";

const TutorialContainer = styled.div<{ currentStep: number }>`
  width: calc(100% - 22px);
  height: calc(100% - 22px);
  margin: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  padding: clamp(0.5rem, 2vw, 1rem);
  padding-bottom: calc(clamp(0.5rem, 2vw, 1rem) + 12px);
  z-index: 1000;
  border: 0.5px solid #707070;
  box-sizing: border-box;
  max-height: 100vh;
  background-color: ${(props) => (props.currentStep > 0 ? "white" : "#F6F6F6")};

  @media (max-width: 768px) {
    margin: 5px;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
  }
`;

const Section = styled.div`
  margin: clamp(0.1rem, 1vh, 0.15rem) 0;
`;

const TopSection = styled(Section)`
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  height: 100%;
  max-height: 60vh;
  position: relative;
`;

const BottomSection = styled(Section)`
  display: flex;
  flex: 1;
  min-height: auto;
  max-height: 25vh;
  overflow-y: auto;
  align-items: center;
  justify-content: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: clamp(0.5rem, 2vw, 1rem);
  margin-bottom: clamp(0.25rem, 1.5vh, 0.5rem);
  text-align: center;
  flex-shrink: 0;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const LightbulbIcon = styled.div`
  color: #d1b656;
  height: 60px;
  display: flex;
  align-items: flex-start;
  padding-top: 4px;
  svg {
    width: 100%;
    height: 100%;
    fill: #d1b656;
  }
`;

const Title = styled.h1`
  font-size: 32px;
  font-weight: 400;
  color: #333;
  margin: 0;
  line-height: 1.2;
  max-width: 1000px;

  @media (max-width: 768px) {
    font-size: 24px;
  }
  text-align: left;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: clamp(0.5rem, 1vh, 0.75rem);

  flex-shrink: 0;
`;

const LearnButton = styled.button`
  background: #d1b656;
  color: white;
  border: none;
  padding: 0.75rem 2rem;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.2s ease;

  &:hover {
    background: #b5a25f;
  }
`;

const SkipButton = styled.button`
  background: transparent;
  border: 1px solid #666;
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  color: #666;
  cursor: pointer;
  transition: background 0.2s ease;

  &:hover {
    background: #f5f5f5;
  }
`;

const Description = styled.p`
  color: #333;
  margin: 0.1rem 0;
  overflow-wrap: break-word;
  max-width: 1000px;
  font-size: clamp(2px, 2vw, 22px);
  line-height: 1.3;
`;

const DiagramImageContainer = styled.div<{ isAnimated?: boolean }>`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  ${(props) =>
    props.isAnimated
      ? `
    & > div {
      position: absolute !important;
      inset: 0;
      width: 100% !important;
      height: 100% !important;
    }
  `
      : `
    img {
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
      object-fit: contain;
    }
  `}
`;

const CloseButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: transparent;
  border: none;
  cursor: pointer;
  color: #000;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  transition: background 0.2s ease;

  &:hover {
    background: #f5f5f5;
  }
  z-index: 99999;
`;

const ProgressBar = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 12px;
  background-color: #d1b6564d;
`;

const ProgressFill = styled.div<{ progress: number }>`
  height: 100%;
  background-color: #d1b656;
  width: ${(props) => props.progress}%;
  transition: width 0.3s ease;
`;

interface TutorialStep {
  title?: string;
  bottomContent: React.ReactNode;
}

const AustriaText = () => (
  <div style={{ display: "flex", alignItems: "center", lineHeight: 1.1 }}>
    <div>
      <img
        style={{ height: 20, paddingRight: 10 }}
        src={AustriaFlag}
        alt="Austria Flag"
      />{" "}
    </div>
    Austria in the product space
  </div>
);

const NavigationButtons = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }

  @media (max-width: 768px) {
    gap: 0.5rem;
    width: 100%;

    button {
      flex: 1;
    }
  }
`;

interface ProductSpaceTutorialProps {
  open: boolean;
  onClose: () => void;
}

const TUTORIAL_QUERY_PARAMS = {
  productClass: "HS12",
  countryId: "40",
  productLevel: 4,
  yearMin: latestProductYear,
  yearMax: latestProductYear,
  hs12: true,
  hs92: false,
  sitc: false,
};

const AnimatedProductSpaceContainer = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr 200px;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  gap: 1rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
  }
`;

const SidePanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  height: 100%;
  overflow: auto;
`;

const ComparisonContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  width: 100%;
  height: 100%;
  position: relative;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
`;

const CountryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 0;
`;

const ImageWrapper = styled.div`
  flex: 1;
  width: 100%;
  min-height: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    object-fit: contain;
  }
`;

const CountryLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.5rem;
  font-size: 0.9rem;
  color: #333;
  flex-shrink: 0;
  line-height: 1.1;
  font-weight: 600;

  img {
    height: 16px;
    width: auto;
  }
`;

const ProductSpaceTutorial: React.FC<ProductSpaceTutorialProps> = ({
  open,
  onClose,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const { markTutorialComplete, dismissTutorial } = useTutorial();
  const { data } = useQuery(query, {
    variables: TUTORIAL_QUERY_PARAMS,
  });

  useEffect(() => {
    if (open) {
      const imagesToPreload = [
        DiagramImage,
        AustriaPs,
        AustraliaPs,
        hoverLarge,
        clickLarge,
      ];

      imagesToPreload.forEach((imageSrc) => {
        const img = new Image();
        img.src = imageSrc;
      });
    }
  }, [open]);

  const renderTopContent = () => {
    if (currentStep === 0) {
      return (
        <DiagramImageContainer isAnimated={false}>
          <img
            style={{ maxWidth: "80%", maxHeight: "80%" }}
            src={DiagramImage}
            alt="Product Space Introduction"
          />
        </DiagramImageContainer>
      );
    }

    if (currentStep === 5) {
      return (
        <DiagramImageContainer>
          <ComparisonContainer>
            <CountryContainer>
              <ImageWrapper>
                <img src={AustriaPs} alt="Austria Product Space" />
              </ImageWrapper>
              <CountryLabel>
                <img src={AustriaFlag} alt="Austria Flag" />
                Austria in the Product Space
              </CountryLabel>
            </CountryContainer>

            <CountryContainer>
              <ImageWrapper>
                <img src={AustraliaPs} alt="Australia Product Space" />
              </ImageWrapper>
              <CountryLabel>
                <img src={AustraliaFlag} alt="Australia Flag" />
                Australia in the Product Space
              </CountryLabel>
            </CountryContainer>
          </ComparisonContainer>
        </DiagramImageContainer>
      );
    }
    if (currentStep === 6) {
      return (
        <DiagramImageContainer>
          <img src={hoverLarge} alt="Hover interaction demo" />
        </DiagramImageContainer>
      );
    }
    if (currentStep === 7) {
      return (
        <DiagramImageContainer>
          <img src={clickLarge} alt="Click interaction demo" />
        </DiagramImageContainer>
      );
    }

    return (
      <AnimatedProductSpaceContainer>
        <SidePanel>
          {currentStep === 4 && (
            <div style={{ alignSelf: "flex-start" }}>
              <AustriaText />
            </div>
          )}
        </SidePanel>

        <DiagramImageContainer isAnimated={true}>
          <ParentSize>
            {({ width, height }) =>
              width && height ? (
                <AnimatedProductSpace
                  step={currentStep - 1}
                  width={width}
                  height={height}
                  data={data}
                />
              ) : null
            }
          </ParentSize>
        </DiagramImageContainer>

        <SidePanel>{currentStep === 4 && <NodeColorLegend />}</SidePanel>
      </AnimatedProductSpaceContainer>
    );
  };

  const tutorialSteps: TutorialStep[] = [
    {
      title:
        "Learn about the Product Space and navigate your potential —from current capabilities toward prosperity.",
      bottomContent: null,
    },
    {
      bottomContent: (
        <Description>
          In the Product Space, each node represents a product.  Here, we see
          more than 800 products traded around the world.
        </Description>
      ),
    },
    {
      bottomContent: (
        <Description>
          <b>Connected by capability:</b> Products requiring similar
          capabilities cluster together in the space, as they are likely to be
          produced in the same place. Products that require distinct
          capabilities are more distant in the space.
        </Description>
      ),
    },
    {
      bottomContent: (
        <Description>
          <b>Products are sized by global export volume.</b> Note that the
          location of each node remains fixed in the Product Space, regardless
          of which country is selected.
        </Description>
      ),
    },
    {
      bottomContent: (
        <Description>
          <b>Proximity signals possibility:</b> By examining a country in the
          Product Space, we can identify how its current strengths connect to
          future growth pathways.
        </Description>
      ),
    },
    {
      bottomContent: (
        <Description>
          <b>Different starting points, different trajectories:</b> By comparing
          2 countries - Austria and Australia - the Product Space reveals how
          distinct capabilities shape unique paths to diversification.
        </Description>
      ),
    },
    {
      bottomContent: (
        <Description>
          <b>Navigate your potential:</b> By hovering over a product, you can
          explore its connections to nearby products.
        </Description>
      ),
    },
    {
      bottomContent: (
        <Description>
          <b>Unlock your next opportunity:</b> By clicking on a product, you can
          view its related products in a ring chart. Here we see that Austria's
          strength in tractors offers a related opportunity to produce vehicle
          bodies, as they require similar capabilities.
        </Description>
      ),
    },
  ];

  useEffect(() => {
    if (!open) {
      setCurrentStep(0);
    }
  }, [open]);

  const handleNext = () => {
    if (currentStep === 0) {
      markTutorialComplete();
    }
    if (currentStep < tutorialSteps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      onClose();
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleClose = () => {
    dismissTutorial();
    onClose();
  };

  if (!open) return null;

  return (
    <TutorialContainer currentStep={currentStep}>
      {currentStep > 0 && (
        <CloseButton onClick={handleClose}>
          <CloseIcon sx={{ fontSize: 34 }} />
        </CloseButton>
      )}
      {tutorialSteps[currentStep].title && (
        <Header>
          <LightbulbIcon>
            <LearnIcon />
          </LightbulbIcon>
          <Title>{tutorialSteps[currentStep].title}</Title>
        </Header>
      )}

      <TopSection>{renderTopContent()}</TopSection>
      {tutorialSteps[currentStep].bottomContent && (
        <BottomSection>
          {tutorialSteps[currentStep].bottomContent}
        </BottomSection>
      )}

      <ButtonContainer>
        <NavigationButtons>
          {currentStep > 0 && (
            <SkipButton onClick={handlePrevious}>
              <ArrowBackIcon sx={{ fontSize: 20 }} />
              Previous
            </SkipButton>
          )}
          <LearnButton onClick={handleNext}>
            {currentStep === tutorialSteps.length - 1 ? (
              "Get Started"
            ) : currentStep === 0 ? (
              "LET'S LEARN"
            ) : (
              <>
                Next
                <ArrowForwardIcon sx={{ fontSize: 20 }} />
              </>
            )}
          </LearnButton>
        </NavigationButtons>
        {currentStep === 0 && (
          <SkipButton onClick={handleClose}>SKIP THE TUTORIAL</SkipButton>
        )}
      </ButtonContainer>
      <ProgressBar>
        <ProgressFill
          progress={(currentStep / (tutorialSteps.length - 1)) * 100}
        />
      </ProgressBar>
    </TutorialContainer>
  );
};

export default ProductSpaceTutorial;
