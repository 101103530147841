import { useEffect, useMemo, useState } from "react";
import { ColorBy, getCategoriesForChartLegend, getColorMap } from "../../Utils";
import { useQuery } from "@apollo/client";
import { query } from "../growth";
import { extent, index, scaleLinear, scaleSqrt, stratify, sum } from "d3";
import useFetchMetadata, {
  MetadataFetchType,
} from "../../../sharedUtilities/useFetchMetadata";
import links from "./top_edges_pruned.json";
import nodes from "./umap_ps_pruned.json";
import {
  VisualizationBottomRowContainer,
  VisualizationContentContainer,
  VisualizationHandlesContainer,
} from "../../VizGrid";
import CategoryLabels from "../../components/CategoryLabels";
import { useParentSize } from "@visx/responsive";
import { CategoryDatum } from "../../components/LegendLabel";
import { Zoom } from "@visx/zoom";
import ZoomControls from "../growth/ZoomControls";
import { OpportunitySizeOption } from "../growth/utils";
import complexityColorScale from "../treemap/complexityColorScale";
import clusterColors from "./cluster_colors.json";
import centroids from "./centroids.json";
import GraphLoading from "../../components/GraphLoading";
import ComplexityColorScaleLegend from "../treemap/ComplexityColorScaleLegend";
import HighlightOverlay from "./HighlightOverlay";
import AnimatedZoomGroup from "./AnimatedZoomGroup";
import ProductSpacePlot from "./ProductSpacePlot";
import ProductOverlay from "./ProductOverlay";
import ClickIndicator from "./ClickIndicator";
import ChartLegend from "./ChartLegend";
import { usePageQueryParams } from "../../defaultSettings";
import { useOutletContext } from "react-router-dom";
import GraphNotice, { GraphNoticeType } from "../../components/GraphNotice";
import { determineLocationLevel } from "../../../sharedUtilities/Utils";
import { LocationLevel } from "../../../graphql/types";
import { useTotalValue } from "../../TotalValueContext";
import { useDownload } from "../../DownloadContext";
import { greenColorScale } from "./greenColorScale";

const initialTransform = {
  scaleX: 0.9,
  scaleY: 0.9,
  translateX: 25,
  translateY: 25,
  skewX: 0,
  skewY: 0,
};

export const getFillColor = (
  node: any,
  productData: any,
  topParentId: string,
  currentColorBySelection: ColorBy,
  colorMap: Map<string, string>,
  clusterColorLookup: Map<string, any>,
  complexityColorScale: any,
  totalExportLookup: Map<string, any>,
  hiddenCategories: string[],
  countryExportLookup: Map<string, any>,
  rcaThreshold: number,
) => {
  let fill;
  let filtered = false;
  let fillColor;
  if (currentColorBySelection === ColorBy.Green) {
    fillColor = greenColorScale(
      {
        greenProduct: productData?.greenProduct,
        exportRca: countryExportLookup.get(productData?.productId)?.exportRca,
      },
      rcaThreshold,
    );
    fill = fillColor;
  } else if (currentColorBySelection === ColorBy.Sector) {
    fillColor = colorMap.get(topParentId);
    fill = fillColor;
  } else if (currentColorBySelection === ColorBy.Cluster) {
    fillColor = clusterColorLookup.get(node.cluster_name_short).cluster_col;
    fill = fillColor;
  } else if (currentColorBySelection === ColorBy.Complexity) {
    fillColor = complexityColorScale(
      totalExportLookup.get(productData?.productId)?.pci,
    );
    fill = fillColor;
  }

  if (
    (currentColorBySelection === ColorBy.Sector &&
      hiddenCategories.includes(topParentId)) ||
    (currentColorBySelection === ColorBy.Cluster &&
      hiddenCategories.includes(node.cluster_name_short)) ||
    (currentColorBySelection === ColorBy.Green &&
      hiddenCategories.includes(
        `${productData?.greenProduct ? "green" : "not-green"}-${
          countryExportLookup.get(productData?.productId)?.exportRca >
          rcaThreshold
            ? "high"
            : "low"
        }-rca`,
      ))
  ) {
    fill = "#868686";
    filtered = true;
  }

  if (
    currentColorBySelection !== ColorBy.Green &&
    (countryExportLookup.get(productData?.productId)?.exportRca <
      rcaThreshold ||
      !countryExportLookup.get(productData?.productId)?.exportRca) &&
    rcaThreshold !== 0
  ) {
    fill = "#E9E9E9";
  }

  return { fill, filtered, fillColor };
};

const ProductSpace = () => {
  const [
    {
      exporter,
      productClass,
      productLevel,
      view,
      year,
      colorBy: currentColorBySelection,
      sizing,
      rcaThreshold,
    },
  ] = usePageQueryParams();
  const [, setTotalValue] = useTotalValue();
  const { setFindInVizOptions, highlightedItem, setHighlightedItem }: any =
    useOutletContext();
  const [selection, select] = useState(null);
  const [ringItem, setRingItem] = useState(null);
  const { svgRef, dataRef } = useDownload();

  let categoriesForLegend: CategoryDatum[] = useMemo(
    () =>
      getCategoriesForChartLegend({
        view,
        productClass,
        clusters: currentColorBySelection === ColorBy.Cluster,
        currentColorBySelection,
        rcaThreshold,
      }).filter((d) => d.id.split("-")[2] !== "14"),
    [view, productClass, currentColorBySelection, rcaThreshold],
  );

  const [hiddenCategories, setHiddenCategories] = useState<string[]>([]);
  useEffect(() => {
    setHiddenCategories([]);
  }, [currentColorBySelection]);

  const { loading, error, previousData, data } = useQuery(query, {
    variables: {
      productClass,
      countryId: exporter?.split("-")[1],
      productLevel,
      yearMin: year,
      yearMax: year,
      hs12: productClass === "HS12",
      hs92: productClass === "HS92",
      sitc: productClass === "SITC",
    },
  });

  const displayData = data ? data : previousData;
  const {
    countryProductYear = [],
    productYear = [],
    countryYear: [countryData] = [],
  } = displayData || {};

  const {
    metadataStatus: countryMetadataStatus,
    metadata: countryMetadata,
    error: countryMetadataError,
  } = useFetchMetadata({ metadataFetchType: MetadataFetchType.Country });
  const countryLookup = useMemo(() => {
    if (countryMetadata) {
      return index(countryMetadata, (d: any) => d.countryId);
    } else {
      return new Map();
    }
  }, [countryMetadata]) as any;
  const metaDataTree = useMemo(() => {
    if (!displayData) {
      return stratify()([{ id: "root" }]);
    }
    const metaData =
      Object.entries(displayData).find(([k, v]) =>
        k.includes("metadata"),
      )?.[1] || [];

    const tree = stratify()
      .id((d: any) => d.productId)
      .parentId((d: any) => {
        if (d.productLevel === 1) {
          return "root";
        } else if (d?.parent) {
          return d?.parent?.productId;
        } else {
          return d?.topParent?.productId;
        }
      })([{ productId: "root", parent: null }, ...(metaData as Array<any>)]);

    return tree;
  }, [displayData]);
  const metaDataLookup = useMemo(
    () => index(metaDataTree.descendants(), (d) => d.id),
    [metaDataTree],
  ) as any;

  const productIdLookup = useMemo(
    () =>
      index(
        metaDataTree
          .descendants()
          .filter((d: any) => d?.data?.productLevel === 4 && d?.data?.code),
        (d: any) => d.data.code,
      ),
    [metaDataTree],
  ) as any;

  let colorMap = useMemo(
    () => getColorMap({ view, productClass }),
    [view, productClass],
  );
  const clusterColorLookup = useMemo(
    () => index(clusterColors, (d) => d.cluster_name_short),
    [],
  );
  const { parentRef, width, height } = useParentSize({ debounceTime: 150 });

  const { xExtent, yExtent } = useMemo(() => {
    const xExtent = extent(nodes, (d) => parseFloat(d.x));
    const yExtent = extent(nodes, (d) => parseFloat(d.y));
    return { xExtent, yExtent };
  }, []);
  const totalExportLookup = useMemo(
    () => index(productYear, (d: any) => d.productId),
    [productYear],
  ) as any;
  const countryExportLookup = useMemo(
    () => index(countryProductYear, (d: any) => d.productId),
    [countryProductYear],
  ) as any;
  const sizingExtent = useMemo(() => {
    const nodeLookup = index(nodes, (d) => d.product_code);

    const filterServices = (d: any) => {
      const productData = metaDataLookup.get(d.productId)?.data;
      const node = nodeLookup.get(productData.code);
      const topParentId = productData?.topParent?.productId;
      return topParentId.split("-")[2] !== "14" && node;
    };
    if (sizing === OpportunitySizeOption.WorldTrade) {
      return extent(
        productYear.filter(filterServices),
        (d: any) => d.exportValue,
      );
    } else if (sizing === OpportunitySizeOption.CountryTrade) {
      return extent(
        countryProductYear.filter(filterServices),
        (d: any) => d.exportValue,
      );
    } else {
      return [0, 0];
    }
  }, [countryProductYear, metaDataLookup, productYear, sizing]) as any;

  const { xScale, yScale, rScale } = useMemo(() => {
    const xScale = scaleLinear().domain(xExtent).range([0, width]);
    const yScale = scaleLinear().domain(yExtent).range([height, 0]);
    const rScale = scaleSqrt().domain(sizingExtent).range([2, 40]);
    return { xScale, yScale, rScale };
  }, [width, height, xExtent, yExtent, sizingExtent]);

  const linkLookup = useMemo(
    () =>
      links.reduce((obj, datum) => {
        const sourceId = productIdLookup.get(datum.source)?.data.productId;
        const targetId = productIdLookup.get(datum.target)?.data.productId;
        if (obj[sourceId]) {
          obj[sourceId] = Array.from(new Set([...obj[sourceId], targetId]));
        } else {
          obj[sourceId] = [targetId];
        }
        if (obj[targetId]) {
          obj[targetId] = Array.from(new Set([...obj[targetId], sourceId]));
        } else {
          obj[targetId] = [sourceId];
        }
        return obj;
      }, {}),
    [productIdLookup],
  );

  const processedData = useMemo(
    () =>
      nodes
        .map((node: any) => {
          const productData = productIdLookup.get(node.product_code)?.data;
          const topParentId = productData?.topParent?.productId;
          let radiusValue;
          let { fill, filtered, fillColor } = getFillColor(
            node,
            productData,
            topParentId,
            currentColorBySelection,
            colorMap,
            clusterColorLookup,
            complexityColorScale,
            totalExportLookup,
            hiddenCategories,
            countryExportLookup,
            rcaThreshold,
          );

          if (sizing === OpportunitySizeOption.WorldTrade) {
            radiusValue = rScale(
              totalExportLookup.get(
                productIdLookup.get(node.product_code)?.data.productId,
              )?.exportValue,
            );
          } else if (sizing === OpportunitySizeOption.CountryTrade) {
            radiusValue = rScale(
              countryExportLookup.get(productData?.productId)?.exportValue,
            );
          } else {
            radiusValue = 4;
          }

          return {
            ...node,
            key: node.product_code,
            x: xScale(node.x),
            y: yScale(node.y),
            r: radiusValue || 4,
            fill,
            productId: productData?.productId,
            filtered,
            sector: metaDataLookup?.get(topParentId)?.data?.nameShortEn,
            fillColor,
          };
        })
        .sort((p1, p2) => p2.r - p1.r),
    [
      clusterColorLookup,
      colorMap,
      countryExportLookup,
      currentColorBySelection,
      hiddenCategories,
      productIdLookup,
      rScale,
      sizing,
      totalExportLookup,
      xScale,
      yScale,
      rcaThreshold,
      metaDataLookup,
    ],
  );

  useEffect(() => {
    const fivOptions = processedData
      .map((d) => metaDataLookup.get(d.productId))
      .map((d: any) => ({
        id: d?.data.productId,
        label: d?.data.code
          ? `${d?.data.nameShortEn} (${d?.data.code} ${productClass})`
          : d?.data.nameShortEn,
      }));
    setFindInVizOptions(fivOptions);
  }, [
    metaDataLookup,
    metaDataTree,
    processedData,
    productClass,
    productLevel,
    setFindInVizOptions,
  ]);

  useEffect(() => {
    const totalValue = sum(processedData, (d: any) => {
      return countryExportLookup.get(d?.productId)?.exportValue;
    });
    setTotalValue(totalValue);
  }, [countryExportLookup, processedData, setTotalValue]);
  const nodeLookup = useMemo(
    () =>
      index(
        processedData.filter((d) => d.productId),
        (d) => d.productId,
      ),
    [processedData],
  );

  const isCountrySelected =
    exporter &&
    determineLocationLevel({ location: exporter }) === LocationLevel.Country;

  useEffect(() => {
    dataRef.current = processedData.map((d) => ({
      Name: metaDataLookup.get(d.productId)?.data.nameEn,
      Code: d.product_code,
      Year: year,
      "Country Trade": countryExportLookup.get(d?.productId)?.exportValue,
      "World Trade": d.total_exports,
      "Revealed Comparative Advantage (RCA)": countryExportLookup.get(
        d?.productId,
      )?.exportRca,
      Sector: d.sector,
    }));
  }, [processedData, dataRef, metaDataLookup, year, countryExportLookup]);

  if (!isCountrySelected) {
    return (
      <VisualizationContentContainer>
        <GraphNotice
          graphNoticeType={GraphNoticeType.CountrySelectionRequired}
        />
      </VisualizationContentContainer>
    );
  }

  return (
    <>
      {ringItem && (
        <ProductOverlay
          linkLookup={linkLookup}
          selection={ringItem}
          nodeLookup={nodeLookup}
          metaDataLookup={metaDataLookup}
          setRingItem={setRingItem}
          colorMap={colorMap}
          displayData={displayData}
          totalExportLookup={totalExportLookup}
          countryExportLookup={countryExportLookup}
          currentColorBySelection={currentColorBySelection}
          clusterColorLookup={clusterColorLookup}
          complexityColorScale={complexityColorScale}
          rcaThreshold={rcaThreshold}
        />
      )}
      <VisualizationContentContainer>
        <div
          ref={parentRef}
          style={{ height: "100%", width: "100%", position: "absolute" }}
        >
          <ClickIndicator />
          <ChartLegend rScale={rScale} />
          <Zoom
            width={width}
            height={height}
            scaleXMin={1 / 2}
            scaleXMax={4}
            scaleYMin={1 / 2}
            scaleYMax={4}
            initialTransformMatrix={initialTransform}
          >
            {(zoom) => {
              return (
                <>
                  {loading && <GraphLoading />}
                  <ZoomControls zoom={zoom} />
                  <svg
                    height={height}
                    width={width}
                    style={{ backgroundColor: "white" }}
                    ref={(el) => {
                      (zoom.containerRef as any).current = el;
                      svgRef.current = el;
                    }}
                  >
                    <rect
                      width={width}
                      x={0}
                      height={height}
                      fill="#FBFBFB"
                      onTouchStart={zoom.dragStart}
                      onTouchMove={zoom.dragMove}
                      onTouchEnd={zoom.dragEnd}
                      onMouseDown={zoom.dragStart}
                      onMouseMove={zoom.dragMove}
                      onMouseUp={zoom.dragEnd}
                      onMouseLeave={() => {
                        if (zoom.isDragging) zoom.dragEnd();
                      }}
                      onClick={() => setHighlightedItem(null)}
                      style={{
                        touchAction: "none",
                        cursor: zoom.isDragging ? "grabbing" : "grab",
                      }}
                      strokeWidth="1"
                      stroke="#999999"
                    />
                    <AnimatedZoomGroup zoom={zoom.transformMatrix}>
                      <ProductSpacePlot
                        processedData={processedData}
                        metaDataLookup={metaDataLookup}
                        highlightedItem={highlightedItem}
                        select={select}
                        setRingItem={setRingItem}
                        setHighlightedItem={setHighlightedItem}
                      />
                      {selection && (
                        <HighlightOverlay
                          linkLookup={linkLookup}
                          selection={selection}
                          nodeLookup={nodeLookup}
                          tip={false}
                          metaDataLookup={metaDataLookup}
                          setHighlightedItem={setHighlightedItem}
                        />
                      )}
                      {highlightedItem && (
                        <HighlightOverlay
                          linkLookup={linkLookup}
                          selection={highlightedItem}
                          nodeLookup={nodeLookup}
                          tip={true}
                          metaDataLookup={metaDataLookup}
                          setHighlightedItem={setHighlightedItem}
                        />
                      )}
                      {zoom.transformMatrix.scaleX < 2 &&
                        clusterColors.map((c, i) => (
                          <text
                            key={centroids[i].FID}
                            id={centroids[i].FID}
                            textAnchor="middle"
                            x={xScale(centroids[i].X)}
                            y={yScale(centroids[i].Y)}
                            style={{ pointerEvents: "none" }}
                            stroke="#FBFBFB"
                            strokeWidth="5"
                            strokeLinecap="butt"
                            strokeLinejoin="miter"
                            paintOrder="stroke"
                            fontWeight="800"
                          >
                            {c.cluster_name_short.toUpperCase()}
                          </text>
                        ))}
                    </AnimatedZoomGroup>
                  </svg>
                </>
              );
            }}
          </Zoom>
        </div>
      </VisualizationContentContainer>
      <VisualizationBottomRowContainer>
        <VisualizationHandlesContainer>
          {currentColorBySelection === ColorBy.Green && (
            <CategoryLabels
              categories={categoriesForLegend}
              allowToggle={true}
              hiddenCategories={hiddenCategories}
              setHiddenCategories={setHiddenCategories}
              resetText="Show All Products"
              fullWidth={true}
            />
          )}
          {currentColorBySelection === ColorBy.Sector && (
            <CategoryLabels
              categories={categoriesForLegend}
              allowToggle={true}
              hiddenCategories={hiddenCategories}
              setHiddenCategories={setHiddenCategories}
              resetText="Show All Sectors"
              fullWidth={true}
            />
          )}
          {currentColorBySelection === ColorBy.Cluster && (
            <CategoryLabels
              categories={categoriesForLegend}
              allowToggle={true}
              hiddenCategories={hiddenCategories}
              setHiddenCategories={setHiddenCategories}
              resetText="Show All Sectors"
              fullWidth={true}
            />
          )}
          {currentColorBySelection === ColorBy.Complexity && (
            <ComplexityColorScaleLegend />
          )}
        </VisualizationHandlesContainer>
      </VisualizationBottomRowContainer>
    </>
  );
};

export default ProductSpace;
