import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./store/store";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import "./fonts.css";
import {
  RouterProvider,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import { router } from "./routing";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import ReactGA from "react-ga4";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import l10n from "./contextProviders/getFluentLocalizationContext";
import { LocalizationProvider } from "@fluent/react";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { HelmetProvider } from "react-helmet-async";
import { TutorialProvider } from "./components/tutorial/TutorialContext";

if (process.env.REACT_APP_SENTRY_DSN && process.env.REACT_APP_SENTRY_ENV) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
    ],
    environment: process.env.REACT_APP_SENTRY_ENV,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.8,
  });
} else {
  console.log("Build is running without Sentry.");
}

// SETTING UP APOLLO CLIENT
const client = new ApolloClient({
  uri:
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_API_STAGING_REWRITE_BASE_URL + "/graphql"
      : process.env.REACT_APP_API_REWRITE_BASE_URL + "/graphql",
  cache: new InMemoryCache(),
});

if (process.env.REACT_APP_GOOGLE_ANALYTICS_GA4_STREAM_ID) {
  ReactGA.initialize([
    {
      trackingId: process.env.REACT_APP_GOOGLE_ANALYTICS_GA4_STREAM_ID,
    },
  ]);
}

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <TutorialProvider>
        <Provider store={store}>
          <ApolloProvider client={client}>
            <LocalizationProvider l10n={l10n}>
              {/* @ts-ignore */}
              <RouterProvider router={router}>
                <QueryParamProvider
                  adapter={ReactRouter6Adapter}
                  options={{ removeDefaultsFromUrl: true }}
                >
                  <div id="overlay-portal-container" />
                </QueryParamProvider>
              </RouterProvider>
            </LocalizationProvider>
          </ApolloProvider>
        </Provider>
      </TutorialProvider>
    </HelmetProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
