import { NavigationBarContainer } from "../styling/GlobalGrid";
import {
  Bar,
  CenterBar,
  HamburgerMenuButton,
  MenuHeaderContainer,
  ContentPageMenuHeaderContainer,
  MenuBodyContainer,
  Icon,
} from "./styles";
import {
  Link,
  LinkProps,
  To,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import GrowthLabLogo from "../../assets/images/GL_logo_white.png";
import { ReactElement, useEffect, useRef, useState } from "react";
import VizTypeSelectorButton from "../buttons/VizTypeSelectorButton";
import { VizType } from "../../visualization/Utils";
import GlossaryModalButton from "../buttons/GlossaryModalButton";
import DataNotesModalButton from "../buttons/DataNotesModalButton";
import { getLocationLevelFromStringLocationId } from "../../sharedUtilities/Utils";
import { LocationLevel } from "../../graphql/types";
import useFetchMetadata, {
  MetadataFetchType,
} from "../../sharedUtilities/useFetchMetadata";
import HamburgerMenu from "./HamburgerMenu";
import { getReferenceLocation } from "../../visualization/charts/geomap/Utils";
import SubregionMapModalButton from "../buttons/SubregionMapModalButton";
import GuideModalButton from "../buttons/GuideModalButton";
import DownloadModalButton from "../buttons/DownloadModalButton";
import ShareModalButton from "../buttons/ShareModalButton";
import {
  mergeParams,
  usePageQueryParams,
} from "../../visualization/defaultSettings";
import { useOldCountryIds } from "../../routing/Utils";
import ExploreFurther from "./ExploreFurther";
import {
  DisabledVizTypeTooltipContainer,
  MenuHeaderGrowthLabIcon,
  MenuRow,
  MenuRowIcon,
  MenuRowLabel,
  NotAvailableTooltip,
  OpenPanelArrow,
  OpenPanelArrowButtonContainer,
  OpenPanelArrowContainer,
  OptionsList,
  OptionsListRow,
  VizTypeList,
} from "./Components";
import { googleAnalyticsCustomEvent } from "../../sharedUtilities/googleAnalyticsEvent";

const DisabledVizTypeOption = ({ children }: any) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [positionFromTop, setPositionFromTop] = useState<number | undefined>(
    undefined,
  );
  const disabledVizTypeContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (disabledVizTypeContainerRef && disabledVizTypeContainerRef.current) {
      const node = disabledVizTypeContainerRef.current;
      const { top, height } = node.getBoundingClientRect();
      setPositionFromTop(top + height / 2);
    }
  }, [disabledVizTypeContainerRef.current]);

  const style =
    positionFromTop !== undefined
      ? {
          top: `${positionFromTop}px`,
        }
      : undefined;

  let tooltipNotification;
  if (isHovered && positionFromTop !== undefined) {
    tooltipNotification = (
      <NotAvailableTooltip style={style}>
        Complexity visualizations are not available for regions and subregions
      </NotAvailableTooltip>
    );
  } else {
    tooltipNotification = null;
  }

  return (
    <DisabledVizTypeTooltipContainer
      ref={disabledVizTypeContainerRef}
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
    >
      {children}
      {tooltipNotification}
    </DisabledVizTypeTooltipContainer>
  );
};

interface ValidateNavigationInput {
  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>;
  currentPathName: string;
  toPath: To;
}

const validateNavigation = ({
  event,
  currentPathName,
  toPath,
}: ValidateNavigationInput) => {
  if (currentPathName === toPath) {
    event.preventDefault();
  }
};

function ValidatedNavigationLink({ to, children }: LinkProps): ReactElement {
  const { pathname: currentPathName } = useLocation();
  return (
    <Link
      to={to}
      onClick={(event) =>
        validateNavigation({ event, currentPathName, toPath: to })
      }
    >
      {children}
    </Link>
  );
}

export enum NavigationBarPageType {
  ContentPage = "ContentPage",
}

export const navigationBarElementId = "navigation-bar-container";

const NavigationBar = ({ pageType }: any) => {
  // redirect url if old ids are present
  useOldCountryIds();
  const location = useLocation();

  const currentVizType = location.pathname.split("/")[2] as any;
  const [query] = usePageQueryParams();
  const [search] = useSearchParams();

  const existingQuery = {
    ...Object.fromEntries(
      Array.from(search.entries()).filter(
        ([, v]) => v !== "undefined" && v !== undefined,
      ),
    ),
  };
  if (query.view && query.view?.length > 0) {
    existingQuery.view = query.view;
  }
  const { importer: currentImporter, exporter: currentExporter } = query;
  const [isNavigationBarOpen, setIsNavigationBarOpen] =
    useState<boolean>(false);
  let [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [navigationBarOpenedOnce, setNavigationBarOpenedOnce] =
    useState<boolean>(false);
  const [navigationBarContainerClassName, setNavigationBarContainerClassName] =
    useState<string | undefined>(undefined);

  const primaryOptionsRowId = "options-row-primary";

  let hamburgerMenuContents: JSX.Element | null;
  if (isMenuOpen) {
    hamburgerMenuContents = (
      <HamburgerMenu
        closeMenu={() => {
          setIsMenuOpen(false);
          setIsNavigationBarOpen(false);
        }}
      />
    );
  } else {
    hamburgerMenuContents = null;
  }

  const handleOpenPanelArrow = () => {
    if (!navigationBarOpenedOnce) setNavigationBarOpenedOnce(true);
    const newIsNavigationBarOpen = !isNavigationBarOpen;

    // Add Google Analytics event when opening the panel
    if (!isNavigationBarOpen) {
      googleAnalyticsCustomEvent("leftnav", "click", "menu", "explore");
    }

    /*
        NOTE:

        This is a really clunky way of handling the transition for the primary options row,
        i.e., the buttons for Guide, Download, and Data Alerts.

        The problem:

        When the navigation bar is collapsed, these 3 option buttons remain visible; when the
        bar is expanded, these 3 option buttons are still visible, accompanied by 3 additional
        buttons (subregion map, glossary, and share).

        The 3 new options are newly-rendered elements, and thus trigger the delayed visibility
        animation; however, the 3 primary options are not newly rendered, and there is no clean way
        in CSS to re-trigger the delayed visibility animation for those options.

        The result is that in the expansion and closing animation, the 3 primary option buttons
        reflow between horizontal and vertical, which is visually messy.

        The following solution uses JavaScript to force a temporary opacity switch for
        the primary options row, just long enough to hide the reflow of the elements. 

        */
    const primaryOptionsRow = document.querySelector(
      `#${primaryOptionsRowId}`,
    ) as HTMLElement;
    if (primaryOptionsRow) {
      primaryOptionsRow.style.opacity = "0";
      primaryOptionsRow.style.animation = "none";

      setTimeout(() => {
        primaryOptionsRow.style.opacity = "1";
      }, 300);
    }

    setIsNavigationBarOpen(newIsNavigationBarOpen);
  };

  const handleHamburgerMenuClick = () => {
    if (!isMenuOpen) {
      setIsMenuOpen(true);
      setIsNavigationBarOpen(true);
    } else {
      setIsMenuOpen(!isMenuOpen);
    }
  };

  useEffect(() => {
    let newNavigationBarContainerClassName;
    if (isNavigationBarOpen) {
      newNavigationBarContainerClassName = "expanded";
    } else if (!isNavigationBarOpen && navigationBarOpenedOnce) {
      newNavigationBarContainerClassName = "closed";
    } else {
      newNavigationBarContainerClassName = undefined;
    }
    setNavigationBarContainerClassName(newNavigationBarContainerClassName);
  }, [isNavigationBarOpen, navigationBarOpenedOnce]);

  const {
    metadataStatus,
    metadata: countryMetadata,
    error,
  } = useFetchMetadata({ metadataFetchType: MetadataFetchType.Country });

  if (pageType === NavigationBarPageType.ContentPage) {
    const handleContentPageHamburgerMenuClick = () => {
      if (!isMenuOpen) {
        setIsMenuOpen(true);
        setIsNavigationBarOpen(true);
      } else {
        setIsMenuOpen(false);
        setIsNavigationBarOpen(false);
      }
    };

    return (
      <NavigationBarContainer
        id={navigationBarElementId}
        className={`${navigationBarContainerClassName} contentPage`}
      >
        <ContentPageMenuHeaderContainer>
          <HamburgerMenuButton onClick={handleContentPageHamburgerMenuClick}>
            Menu
            <Icon>
              <Bar style={{ opacity: isMenuOpen ? 0 : undefined }} />
              <CenterBar className={isMenuOpen ? "close__menu" : undefined} />
              <Bar style={{ opacity: isMenuOpen ? 0 : undefined }} />
            </Icon>
          </HamburgerMenuButton>
        </ContentPageMenuHeaderContainer>
        <MenuBodyContainer>{hamburgerMenuContents}</MenuBodyContainer>
      </NavigationBarContainer>
    );
  } else {
    let disabledButtonClassName;
    let referenceLocation = getReferenceLocation({
      exporter: currentExporter,
      importer: currentImporter,
    });
    let referenceLocationLevel =
      getLocationLevelFromStringLocationId(referenceLocation);
    if (referenceLocationLevel === LocationLevel.Group) {
      disabledButtonClassName = "disabledVisOption";
    } else {
      disabledButtonClassName = null;
    }
    const productSpaceEntry = (
      <>
        <MenuRowIcon>
          <VizTypeSelectorButton vizType={VizType.ProductSpace} />
        </MenuRowIcon>
        {isNavigationBarOpen && (
          <MenuRowLabel>
            Depicts the connectedness between products, based on the similarity
            of know-how required to produce them; helps to reveal paths to
            diversify a country's economy based on the connectedness of its
            existing knowhow.
          </MenuRowLabel>
        )}
      </>
    );
    const feasibilityEntry = (
      <>
        <MenuRowIcon>
          <VizTypeSelectorButton vizType={VizType.Feasibility} />
        </MenuRowIcon>
        <MenuRowLabel>
          Displays a country's opportunities for export diversification based on
          what it currently exports.
        </MenuRowLabel>
      </>
    );
    let productSpaceVisOptionMenuItem, feasibilityVisOptionMenuItem;
    if (disabledButtonClassName) {
      productSpaceVisOptionMenuItem = (
        <DisabledVizTypeOption>
          <MenuRow className={disabledButtonClassName}>
            {productSpaceEntry}
          </MenuRow>
        </DisabledVizTypeOption>
      );

      feasibilityVisOptionMenuItem = (
        <DisabledVizTypeOption>
          <MenuRow className={disabledButtonClassName}>
            {feasibilityEntry}
          </MenuRow>
        </DisabledVizTypeOption>
      );
    } else {
      productSpaceVisOptionMenuItem = (
        <ValidatedNavigationLink
          onClick={() =>
            googleAnalyticsCustomEvent(
              "leftnav",
              "click",
              "productspace",
              "explore",
            )
          }
          to={`/explore/productspace?${mergeParams(VizType.ProductSpace, existingQuery)}`}
        >
          <MenuRow
            className={
              currentVizType === VizType.ProductSpace ? "selected" : ""
            }
          >
            {productSpaceEntry}
          </MenuRow>
        </ValidatedNavigationLink>
      );

      feasibilityVisOptionMenuItem = (
        <ValidatedNavigationLink
          onClick={() =>
            googleAnalyticsCustomEvent(
              "leftnav",
              "click",
              "growthopp",
              "explore",
            )
          }
          to={`/explore/feasibility?${mergeParams(VizType.Feasibility, existingQuery)}`}
        >
          <MenuRow
            className={currentVizType === VizType.Feasibility ? "selected" : ""}
          >
            {feasibilityEntry}
          </MenuRow>
        </ValidatedNavigationLink>
      );
    }

    let moreOptionsRow: any;
    if (isNavigationBarOpen === true) {
      moreOptionsRow = (
        <OptionsListRow>
          <SubregionMapModalButton
            menuOptionClassName={navigationBarContainerClassName}
            isNavigationBarOpen={isNavigationBarOpen}
          />
          <ShareModalButton
            menuOptionClassName={navigationBarContainerClassName}
            isNavigationBarOpen={isNavigationBarOpen}
          />
          <GlossaryModalButton
            menuOptionClassName={navigationBarContainerClassName}
            isNavigationBarOpen={isNavigationBarOpen}
          />
        </OptionsListRow>
      );
    } else {
      moreOptionsRow = null;
    }
    return (
      <NavigationBarContainer
        id={navigationBarElementId}
        className={navigationBarContainerClassName}
      >
        <MenuHeaderContainer>
          <MenuRow>
            <MenuHeaderGrowthLabIcon>
              <Link to={"/"}>
                <img
                  src={GrowthLabLogo}
                  className={navigationBarContainerClassName}
                  alt="The Atlas of Economic Complexity"
                />
              </Link>
            </MenuHeaderGrowthLabIcon>
          </MenuRow>

          <HamburgerMenuButton onClick={handleHamburgerMenuClick}>
            Menu
            <Icon>
              <Bar style={{ opacity: isMenuOpen ? 0 : undefined }} />
              <CenterBar className={isMenuOpen ? "close__menu" : undefined} />
              <Bar style={{ opacity: isMenuOpen ? 0 : undefined }} />
            </Icon>
          </HamburgerMenuButton>
        </MenuHeaderContainer>
        <MenuBodyContainer>
          {hamburgerMenuContents}
          <OpenPanelArrowContainer
            className={navigationBarContainerClassName}
            onClick={handleOpenPanelArrow}
          >
            <OpenPanelArrowButtonContainer>
              <OpenPanelArrow
                className={isNavigationBarOpen ? "closeArrow" : "openArrow"}
              />
            </OpenPanelArrowButtonContainer>
          </OpenPanelArrowContainer>
          <VizTypeList>
            <div className="analyzeTradeFlows" style={{ overflow: "hidden" }}>
              <ValidatedNavigationLink
                onClick={() =>
                  googleAnalyticsCustomEvent(
                    "leftnav",
                    "click",
                    "treemap",
                    "explore",
                  )
                }
                to={`/explore/treemap?${mergeParams(VizType.Tree, existingQuery)}`}
              >
                <MenuRow
                  className={currentVizType === VizType.Tree ? "selected" : ""}
                >
                  <MenuRowIcon>
                    <VizTypeSelectorButton vizType={VizType.Tree} />
                  </MenuRowIcon>
                  <MenuRowLabel>
                    Displays the breakdown of exports or imports by location or
                    product, in a given year.
                  </MenuRowLabel>
                </MenuRow>
              </ValidatedNavigationLink>

              <ValidatedNavigationLink
                onClick={() =>
                  googleAnalyticsCustomEvent(
                    "leftnav",
                    "click",
                    "geo",
                    "explore",
                  )
                }
                to={`/explore/geomap?${mergeParams(VizType.Geo, existingQuery)}`}
              >
                <MenuRow
                  className={currentVizType === VizType.Geo ? "selected" : ""}
                >
                  <MenuRowIcon>
                    <VizTypeSelectorButton vizType={VizType.Geo} />
                  </MenuRowIcon>
                  <MenuRowLabel>
                    A world map depicting the value of trade flows, by location
                    or by product, in a given year.
                  </MenuRowLabel>
                </MenuRow>
              </ValidatedNavigationLink>

              <ValidatedNavigationLink
                onClick={() =>
                  googleAnalyticsCustomEvent(
                    "leftnav",
                    "click",
                    "stack",
                    "explore",
                  )
                }
                to={`/explore/overtime?${mergeParams(
                  VizType.OverTime,
                  existingQuery,
                )}`}
              >
                <MenuRow
                  className={
                    currentVizType === VizType.OverTime ? "selected" : ""
                  }
                >
                  <MenuRowIcon>
                    <VizTypeSelectorButton vizType={VizType.OverTime} />
                  </MenuRowIcon>
                  <MenuRowLabel>
                    Displays the breakdown of exports or imports, by location or
                    by product, over a specified time period.
                  </MenuRowLabel>
                </MenuRow>
              </ValidatedNavigationLink>
              <ValidatedNavigationLink
                onClick={() =>
                  googleAnalyticsCustomEvent(
                    "leftnav",
                    "click",
                    "marketshare",
                    "explore",
                  )
                }
                to={`/explore/marketshare?${mergeParams(
                  VizType.MarketShare,
                  existingQuery,
                )}`}
              >
                <MenuRow
                  className={
                    currentVizType === VizType.MarketShare ? "selected" : ""
                  }
                >
                  <MenuRowIcon>
                    <VizTypeSelectorButton vizType={VizType.MarketShare} />
                  </MenuRowIcon>
                  <MenuRowLabel>
                    Displays a location's share of the global market across 10
                    product sectors.
                  </MenuRowLabel>
                </MenuRow>
              </ValidatedNavigationLink>
            </div>
            <div
              className="analyzeCountryComplexity"
              style={{ overflow: "hidden" }}
            >
              {productSpaceVisOptionMenuItem}
              {feasibilityVisOptionMenuItem}
            </div>
          </VizTypeList>
          {isNavigationBarOpen && <ExploreFurther />}
          <OptionsList className="learnMoreAndShare">
            <OptionsListRow id={primaryOptionsRowId}>
              <GuideModalButton
                menuOptionClassName={navigationBarContainerClassName}
                isNavigationBarOpen={isNavigationBarOpen}
              />
              <DownloadModalButton
                menuOptionClassName={navigationBarContainerClassName}
                isNavigationBarOpen={isNavigationBarOpen}
              />
              <DataNotesModalButton
                menuOptionClassName={navigationBarContainerClassName}
                isNavigationBarOpen={isNavigationBarOpen}
              />
            </OptionsListRow>
            {moreOptionsRow}
          </OptionsList>
        </MenuBodyContainer>
      </NavigationBarContainer>
    );
  }
};

export default NavigationBar;
