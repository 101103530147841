// @ts-nocheck
import {
  useState,
  useContext,
  memo,
  useCallback,
  useMemo,
  useEffect,
} from "react";
import { ChartContainerSizeContext } from "../../components/GenericResizeContainer";
import styled from "@emotion/styled";
import { curveMonotoneX, groups, line, scaleLinear } from "d3";
import { chartMargin } from "../../components/styles";
import { UIView, getColorMap } from "../../Utils";
import transformProducts from "./transformProducts";
import XAxisYear from "../../components/XAxisYear";
import YAxisPercentage from "../../components/YAxisPercentage";
import MarketShareLineGroup from "./MarketShareLine";
import { determineClosestXValue } from "../../components/Tooltip";
import useFetchMetadata, {
  MetadataFetchStatus,
  MetadataFetchType,
} from "../../../sharedUtilities/useFetchMetadata";
import YAxisGridlines from "../../components/YAxisGridlines";
import { removeOtherProducts } from ".";
import GraphNotice, { GraphNoticeType } from "../../components/GraphNotice";
import { useDownload } from "../../DownloadContext";
import { hs92Names } from "../../../sharedUtilities/Utils";

const Root = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;

  & svg {
    margin: 0px;
    padding: 0px;
  }
`;

const YAxisLabel = styled.div`
  height: 100%;
  width: ${chartMargin.left / 2}px;
  position: absolute;
  top: 0px;
  left: 0px;
  padding-top: ${chartMargin.bottom}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 1rem;
  color: black;
  box-sizing: border-box;
  pointer-events: none;

  writing-mode: vertical-lr;
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  transform: rotate(-180deg);
`;

const Chart = ({
  inputData,
  queryVariables,
  tradeDirection,
  hiddenCategories,
  setXAxisTooltipValue,
  handleTooltip,
}: any) => {
  const { dataRef } = useDownload();
  const { chartWidth, chartHeight } = useContext(ChartContainerSizeContext);
  const { exporter, productClass, yearMin, yearMax } = queryVariables;

  const [yAxisExtentDomain, setYAxisExtentDomain] = useState<
    { min: number; max: number } | undefined
  >(undefined);
  const { svgRef } = useDownload();
  const setYAxisDomain = useCallback(
    (domainToSet: { min: number; max: number }) => {
      if (
        !yAxisExtentDomain ||
        (yAxisExtentDomain &&
          (domainToSet.min !== yAxisExtentDomain.min ||
            domainToSet.max !== yAxisExtentDomain.max))
      ) {
        setYAxisExtentDomain({ min: domainToSet.min, max: domainToSet.max });
      }
    },
    [yAxisExtentDomain],
  );

  const useColorMap = useMemo(
    () => getColorMap({ view: UIView.Products, productClass }),
    [productClass],
  );
  let noDataToDisplay: boolean = false;
  const hs92MetadataFetch = useFetchMetadata({
    metadataFetchType: MetadataFetchType.ProductsHs92,
  });
  const hs12MetadataFetch = useFetchMetadata({
    metadataFetchType: MetadataFetchType.ProductsHs12,
  });

  const sitcMetadataFetch = useFetchMetadata({
    metadataFetchType: MetadataFetchType.ProductsSitc,
  });
  const {
    metadataStatus: productYearMetadataStatus,
    metadata: productYearMetadata,
    error: productYearError,
  } = useFetchMetadata({
    metadataFetchType: MetadataFetchType.ProductYear,
    yearRange: { yearMin, yearMax },
    productClass: productClass,
  });

  let { data } = inputData;

  let filteredData = data
    .filter((d: any) => {
      const productId = d.productId;

      if (hiddenCategories.includes(productId)) {
        return false;
      } else {
        return true;
      }
    })
    .filter(removeOtherProducts);

  let transformedData: any[] | undefined = undefined;
  let groupedBySectorData: any[] | undefined = undefined;

  if (productYearMetadataStatus === MetadataFetchStatus.Success) {
    transformedData = transformProducts({
      exporter,
      inputData: filteredData,
      productYearData: productYearMetadata,
    });

    if (transformedData) {
      let maxGlobalShareValue = Math.max(
        ...transformedData.map(
          ({ globalMarketShare }: any) => globalMarketShare,
        ),
      );

      if (maxGlobalShareValue) {
        setYAxisDomain({ min: 0, max: maxGlobalShareValue });
      }

      groupedBySectorData = groups(
        transformedData,
        ({ productId }: any) => productId,
      );
    } else {
      noDataToDisplay = true;
    }
  }

  /*
    In the scale generator below for xScale, note that we are using d3.scaleLinear().
    Another candidate scale generator that would be more semantically aligned with the
    variable represented on the x-axis is d3.scaleTime(). However, the x-axis variable
    is encoded as discrete years, and so to use d3.scaleTime(), all of the `year` values
    would need to be coerced into JS Date() objects. This would introduce a number of
    unnecessary code acrobatics, as well as the need to manage the oddities of JS Date()
    representations, e.g., representations across time zones. Thus, the choice here is
    to use d3.scaleLinear() and encode `year` as a continuous quantitative variable.
    */

  const xScale = useMemo(() => {
    return scaleLinear()
      .domain([yearMin, yearMax])
      .range([chartMargin.left, chartWidth - chartMargin.right]);
  }, [yearMin, yearMax, chartWidth]);

  const yScale = useMemo(() => {
    const scale = scaleLinear().range([
      chartHeight - chartMargin.bottom,
      chartMargin.top,
    ]);

    if (yAxisExtentDomain) {
      scale.domain([0, yAxisExtentDomain.max]).nice();
    }

    return scale;
  }, [chartHeight, yAxisExtentDomain]);

  const marketLineGenerator = useMemo(() => {
    return ({ x, y }: any) => {
      return line()
        .x((d: any) => x(d.year))
        .y((d: any) => y(d.globalMarketShare))
        .defined((d: any) => d.year && d.globalMarketShare)
        .curve(curveMonotoneX);
    };
  }, []);

  const lineGenerator = useMemo(
    () => marketLineGenerator({ x: xScale, y: yScale }),
    [marketLineGenerator, xScale, yScale],
  );

  const pointGenerator = useMemo(() => {
    return ({ year, value }: any) => {
      return { cx: xScale(year), cy: yScale(value) };
    };
  }, [xScale, yScale]);

  useEffect(() => {
    dataRef.current = transformedData?.map((d: any) => ({
      Sector: hs92Names.get(d.productId),
      Year: d.year,
      "Market Share": d.globalMarketShare,
    }));
  }, [transformedData, dataRef]);
  let contentToRender: any = null;
  if (transformedData === undefined && noDataToDisplay === true) {
    contentToRender = <GraphNotice graphNoticeType={GraphNoticeType.NoData} />;
  } else {
    contentToRender = (
      <>
        <YAxisLabel>Share of World Market by Sector</YAxisLabel>
        <svg
          style={{ backgroundColor: "white" }}
          width={chartWidth}
          height={chartHeight}
          onMouseMove={(e) => {
            if (!transformedData || !Array.isArray(transformedData)) {
              setXAxisTooltipValue(null);
              handleTooltip({ data: [] });
              return;
            }

            determineClosestXValue({
              e,
              xScale,
              containerSize: { width: chartWidth, height: chartHeight },
              chartMargin,
              setXAxisTooltipValue,
            });

            const validData = transformedData.filter((d) => {
              return (
                d && typeof d === "object" && "year" in d && d.year != null
              );
            });

            handleTooltip({ data: validData });
          }}
          onMouseLeave={() => {
            setXAxisTooltipValue(null);
            handleTooltip({ data: [] });
          }}
          ref={svgRef}
        >
          <YAxisGridlines
            scale={yScale}
            containerSize={{ width: chartWidth, height: chartHeight }}
            chartMargin={chartMargin}
          />

          {groupedBySectorData &&
            groupedBySectorData.map((groupedBySector: any) => {
              let [sectorProductId, sectorYearData] = groupedBySector;
              let lineKeyString = `${exporter}_${productClass}_${sectorProductId}`;
              return (
                <MarketShareLineGroup
                  key={lineKeyString}
                  sectorProductId={sectorProductId}
                  lineData={sectorYearData}
                  lineGenerator={lineGenerator}
                  pointGenerator={pointGenerator}
                  colorMap={useColorMap}
                />
              );
            })}

          <XAxisYear
            scale={xScale}
            containerSize={{ width: chartWidth, height: chartHeight }}
          />
          <YAxisPercentage
            scale={yScale}
            containerSize={{ width: chartWidth, height: chartHeight }}
            chartMargin={chartMargin}
          />
        </svg>
      </>
    );
  }

  return <Root>{contentToRender}</Root>;
};

export default memo(Chart);
