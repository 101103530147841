import { useState, useCallback, memo, useMemo, useEffect } from "react";
import {
  convertProductStringToNumberId,
  otherGroupId,
  undeclaredCountryId,
  unidentifiedServicesCountryId,
} from "../../../sharedUtilities/Utils";
import {
  ProductLevel,
  TradeDirection,
  UIView,
  determineAPICallLocationOrder,
  getTradeDirection,
} from "../../Utils";
import determineEndpointFacet from "../../../graphql/determineEndpointFacet";
import { useQuery } from "@apollo/client";
import Chart from "./Chart";
import Tooltip, { TooltipContent } from "../../components/Tooltip";
import {
  VisualizationContentContainer,
  VisualizationHandlesContainer,
  VisualizationBottomRowContainer,
} from "../../VizGrid";
import { allProductsDatum } from "../../../graphql/queries/getProductsMetadata";
import ColorLegend from "./Legend";
import { mapQueryArgumentsToAPIEndpointInputs } from "../../../graphql/Utils";
import { useOutletContext } from "react-router-dom";

import { getReferenceLocation } from "./Utils";
import GraphNotice, { GraphNoticeType } from "../../components/GraphNotice";
import { usePageQueryParams } from "../../defaultSettings";
import GraphLoading from "../../components/GraphLoading";
import { useTotalValue } from "../../TotalValueContext";

const Geomap = () => {
  const [
    {
      exporter,
      importer,
      product: productId,
      view,
      productClass,
      year,
      tradeFlow: currentTradeFlow,
      servicesClass,
    },
  ] = usePageQueryParams();

  const [tooltipContent, setTooltipContent] = useState(undefined);

  const [totalValue, setTotalValue] = useTotalValue();
  const [currentTradeDirection, setCurrentTradeDirection] = useState<
    TradeDirection | undefined
  >(undefined);

  const { visualizationElementRef: geomapElement }: any = useOutletContext();

  const useLocationValue = useMemo(() => {
    return getReferenceLocation({ exporter, importer });
  }, [exporter, importer]);

  const narrowedGeomapInputVariables = useMemo(() => {
    return mapQueryArgumentsToAPIEndpointInputs({
      exporter,
      importer,
      view,
    });
  }, [exporter, importer, view]);

  const useProductLevel = useMemo(() => {
    if (view === UIView.Markets) {
      if (productId === allProductsDatum.productId) {
        return ProductLevel.ProductSection;
      } else {
        return undefined;
      }
    }
  }, [view, productId]);

  const geoMapInputVariables = useMemo(() => {
    return {
      productClass: productClass,
      yearMax: year,
      yearMin: year,
      productLevel: useProductLevel,
      servicesClass,
      productId:
        productId === allProductsDatum.productId
          ? undefined
          : convertProductStringToNumberId(productId),
      ...narrowedGeomapInputVariables,
    };
  }, [
    productClass,
    year,
    useProductLevel,
    servicesClass,
    productId,
    narrowedGeomapInputVariables,
  ]);

  const { queryToUse, queryIsInvalid } = useMemo(() => {
    return determineEndpointFacet({
      view,
      variables: geoMapInputVariables as any,
    });
  }, [view, geoMapInputVariables]);

  const onHover = useCallback(
    (datum: any) => {
      if (!datum) {
        setTooltipContent(undefined);
      } else {
        const { countryId, nameEn, exportValue, importValue, name } =
          datum.properties;
        let mappedDatum: any = {
          countryId,
          nameEn: nameEn || name,
          exportValue,
          importValue,
          ...datum,
        };
        setTooltipContent(mappedDatum);
      }
    },
    [setTooltipContent],
  );

  const {
    loading,
    error,
    previousData,
    data: successResponse,
  } = useQuery(queryToUse, {
    variables: geoMapInputVariables,
    skip: queryIsInvalid,
  });

  const { data: oldData = [] } = previousData || {};
  const { data: currentData = [] } = successResponse || {};
  const data = loading ? oldData : currentData;

  const filteredData = useMemo(() => {
    const excludedIds = [
      otherGroupId,
      undeclaredCountryId,
      unidentifiedServicesCountryId,
    ];
    return data.filter(
      (item: any) => !excludedIds.includes(item.partnerCountryId),
    );
  }, [data]);

  const legendElem = useMemo(() => {
    if (exporter || productId) {
      return (
        <ColorLegend
          data={filteredData}
          location={useLocationValue}
          tradeFlow={currentTradeFlow}
        />
      );
    }
    return null;
  }, [exporter, productId, filteredData, useLocationValue, currentTradeFlow]);

  const tooltipExplanationElement = useMemo(() => {
    if (tooltipContent) {
      return (
        <TooltipContent
          datum={tooltipContent}
          view={view}
          totalValue={totalValue}
          tradeDirection={currentTradeDirection}
          productClass={productClass}
          fillColor={tooltipContent.fillColor}
        />
      );
    }
    return null;
  }, [tooltipContent, view, totalValue, currentTradeDirection, productClass]);

  useEffect(() => {
    let { locationForAPI, partnerForAPI } = determineAPICallLocationOrder({
      variables: geoMapInputVariables as any,
    });

    let computedTradeDirection = getTradeDirection({
      exporter,
      importer,
      locationForAPI,
      partnerForAPI,
    });
    if (
      currentTradeDirection === undefined ||
      currentTradeDirection !== computedTradeDirection
    ) {
      setCurrentTradeDirection(computedTradeDirection);
    }
  }, [exporter, importer, currentTradeDirection, geoMapInputVariables]);
  const queryVariables = useMemo(
    () => ({ exporter, importer, productId, year, view }),
    [exporter, importer, productId, year, view],
  );
  return (
    <>
      <Tooltip
        explanation={tooltipExplanationElement}
        cursor="default"
        overrideStyles={true}
      >
        <VisualizationContentContainer ref={geomapElement}>
          {successResponse?.data?.length > 0 && (
            <Chart
              handleTooltip={onHover}
              inputData={filteredData}
              queryVariables={queryVariables}
              setTotalValue={setTotalValue}
              tradeDirection={currentTradeDirection}
              tradeFlow={currentTradeFlow}
            />
          )}
          {loading && <GraphLoading />}
          {successResponse?.data?.length === 0 && (
            <GraphNotice graphNoticeType={GraphNoticeType.NoData} />
          )}
          {error && <GraphNotice graphNoticeType={GraphNoticeType.Error} />}
        </VisualizationContentContainer>
      </Tooltip>
      <VisualizationBottomRowContainer>
        <VisualizationHandlesContainer>
          {legendElem}
        </VisualizationHandlesContainer>
      </VisualizationBottomRowContainer>
    </>
  );
};

const GeomapEntry = () => {
  return <Geomap />;
};

export default memo(GeomapEntry);
