/** @jsxImportSource @emotion/react */
import React from "react";
import { Box } from "@mui/material";
import { H2, H3, Paragraph } from "../../components/styling/TextStyling";

import { Link } from "react-router-dom";

const DataSourcesSection = () => {
  return (
    <Box sx={{ mb: 2, mt: 0, pt: 0 }}>
      <H2 style={{ marginTop: "0" }}>
        Data Sources, Classifications and Coverage
      </H2>

      <section>
        <H3>Data Sources</H3>
        <Paragraph>
          Atlas data is compiled from international third-party sources:
        </Paragraph>
        <ul>
          <li>
            <Paragraph>
              <strong>Goods Trade (raw data):</strong> United Nations
              Statistical Division (UN Comtrade)
            </Paragraph>
          </li>
          <li>
            <Paragraph>
              <strong>Services Trade (raw data):</strong> International Monetary
              Fund Direction of Trade Statistics
            </Paragraph>
          </li>
          <li>
            <Paragraph>
              <strong>Economic Indicators:</strong> International Monetary Fund
            </Paragraph>
          </li>
          <li>
            <Paragraph>
              <strong>Price Adjustments:</strong> Federal Reserve Economic Data
              (FRED) Producer Price Index for Industrial Commodities
            </Paragraph>
          </li>
        </ul>
        <Paragraph>
          Atlas trade values are reported in US dollars (USD) while constant
          values are adjusted using the FRED Producer Price Index for Industrial
          Commodities. The base year matches the most recent Atlas data year,
          allowing users to understand values in terms of current purchasing
          power. For historical analyses (e.g., growth rates), constant dollar
          values are used.
        </Paragraph>
      </section>

      <section>
        <H3>Classification Systems</H3>
        <Paragraph>
          The Atlas utilizes two complementary product classification systems:
        </Paragraph>

        <Paragraph>
          <strong>Harmonized System (HS)</strong>
        </Paragraph>
        <ul>
          <li>
            <Paragraph>
              Modern classification covering approximately 5,000 products
            </Paragraph>
          </li>
          <li>
            <Paragraph>Data available from 1995 onwards</Paragraph>
          </li>
          <li>
            <Paragraph>Provides detailed 6-digit product codes</Paragraph>
          </li>
          <li>
            <Paragraph>
              Better suited for contemporary products and industries
            </Paragraph>
          </li>
        </ul>

        <Paragraph>
          <strong>Standard International Trade Classification (SITC)</strong>
        </Paragraph>
        <Paragraph>
          The SITC dataset stands as the Atlas's most comprehensive trade
          resource, unifying multiple classification systems across distinct
          time periods:
        </Paragraph>
        <ul>
          <li>
            <Paragraph>SITC Revision 1 (1962-1976)</Paragraph>
          </li>
          <li>
            <Paragraph>SITC Revision 2 (1977-1994)</Paragraph>
          </li>
          <li>
            <Paragraph>
              HS92 data converted to SITC format (1995 onward)
            </Paragraph>
          </li>
        </ul>
        <Paragraph>
          This careful harmonization delivers a continuous time series of trade
          data, spanning six decades, while maintaining consistent product
          categorization.
        </Paragraph>
        <Paragraph>SITC includes the following key features:</Paragraph>
        <ul>
          <li>
            <Paragraph>
              Traditional classification covering approximately 700 products
            </Paragraph>
          </li>
          <li>
            <Paragraph>Historical data reaching back to 1962</Paragraph>
          </li>
          <li>
            <Paragraph>
              Detailed product identification with up to 4-digit codes
            </Paragraph>
          </li>
          <li>
            <Paragraph>
              Optimal resource for long-term historical analysis and trend
              identification
            </Paragraph>
          </li>
        </ul>

        <Paragraph>
          <strong>Services Data</strong>
        </Paragraph>
        <ul>
          <li>
            <Paragraph>Exports and imports reported unilaterally</Paragraph>
          </li>
          <li>
            <Paragraph>
              Partner countries broadly categorized as "Services Partners"
            </Paragraph>
          </li>
          <li>
            <Paragraph>
              Available for 50-75% of countries in the Atlas
            </Paragraph>
          </li>
          <li>
            <Paragraph>
              Coverage begins from 1980, with gaps due to reporting delays
            </Paragraph>
          </li>
        </ul>
      </section>

      <section>
        <H3>Country Coverage</H3>
        <Paragraph>
          While Atlas Explore provides comprehensive data for all countries and
          territories, Atlas Country Profiles and Rankings focus on a subset
          that meets rigorous inclusion criteria:
        </Paragraph>
        <ul>
          <li>
            <Paragraph>
              Country population threshold of at least 1 million residents
            </Paragraph>
          </li>
          <li>
            <Paragraph>
              Minimum annual trade volume of $1 billion (averaged)
            </Paragraph>
          </li>
          <li>
            <Paragraph>Verified GDP and export data availability</Paragraph>
          </li>
          <li>
            <Paragraph>
              Demonstrated history of consistent, reliable trade reporting
            </Paragraph>
          </li>
        </ul>
      </section>

      <section>
        <H2>Data Cleaning Methodology</H2>
        <Paragraph>
          International trade flows are theoretically recorded twice: as exports
          from the origin country and as imports by the destination country.
          However, in practice, trade values are recorded imperfectly, reported
          inconsistently, or sometimes not reported at all. To address these
          challenges, our research team developed a rigorous three-step approach
          to clean raw trade data and generate reliable estimates of trade flows
          between countries.
        </Paragraph>
        <Paragraph>
          This data cleaning methodology can be summarized in the following
          three steps:
        </Paragraph>
        <ol>
          <li>
            <Paragraph>
              <strong>Value Standardization:</strong> We first correct import
              values, which are reported including the costs of freight and
              insurance (CIF), to enable comparison with exporter-reported
              values that are free on board (FOB). This standardization ensures
              we can accurately compare trade flows from both reporting sources.
            </Paragraph>
          </li>
          <li>
            <Paragraph>
              <strong>Reliability Assessment:</strong> We estimate country
              reliability indices based on the consistency of reported trade
              totals across all exporter and importer combinations over time.
              This assessment helps us determine which countries' reporting
              practices are more dependable.
            </Paragraph>
          </li>
          <li>
            <Paragraph>
              <strong>Trade Value Estimation:</strong> Using data from both
              exporters and importers, we generate final trade value estimates
              by weighting each country's reported data according to their
              reliability scores. This process helps produce more accurate
              measurements of international trade flows.
            </Paragraph>
          </li>
        </ol>
      </section>

      <section>
        <H2>Data Update Cycle</H2>
        <Paragraph>
          The Atlas data update process follows international trade reporting
          patterns and requires significant data processing time to ensure
          quality and consistency.
        </Paragraph>

        <Paragraph>
          <strong>Annual Updates:</strong> We update approximately 95% of Atlas
          data once per year, typically between April and June. This timing is
          driven by country reporting patterns to UN Comtrade, which can take
          12-18 months to reach sufficient coverage for a comprehensive update.
          For example, complete trade data for 2024 would typically become
          available in the Atlas between April and June 2026.
        </Paragraph>

        <Paragraph>
          <strong>Interim Updates:</strong> Throughout the year, we also
          release:
        </Paragraph>
        <ul>
          <li>
            <Paragraph>
              Quarterly revisions as countries submit additional trade data
            </Paragraph>
          </li>
          <li>
            <Paragraph>
              Occasional updates to reflect improvements in our data cleaning
              processes
            </Paragraph>
          </li>
          <li>
            <Paragraph>Minor corrections when necessary</Paragraph>
          </li>
        </ul>
        <Paragraph>
          To stay informed about real-time data availability by country, please
          visit the{" "}
          <a
            href="https://comtradeplus.un.org/Visualization/DADashboard"
            target="_blank"
            rel="noopener noreferrer"
          >
            UN Comtrade Data Availability Dashboard
          </a>
          .
        </Paragraph>
      </section>

      <section>
        <H2>Usage and Citation</H2>

        <H3>Open Access</H3>
        <Paragraph>
          The Atlas of Economic Complexity is a freely available public resource
          designed to advance the understanding of inclusive economic growth.
          Numerous innovative projects have built upon Atlas data and concepts,
          and we actively encourage using our resources for research, analysis,
          and collaboration—while emphasizing the importance of proper
          attribution.
        </Paragraph>

        <H3>Citation Guidelines</H3>
        <Paragraph>
          When incorporating Atlas content in your work, please use these
          citation formats:
        </Paragraph>

        <Paragraph>
          <strong>For the Atlas Platform:</strong>
        </Paragraph>
        <Paragraph>
          Growth Lab at Harvard University. "The Atlas of Economic Complexity."
          Web application. Harvard Kennedy School, 2024.
          https://atlas.hks.harvard.edu
        </Paragraph>

        <Paragraph>
          <strong>For Downloaded Datasets:</strong>
        </Paragraph>
        <Paragraph>
          Each downloadable data file includes specific citation requirements
        </Paragraph>
        <Paragraph>
          Example: The Growth Lab at Harvard University, 2024, "International
          Trade Data (HS), 1995-2022", https://doi.org/[dataset-specific-doi],
          Harvard Dataverse
        </Paragraph>

        <Paragraph>
          <strong>For the Original Atlas Book:</strong>
        </Paragraph>
        <Paragraph>
          Hausmann, R., Hidalgo, C., Bustos, S., Coscia, M., Chung, S., Jimenez,
          J., Simoes, A., Yildirim, M. (2013). The Atlas of Economic Complexity.
          Cambridge, MA: MIT Press.
        </Paragraph>
      </section>

      <section>
        <H2>Additional Resources</H2>
        <Paragraph>
          For detailed explanations of Atlas terms, metrics, and methodologies,
          please visit our{" "}
          <Link to="/glossary" target="_blank" rel="noopener noreferrer">
            Glossary
          </Link>
          . Common questions about using the Atlas and interpreting its data are
          addressed in our{" "}
          <Link to="/faq" target="_blank" rel="noopener noreferrer">
            Frequently Asked Questions
          </Link>
          .
        </Paragraph>
        <Paragraph>
          If you identify potential errors in the data or have technical
          questions, please contact our team at{" "}
          <a href="mailto:growthlabtools@hks.harvard.edu">
            growthlabtools@hks.harvard.edu
          </a>
          .
        </Paragraph>
      </section>
    </Box>
  );
};

export default DataSourcesSection;
