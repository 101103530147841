import styled from "@emotion/styled";
import GrowthLabLogoPng from "../../assets/images/GL_logo_black.png";
import PodcastIcon from "../../assets/images/social/applepodcast.svg";
import FacebookIcon from "../../assets/images/social/facebook.svg";
import LinkedInIcon from "../../assets/images/social/linkedin.svg";
import InstagramIcon from "../../assets/images/social/instagram.svg";
import TwitterIcon from "../../assets/images/social/twitter.svg";
import YouTubeIcon from "../../assets/images/social/youtube.svg";
import {
  demiFontWeight,
  globalPageMargin,
} from "../../components/styling/cssVariables";
import {
  countryProfileRoutes,
  headerLinkMetadata,
} from "../../components/header/Utils";
import { Link } from "react-router-dom";
import { RouteID } from "../../routing/routes";

export const footerColor = "#5a708c";
const breakpointSmall = "990px";

export const CopyrightContainer = styled.div`
  position: relative;
`;

const Root = styled.div`
  background-color: #e6e6e6;
  padding: 7vh 100px 4vh;
  line-height: 1;
  letter-spacing: 1px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 4rem;
  text-transform: uppercase;
  justify-content: center;

  @media (min-width: ${breakpointSmall}) {
    grid-template-columns: auto auto auto auto auto;
  }
`;
const Title = styled.div`
  grid-column: span 2;
  margin-bottom: 0;
  margin-left: ${globalPageMargin};
  padding-bottom: 10px;
  font-size: 1.1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: ${breakpointSmall}) {
    grid-column: 1;
    text-align: left;
  }
`;
const LeftColumnLinks = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0px;

  @media (min-width: ${breakpointSmall}) {
    align-items: flex-start;
    grid-column: 2;
  }
`;
const RightColumnLinks = styled.ul`
  padding: 0px;
  @media (min-width: ${breakpointSmall}) {
    grid-column: 3;
  }
`;
const SocialMediaLinks = styled.ul`
  display: flex;
  justify-content: space-between;
  grid-column: span 2;
  margin: 2rem auto;
  max-width: 200px;
  padding: 0px;

  @media (min-width: ${breakpointSmall}) {
    display: block;
    grid-column: 4;
    margin: 0;
  }
`;
const GrowthLabLogo = styled.div`
  grid-column: 1 / -1;
  margin-right: ${globalPageMargin};
  display: flex;
  flex-direction: column;
  justify-content: center;

  & p {
    text-transform: none;
    font-size: 0.6rem;
    line-height: 1rem;
  }

  @media (min-width: ${breakpointSmall}) {
    grid-column: 5;
  }
`;
const Li = styled.li`
  margin-bottom: 0.8rem;
  font-size: 0.875rem;
  font-weight: ${demiFontWeight};
  list-style: none;

  a {
    text-decoration: none;
    color: #000000;
    &:hover {
      border-bottom: 1px solid #333;
    }
  }
`;

const SocialLink = styled(Li)`
  font-size: 0;
  line-height: 0;
  color: rgba(0, 0, 0, 0);

  && a:link,
  a:visited {
    border-bottom: none;
  }

  && a:hover {
    border-bottom: none;
  }
`;

const SocialIconContainer = styled.span`
  display: inline-block;
  width: 1.5rem;
  filter: opacity(0.4);

  &:hover {
    filter: opacity(0.8);
  }
`;
const AtlasLogoImage = styled.div`
  max-width: 280px;
  margin: auto;

  img {
    width: 100%;
    height: 100%;
  }
`;

const Copyright = styled(CopyrightContainer)`
  padding: 0.5rem;
  text-align: center;
  background-color: #333;
  color: #fff;
  font-size: 0.875rem;

  a {
    color: #fff;
    text-decoration: none;
    border-bottom: solid 1px transparent;

    &:hover {
      border-bottom-color: #fff;
    }
  }
`;

const Footer = () => {
  const mailingListLink = `https://hksexeced.tfaforms.net/f/subscribe?s=a1n6g000000nJnxAAE`;

  const linkedInLink = `https://www.linkedin.com/company/harvard-growth-lab/`;

  const instagramLink = `https://www.instagram.com/harvardgrowthlab/`;

  const versionNumber = process.env.REACT_APP_ATLAS_VERSION_NUMBER;

  return (
    <>
      <Root>
        <Title>
          <a
            href="https://growthlab.cid.harvard.edu/home"
            target="_blank"
            rel="noopener noreferrer"
          >
            <AtlasLogoImage>
              <img src={GrowthLabLogoPng} alt="The Growth Lab" />
            </AtlasLogoImage>
          </a>
          <GrowthLabLogo>
            <p>
              79 JFK St. | Cambridge, MA 02138
              <br />
              growthlab@hks.harvard.edu
            </p>
          </GrowthLabLogo>
          <p>
            <em>Atlas {versionNumber}</em>
          </p>
        </Title>
        <LeftColumnLinks>
          {headerLinkMetadata.map((navigationLink) => {
            if (countryProfileRoutes.includes(navigationLink.routeId)) {
              return (
                <Li>
                  <a href={navigationLink.route}>{navigationLink.label}</a>
                </Li>
              );
            } else {
              return (
                <Li>
                  <Link to={navigationLink.route}>{navigationLink.label}</Link>
                </Li>
              );
            }
          })}
        </LeftColumnLinks>
        <RightColumnLinks>
          <Li>
            <a
              href="mailto:growthlabtools@hks.harvard.edu"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact Us
            </a>
          </Li>
          <Li>
            <a href={mailingListLink} target="_blank" rel="noopener">
              Newsletter
            </a>
          </Li>
          <Li>
            <a
              href="https://growthlab.cid.harvard.edu/jobs-opportunities"
              target="_blank"
              rel="noopener noreferrer"
            >
              Work with us
            </a>
          </Li>
        </RightColumnLinks>
        <SocialMediaLinks>
          <SocialLink>
            <a
              href="https://www.facebook.com/harvardgrowthlab/"
              target="_blank"
              rel="noopener noreferrer"
              title="Facebook"
            >
              <SocialIconContainer>
                <img src={FacebookIcon} alt="Facebook" title="Facebook" />
              </SocialIconContainer>
              Facebook
            </a>
          </SocialLink>
          <SocialLink>
            <a
              href={linkedInLink}
              target="_blank"
              rel="noopener noreferrer"
              title="LinkedIn"
            >
              <SocialIconContainer>
                <img src={LinkedInIcon} alt="LinkedIn" title="LinkedIn" />
              </SocialIconContainer>
              LinkedIn
            </a>
          </SocialLink>
          <SocialLink>
            <a
              href="https://twitter.com/HarvardGrwthLab"
              target="_blank"
              rel="noopener noreferrer"
              title="Twitter"
            >
              <SocialIconContainer>
                <img src={TwitterIcon} alt="Twitter" title="Twitter" />
              </SocialIconContainer>
              Twitter
            </a>
          </SocialLink>
          <SocialLink>
            <a
              href={instagramLink}
              target="_blank"
              rel="noopener noreferrer"
              title="LinkedIn"
            >
              <SocialIconContainer>
                <img src={InstagramIcon} alt="Instagram" title="Instagram" />
              </SocialIconContainer>
              Instagram
            </a>
          </SocialLink>
          <SocialLink>
            <a
              href="https://youtube.com/playlist?list=PLVJQsjaKb-4SNl2obPcBFtIbjKoyi4e5z"
              target="_blank"
              rel="noopener noreferrer"
              title="YouTube"
            >
              <SocialIconContainer>
                <img src={YouTubeIcon} alt="YouTube" title="YouTube" />
              </SocialIconContainer>
              YouTube
            </a>
          </SocialLink>
          <SocialLink>
            <a
              href="https://podcasts.apple.com/us/podcast/growth-lab-podcast-series/id1486218164"
              target="_blank"
              rel="noopener noreferrer"
              title="Apple Podcast"
            >
              <SocialIconContainer>
                <img
                  src={PodcastIcon}
                  alt="Apple Podcast"
                  title="Apple Podcast"
                />
              </SocialIconContainer>
              Apple Podcast
            </a>
          </SocialLink>
        </SocialMediaLinks>
      </Root>
      <Copyright>
        Copyright © {new Date().getFullYear()} The President and Fellows of
        Harvard College
        {" | "}
        <a
          href="https://gdpr.harvard.edu/eeaprivacydisclosures"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy
        </a>
        {" | "}
        <a
          href="http://accessibility.harvard.edu/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Accessibility
        </a>
        {" | "}
        <a
          href="https://accessibility.huit.harvard.edu/digital-accessibility-policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Digital Accessibility
        </a>
        {" | "}
        <a
          href="http://www.harvard.edu/reporting-copyright-infringements"
          target="_blank"
          rel="noopener noreferrer"
        >
          Report Copyright
        </a>
      </Copyright>
    </>
  );
};

export default Footer;
