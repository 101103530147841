import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  IconButton,
  Box,
  Alert,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckIcon from "@mui/icons-material/Check";
import MetadataTable, { MetadataField } from "./MetadataTable";
import { formatDataTypeLabel } from "../ui/DataChip";
import { useState } from "react";
import { ReactComponent as CitationIcon } from "./citation.svg";

// Dataset name mapping based on repository value
const datasetNameMapping: Record<string, string> = {
  hs92: "International Trade Data (HS92)",
  hs12: "International Trade Data (HS12)",
  sitc: "International Trade Data (SITC, Rev. 2)",
  services_unilateral: "International Trade Data (Services)",
  classification: "Classifications Data",
  rankings: "Growth Projections and Complexity Rankings",
  product_space: "Product Space Networks",
};

interface DatasetModalProps {
  open: boolean;
  onClose: () => void;
  dataset: any;
}

const DatasetModal = ({ open, onClose, dataset }: DatasetModalProps) => {
  const [copied, setCopied] = useState(false);

  if (!dataset) return null;
  const columns = dataset.originalData?.columns || dataset.Columns || [];

  const metadataFields: MetadataField[] = columns.map((column: any) => ({
    fieldName: column.columnName || "",
    description: column.description || "",
    dataType: column.dataType || "",
    notes: column.notes || "",
  }));

  const displayMetadata = metadataFields || [];

  const fileName = dataset.originalData?.displayName;

  const repo = dataset.originalData?.repo || "";
  const datasetDisplayName = datasetNameMapping[repo] || "";

  const publicationDate = dataset.originalData?.dvPublicationDate || "";
  const publicationYear = publicationDate
    ? new Date(publicationDate).getFullYear()
    : new Date().getFullYear();

  const doi = dataset.originalData?.doi || "";
  const doiUrl = doi
    ? `https://doi.org/${doi}`
    : "http://www.atlas.cid.harvard.edu";

  const yearMin = dataset.originalData?.yearMin;
  const yearMax = dataset.originalData?.yearMax;
  const yearsRange =
    yearMin && yearMax ? `${yearMin}-${yearMax}` : "various years";

  const dataType = dataset["Data Type (tag)"];
  const classification = dataset["Classification (tag)"] || "";

  const productLevel = dataset["Product Level"] || "";

  const createParentheticalInfo = () => {
    const parts = [];

    if (dataType) {
      parts.push(formatDataTypeLabel(dataType));
    }

    if (classification) {
      parts.push(classification);
    }

    if (productLevel && productLevel !== "N/A") {
      parts.push(`${productLevel} digit`);
    }

    parts.push(yearsRange);

    return parts.length > 0 ? `(${parts.join(", ")})` : "";
  };

  const parentheticalInfo = createParentheticalInfo();

  const dvFileId = dataset.originalData?.dvFileId || dataset["DV File ID"];

  const handleDownload = () => {
    if (dvFileId) {
      const downloadUrl = `https://dataverse.harvard.edu/api/access/datafile/${dvFileId}`;

      const link = document.createElement("a");
      link.href = downloadUrl;
      link.target = "_blank";
      link.download = `${fileName}.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    onClose();
  };

  const handleCopyCitation = () => {
    const citationText = `The Growth Lab at Harvard University, ${publicationYear}, "${datasetDisplayName}", ${doiUrl}, Harvard Dataverse`;

    navigator.clipboard
      .writeText(citationText)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 2000);
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      slotProps={{
        paper: {
          sx: {
            borderRadius: "0px",
            p: 1,
            height: "90vh",
            display: "flex",
            flexDirection: "column",
            overflow: "scroll",
          },
        },
      }}
    >
      <Box sx={{ mt: 1, px: 1, display: "flex", flexDirection: "column" }}>
        <IconButton
          onClick={onClose}
          sx={{
            alignSelf: "flex-end",
            color: "text.primary",
            "&:hover": { backgroundColor: "#f5f5f5" },
          }}
        >
          <CloseIcon fontSize="large" />
        </IconButton>
      </Box>
      <Box
        sx={{
          px: 4,
          display: "flex",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: 0,
            mb: 2,
            fontSize: "28px",
            fontWeight: "600",
          }}
        >
          <Box component="span" sx={{ flex: 1 }}>
            <span style={{ fontSize: 26, fontWeight: "normal" }}>
              {datasetDisplayName}
            </span>{" "}
            <span style={{ fontSize: 20, fontWeight: "bold" }}>
              {parentheticalInfo}
            </span>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Button
              variant="contained"
              startIcon={<DownloadIcon />}
              sx={{
                backgroundColor: "#37474f",
                "&:hover": {
                  backgroundColor: "#263238",
                },
                px: 3,
                textTransform: "none",
                fontWeight: "bold",
                borderRadius: "0px",
              }}
              onClick={handleDownload}
              disabled={!dvFileId}
            >
              Download Data
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent
          sx={{
            p: 0,
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            flex: 1,
          }}
        >
          {!dvFileId && (
            <Alert severity="info" sx={{ mb: 2 }}>
              Download is not available for this dataset.
            </Alert>
          )}

          <Typography
            variant="body1"
            paragraph
            sx={{
              mb: 1,
              color: "text.secondary",
              lineHeight: 1.6,
            }}
          >
            This dataset contains information about {datasetDisplayName}. It
            includes data from {yearsRange}
            {dataType
              ? ` and is classified as ${formatDataTypeLabel(dataType)}`
              : ""}
            {classification ? ` with ${classification} classification` : ""}
            {productLevel && productLevel !== "N/A"
              ? ` at the ${productLevel}-digit level`
              : ""}
            .
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1.5,
              fontSize: 16,
            }}
          >
            <Typography variant="body1">
              <Box component="span" sx={{ fontWeight: 600 }}>
                File Name:
              </Box>{" "}
              {dataset.originalData?.dvFileName || "N/A"}
            </Typography>

            <Typography variant="body1">
              <Box component="span" sx={{ fontWeight: 600 }}>
                File Size:
              </Box>{" "}
              {dataset.originalData?.dvFileSize || "N/A"}
            </Typography>

            <Typography variant="body1">
              <Box component="span" sx={{ fontWeight: 600 }}>
                Last Update:
              </Box>{" "}
              {dataset.originalData?.dvPublicationDate || "N/A"}
            </Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: "#CCD4DB",
              padding: "16px",
              marginBottom: "24px",
              borderRadius: "0px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flex: 1,
                flexWrap: "nowrap",
              }}
            >
              <CitationIcon
                style={{
                  width: 26,
                  height: 26,
                  marginRight: "6px",
                }}
              />

              <Typography
                variant="subtitle1"
                component="div"
                sx={{
                  gap: 1,
                  fontWeight: 600,
                  fontSize: "18px",
                  textWrap: "nowrap",
                }}
              >
                HOW TO CITE
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  px: 2,
                  color: "text.secondary",
                  "& a": {
                    color: "primary.main",
                    textDecoration: "none",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  },
                }}
              >
                The Growth Lab at Harvard University, {publicationYear}, "
                {datasetDisplayName}",{" "}
                {doi ? (
                  <a href={doiUrl} target="_blank" rel="noopener noreferrer">
                    {doiUrl}
                  </a>
                ) : (
                  "http://www.atlas.cid.harvard.edu"
                )}
                , Harvard Dataverse
              </Typography>
            </Box>
            <Button
              variant="contained"
              size="small"
              startIcon={copied ? <CheckIcon /> : <ContentCopyIcon />}
              onClick={handleCopyCitation}
              sx={{
                backgroundColor: "#37474f",
                "&:hover": {
                  backgroundColor: "#263238",
                },
                textTransform: "none",
                borderRadius: "0px",
                minWidth: "100px",
              }}
            >
              {copied ? "Copied" : "Copy"}
            </Button>
          </Box>

          <Box sx={{ flex: 1 }}>
            <MetadataTable metadata={displayMetadata} />
          </Box>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default DatasetModal;
