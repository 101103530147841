import { useEffect, useMemo } from "react";
import {
  ColorBy,
  getCategoriesForChartLegend,
} from "../../visualization/Utils";
import {
  VisualizationBottomRowContainer,
  VisualizationContentContainer,
  VisualizationGrid,
  VisualizationHandlesContainer,
} from "../../visualization/VizGrid";
import ProductSpaceTutorial from "./ProductSpaceTutorial";
import { useTutorial } from "./TutorialContext";
import CategoryLabels from "../../visualization/components/CategoryLabels";

const TutorialContainer = () => {
  const {
    isTutorialOpen,
    setIsTutorialOpen,
    dismissTutorial,
    shouldShowTutorial,
  } = useTutorial();

  useEffect(() => {
    if (shouldShowTutorial) {
      setIsTutorialOpen(true);
    }
  }, [shouldShowTutorial, setIsTutorialOpen]);

  const categoriesForLegend = useMemo(
    () =>
      getCategoriesForChartLegend({
        view: "products",
        productClass: "HS92",
        clusters: true,
        currentColorBySelection: ColorBy.Sector,
        rcaThreshold: 1,
      }).filter((d) => d.id.split("-")[2] !== "14"),
    [],
  );

  if (!isTutorialOpen) return null;

  return (
    <>
      <div
        style={{
          zIndex: 1000,
          backgroundColor: "rgba(0, 0, 0, 0.4)",
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          padding: "20px",
          pointerEvents: "auto",
        }}
        onClick={dismissTutorial}
      ></div>
      <VisualizationGrid>
        <VisualizationContentContainer>
          <ProductSpaceTutorial
            open={isTutorialOpen}
            onClose={() => setIsTutorialOpen(false)}
          />
        </VisualizationContentContainer>
        <VisualizationBottomRowContainer>
          <VisualizationHandlesContainer>
            <CategoryLabels
              categories={categoriesForLegend}
              allowToggle={false}
              fullWidth={true}
            />
          </VisualizationHandlesContainer>
        </VisualizationBottomRowContainer>
      </VisualizationGrid>
    </>
  );
};

export default TutorialContainer;
